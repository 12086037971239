import React, { Component } from 'react';

import Team from './blocks/team';
import AddNewTeam from './blocks/teamAddition';

class TeamsList extends Component {
    render() {

    	let userTeams = this.props.listOfTeams.map((item, index) => {
            return (
                <Team key={item.id} 
                	  item={item}
                      createNotification={this.props.createNotification}
                	  toggleActiveTeamRemoval={this.props.toggleActiveTeamRemoval}
                	  index={index} 
                	  selectedTeamIndex={this.props.selectedTeamIndex}
                	  selectTeam={this.props.selectTeam}
                      teamUpdateInProcess={this.props.teamUpdateInProcess}
                      toggleTeamUpdateProcess={this.props.toggleTeamUpdateProcess}
                	  newTeamInputIsVisible={this.props.newTeamInputIsVisible}
                      updateListOfTeams={this.props.updateListOfTeams}/>
            );
        });

        return(
            <div className="teamsListWrapper">
                <div className="teamsList">
                    {userTeams}
                </div>
                <div className="teamAdditionWrapper">
                	<AddNewTeam createNotification={this.props.createNotification}
			                	newTeamInputIsVisible={this.props.newTeamInputIsVisible}
                                refreshUserData={this.props.refreshUserData}
			                	toggleNewTeamInputVisibility={this.props.toggleNewTeamInputVisibility}
                				updateListOfTeams={this.props.updateListOfTeams}/>
                </div>
            </div>       
        )
    }
};

export default TeamsList;