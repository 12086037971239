import React, { Component } from 'react';

class LoaderAnimation extends Component {
	render() {
		return (
			<div className="fullScreenLoaderWrapper">
				<div className="loaderAnimationWrapper">
					<div className="loaderAnimation">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
					<div className="loaderAnimationMessage">{(typeof this.props.message !== "undefined") ? this.props.message : "Please wait..."}</div>
				</div>
			</div>
		);
	}
}

export default LoaderAnimation;
