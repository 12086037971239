import React, { Component } from "react";
import axios from 'axios';

import LoaderAnimation from '../../LoaderAnimation';
import AddressRemoval from '../../appComponents/blocks/addressRemoval';
import ApplyCoupon from '../../appComponents/blocks/applyCoupon';

let icons = require("../../appComponents/icons");
let settings = require("../../settings");
let authentication = require("../../authentication");

class BillingSettings extends Component {

	constructor(props) {
		super(props)

		var customerName = undefined
		var streetLine1 = undefined
		var streetLine2 = undefined
		var city = undefined
		var region = undefined
		var postalCode = undefined
		var country = undefined

		if (this.props.userData.address !== undefined) {
			customerName = this.props.userData.address.customerName
			streetLine1 = this.props.userData.address.streetLine1
			streetLine2 = this.props.userData.address.streetLine2
			city = this.props.userData.address.city
			region = this.props.userData.address.region
			postalCode = this.props.userData.address.postalCode
			country = this.props.userData.address.country
		}

		this.state={
			customerName: customerName,
			streetLine1: streetLine1,
			streetLine2: streetLine2,
			city: city,
			region: region,
			postalCode: postalCode,
			country: country,
			inputIsVisible: false,
			addressRemoveIsVisible: false,
			processing: false
		}

		this.handleInputChange = this.handleInputChange.bind(this);
		this.toggleInputChange = this.toggleInputChange.bind(this);
		this.handleAddAddress = this.handleAddAddress.bind(this);
		this.handUpdateAddress = this.handUpdateAddress.bind(this);
		this.toggleAddressRemove = this.toggleAddressRemove.bind(this);
		this.handleAddressDelete = this.handleAddressDelete.bind(this);
	}

	toggleAddressRemove() {
		this.setState({addressRemoveIsVisible: !this.state.addressRemoveIsVisible})
	}

	handleAddressDelete(event) {
		event.preventDefault()

		this.setState({processing: true})

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

        const headers = { Authorization: "Bearer " + tokenHolder.token };

        axios
            .delete(settings.API_HOST + "user/address", { headers })
            .then(function (response) {
                if (response.status === 200) {
                    self.props.createNotification(
                        "success",
                        "Billing address has been successfully removed.",
                        "Success",
                        7000
                    );
                    self.props.refreshUserData();
                    self.setState({processing: false,
                    				customerName: undefined,
                    				streetLine1: undefined,
									streetLine2: undefined,
									city: undefined,
									region: undefined,
									postalCode: undefined,
									country: undefined,
                    				});
                    self.toggleAddressRemove();
                } else {
                	self.setState({processing: false})
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
            	self.setState({processing: false})
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
	}

	handUpdateAddress(event) {
		event.preventDefault();

    	this.setState({processing: true})

    	if (this.state.customerName === undefined || this.state.customerName === "") {
        	this.setState({processing: false})
            this.props.createNotification(
            	"error", 
            	"Please enter a valid customer name.", 
            	"Error - name", 
            	6000);
            return
        }

        if (this.state.streetLine1 === undefined || this.state.streetLine1 === "") {
        	this.setState({processing: false})
            this.props.createNotification(
            	"error", 
            	"Please enter a valid address in line one.", 
            	"Error - address", 
            	6000);
            return
        }

        if (this.state.city === undefined || this.state.city === "") {
        	this.setState({processing: false})
			this.props.createNotification(
				"error",
				"Please enter a valid city name.",
				"Error - city name",
				6000
			);
			return;
		}

	    if (this.state.postalCode === undefined || this.state.postalCode === "") {
        	this.setState({processing: false})
			this.props.createNotification(
				"error",
				"Please enter a valid postal code.",
				"Error - postal code",
				6000
			);
			return;
		}

		if (this.state.country === undefined || this.state.country === "") {
        	this.setState({processing: false})
			this.props.createNotification(
				"error",
				"Please enter a valid country name.",
				"Error - country",
				6000
			);
			return;
		}

        var self = this;

        let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			this.setState({processing: false})
			return;
		}

        var requestCredentials = {
        	"customerName": this.state.customerName,
            "streetLine1": this.state.streetLine1,
            "streetLine2": this.state.streetLine2,
            "city": this.state.city,
            "region": this.state.region,
            "postalCode": this.state.postalCode,
            "country": this.state.country
        };

        axios
			.patch(settings.API_HOST + "user/address", requestCredentials, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
            .then(function (response) {
                if (response.status === 200) {
					self.props.refreshUserData()
                    self.props.createNotification('success', 'You have successfully updated billing address.', 'Success', 6000);
                    self.toggleInputChange()
                } else {
                    self.props.createNotification('error', 'Internal server error.', 'Error', 6000);
                    self.toggleInputChange()
                }
        	}).catch(function (error) {
                self.props.createNotification('error', 'You have entered invalid data. Please try again.', 'Error', 7000);
                self.toggleInputChange()
        });
	}

	handleAddAddress(event) {
		event.preventDefault();

    	this.setState({processing: true})

        if (this.state.customerName === undefined || this.state.customerName === "") {
        	this.setState({processing: false})
            this.props.createNotification(
            	"error", 
            	"Please enter a valid customer name.", 
            	"Error - name", 
            	6000);
            return
        }


        if (this.state.streetLine1 === undefined || this.state.streetLine1 === "") {
        	this.setState({processing: false})
            this.props.createNotification(
            	"error", 
            	"Please enter a valid address in line one.", 
            	"Error - address", 
            	6000);
            return
        }

        if (this.state.city === undefined || this.state.city === "") {
        	this.setState({processing: false})
			this.props.createNotification(
				"error",
				"Please enter a valid city name.",
				"Error - city name",
				6000
			);
			return;
		}

	    if (this.state.postalCode === undefined || this.state.postalCode === "") {
        	this.setState({processing: false})
			this.props.createNotification(
				"error",
				"Please enter a valid postal code.",
				"Error - postal code",
				6000
			);
			return;
		}

		if (this.state.country === undefined || this.state.country === "") {
        	this.setState({processing: false})
			this.props.createNotification(
				"error",
				"Please enter a valid country name.",
				"Error - country",
				6000
			);
			return;
		}

        var self = this;

        let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			this.setState({processing: false})
			return;
		}

    	var requestCredentials = {
    		"customerName": this.state.customerName,
            "streetLine1": this.state.streetLine1,
            "streetLine2": this.state.streetLine2,
            "city": this.state.city,
            "region": this.state.region,
            "postalCode": this.state.postalCode,
            "country": this.state.country
        };

		axios
			.post(settings.API_HOST + "user/address", requestCredentials, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
            .then(function (response) {
                if (response.status === 200) {
					self.props.refreshUserData()
                    self.props.createNotification('success', 'You have successfully added billing address.', 'Success', 6000);
                    self.toggleInputChange()
                } else {
                    self.props.createNotification('error', 'Internal server error.', 'Error', 6000);
                    self.toggleInputChange()
                }
        	}).catch(function (error) {
                self.props.createNotification('error', 'You have entered invalid data. Please try again.', 'Error', 7000);
                self.toggleInputChange()
        });
	}

	toggleInputChange() {
		this.setState({inputIsVisible: !this.state.inputIsVisible, processing: false})
	}

    handleInputChange(event) {

        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

	render() {

		return (
			<React.Fragment>
				{this.state.addressRemoveIsVisible ? <AddressRemoval customerName={this.state.customerName}
																	 streetLine1={this.state.streetLine1}
																	 streetLine2={this.state.streetLine2}
																	 city={this.state.city}
																	 region={this.state.region}
																	 postalCode={this.state.postalCode}
																	 country={this.state.country}
																	 toggleAddressRemove={this.toggleAddressRemove}
																	 handleAddressDelete={this.handleAddressDelete}
																	/> : <React.Fragment />}
				{this.state.processing ? <LoaderAnimation message={this.state.loaderAnimationMessage}/> : <React.Fragment />}
				<div className="billingSettingsWrapper">
					<div className="billingSettings">
						<div className="billingSettingsProfileBlock">
							<div className="userAddressData">
								<div><h1>Address</h1></div>
								<div>
									<h4>This address appears on your monthly invoice and should be the legal address of your home or business.</h4>
								</div>

								{this.state.inputIsVisible ?
									<React.Fragment>
									<form id="addressForm">
										<div className="addressWrapper">
											<div>
												<div>
													<input placeholder="Customer name" name="customerName" type="text" value={this.state.customerName} onChange={this.handleInputChange} autoFocus />
												</div>
												<div>
													<input placeholder="Address line 1" name="streetLine1" type="text" value={this.state.streetLine1} onChange={this.handleInputChange} />
													<input placeholder="Address line 2" name="streetLine2" type="text" value={this.state.streetLine2} onChange={this.handleInputChange} />
												</div>
												<div>
													<input placeholder="City" name="city" type="text" value={this.state.city} onChange={this.handleInputChange} />
													<input placeholder="State / Province / Region" name="region" type="text" value={this.state.region} onChange={this.handleInputChange} />
													<input placeholder="Zip / Postal code" name="postalCode" type="text" value={this.state.postalCode} onChange={this.handleInputChange} />
												</div>
												<div>
													<input placeholder="Country" name="country" type="text" value={this.state.country} onChange={this.handleInputChange} />
												</div>
											</div>
											<div>
												{
													(typeof this.props.userData.address === "undefined") ? 
														<button type="submit" form="addressForm" className="defaultButtonGreen" onClick={(event) => this.handleAddAddress(event)}>Save address</button>
													:
														<button type="submit" form="addressForm" className="defaultButtonGreen" onClick={(event) => this.handUpdateAddress(event)}>Update address</button>
												}
												<button className="cancelButton" onClick={this.toggleInputChange}>Cancel</button>
											</div>
										</div>
										</form>
									</React.Fragment>
								:
									<React.Fragment>
										<div className="addressWrapperData">
											<div className="address">
												{(typeof this.props.userData.address === "undefined") ?
														<div className="addressNotDefinedNotification">
															
																<h4>Billing address not defined</h4>
															
														</div>
													:
														<div className="addressDisplay">
															<div>
																<div>{this.props.userData.address.customerName}</div>
																<div>{this.props.userData.address.streetLine1}</div>
																<div>{this.props.userData.address.streetLine2}</div>
																<div>{this.props.userData.address.city}, &nbsp;&nbsp;{this.props.userData.address.postalCode}</div>
																<div>{this.props.userData.address.region}</div>
																<div>{this.props.userData.address.country}</div>
															</div>
														</div>
												}
											</div>
											<div>
												<div className="billingAddressEditButtonsWrapper">
													<div
														className={
															(typeof this.props.userData.address === "undefined")
																? "displayNone"
																: "editIcon"
														}			
														onClick={this.toggleAddressRemove}>
														{icons.trashCan()}
													</div>
													<div>
														<button className="defaultButtonGreen" onClick={this.toggleInputChange}>Edit address</button>
													</div>
												</div>
											</div>
										</div>
									</React.Fragment>
								}
							</div>
						</div>
						<div className="applyCouponBlock">
							<ApplyCoupon createNotification={this.props.createNotification} refreshUserData={this.props.refreshUserData} userData={this.props.userData}/>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

}

export default BillingSettings;
