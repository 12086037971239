import React, { Component } from "react";

import CreditsChart from "./blocks/creditsChart";

class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<CreditsChart createNotification={this.props.createNotification}
							  currentProductName={this.props.currentProductName}
							  defaultMonthlyLookupCredits={this.props.defaultMonthlyLookupCredits}
							  defaultMonthlyVerificationCredits={this.props.defaultMonthlyVerificationCredits}
							  lastRenewal={this.props.lastRenewal}
							  monthlyLookupCredits={this.props.monthlyLookupCredits}
							  monthlyVerificationCredits={this.props.monthlyVerificationCredits}
						 	  permanentCredits={this.props.permanentCredits}
						 	  plansData={this.props.plansData}
						 	  refreshUserData={this.props.refreshUserData}
						 	  switchModeTo={this.props.switchModeTo}
						 	  userEmailAddress={this.props.userEmailAddress}
						 	  userName={this.props.userName}
						 	  downgradeActive={this.props.downgradeActive}
						 	  activeTeamData={this.props.activeTeamData}
						 	  isActiveTeamTeamMaster={this.props.isActiveTeamTeamMaster}/>
			</div>
		)
	}
}

export default Footer;
