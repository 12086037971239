import React, { Component } from 'react';
import axios from "axios";

import LoaderAnimation from "../LoaderAnimation";

import TaskFilter from "../appComponents/taskFilter";
import TaskVerifier from "../appComponents/taskVerifier";

import HelperPanel from "../appComponents/helperPanel";

let settings = require("../settings");
let authentication = require("../authentication");

let refreshListInterval;

class EmailVerification extends Component {
	constructor(props) {
		super(props)

		this.state=({
			address: '',
			tasks: [],
			loadingProcessActive: true,
			maxPage: undefined,
			initialPage: 1,
			filter: "",
		})

		this.handleInputChange = this.handleInputChange.bind(this);
		this.fetchVerificationTasks = this.fetchVerificationTasks.bind(this);
		this.handleVerification = this.handleVerification.bind(this);
		this.setInitialPage = this.setInitialPage.bind(this);
		this.setFilter = this.setFilter.bind(this);
	}

	setFilter(value) {
		var self = this;
		this.setState({
			filter: value
		},() => {
			self.fetchVerificationTasks();
		})
	}

	setInitialPage(page) {
		var self = this;
		if (page !== this.state.initialPage) {
			this.setState({
				initialPage: page
			}, () => {
				self.fetchVerificationTasks();
			})
		}
	}

	componentDidMount() {
		this.fetchVerificationTasks();
	}

	componentWillUnmount() {
		clearInterval(refreshListInterval);
	}

	fetchVerificationTasks() {
		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		const requestData = {
			start: this.state.initialPage * this.props.resultsPerPage - this.props.resultsPerPage + 1,
			total: this.props.resultsPerPage,
		};

		if (this.state.filter.length > 2) {
			requestData.filter = this.state.filter
		}

		axios
			.post(settings.API_HOST + "verifications", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				self.setState({ loadingProcessActive: false });
				if (response.status === 200) {

					if (response.data.length > 0) {
						let maxPage = Math.ceil(response.data[0].totalTasks / self.props.resultsPerPage)
						self.setState({
							maxPage: maxPage
						})
					}

					if (self.state.tasks !== response.data) {
						self.props.refreshUserData();
						self.props.refreshActiveTeamData();
					}

					self.setState(
						{
							tasks: response.data,
						},
						() => {
							const hasProcessingValue = self.state.tasks.some(
								(el) =>
									el.status === "pending" ||
									el.status === "processing"
							);
							if (hasProcessingValue) {
								clearInterval(refreshListInterval);

								refreshListInterval = setInterval(function () {
									self.fetchVerificationTasks();
								}, 3000);
							} else {
								clearInterval(refreshListInterval);

								refreshListInterval = setInterval(function () {
									self.fetchVerificationTasks();
								}, 60000);
							}
						}
					);
				} else {
					clearInterval(refreshListInterval);

					refreshListInterval = setInterval(function () {
						self.fetchVerificationTasks();
					}, 60000);

					self.props.createNotification(
						"error",
						"An error occurred fetching previous verifications.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				clearInterval(refreshListInterval);

				refreshListInterval = setInterval(function () {
					self.fetchVerificationTasks();
				}, 60000);

				self.props.createNotification(
					"error",
					"An error occurred fetching previous verifications.",
					"Error",
					6000
				);
			});
	}

	handleVerification(event) {
		event.preventDefault();

		if (this.state.address === "") {
			return;
		}

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		const requestData = {
			email: this.state.address,
		};

		axios
			.post(settings.API_HOST + "verify", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({ address: ""});
					self.fetchVerificationTasks();
					self.props.refreshUserData();
					self.props.refreshActiveTeamData();
				} else {
					self.fetchVerificationTasks();
					self.props.createNotification(
						"error",
						"An error occurred performing email verification.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				self.fetchVerificationTasks();
				self.props.createNotification(
					"error",
					"An error occurred performing email verification.",
					"Error",
					6000
				);
			});
	}

	componentDidUpdate(prevProps) {

		if (prevProps.resultsPerPage !== this.props.resultsPerPage) {
			var self = this;
			this.setState({
				initialPage: 1
			}, () => {
				self.fetchVerificationTasks();
			})
		}
	}



    handleInputChange(event) {
    	event.preventDefault();

        const target = event.target;
        var value = target.value;
        const name = target.name;

    	value = value.toLowerCase();

        this.setState({
            [name]: value
        });
    }

    render() {

    	let tasks = this.state.tasks.map((item, index) => {
			return (
				<TaskVerifier
					key={item.id}
					index={index}
					item={item}
					activeTeamData={this.props.activeTeamData}
					userTags={this.props.userTags}
					displayTagSelector={this.props.displayTagSelector}
					displayTagRemovalSelector={
						this.props.displayTagRemovalSelector
					}
					handleTaskRemoval={this.props.handleTaskRemoval}
					refreshTaskData={this.fetchVerificationTasks}
					setDetailsHelperPanelSource={() => this.props.setDetailsHelperPanelSource(item)}
				/>
			);
		});

        return(
            <div className="emailVerificationWrapper">
            	<div className="emailVerification">
	                	<div className="requestForm">
	                		<div>
	                			<h1>Enter email address to be verified.</h1>
	                		</div>
	                		<form onSubmit={this.handleVerification}>
	                			<input autoFocus name="address" type="text" placeholder="Email address" value={this.state.address} onChange={this.handleInputChange}/>
	                			<div><button type="submit" className="defaultButtonGreen">Verify address</button></div>
	                		</form>
	                	</div>
	                	{this.state.loadingProcessActive ? (
						<div className="singleComponent">
							<LoaderAnimation />
						</div>
					) : (
						<React.Fragment>
							{this.state.tasks.length > 0 || this.state.filter !== "" ? (
								<div className="appTasksWrapper">
									<TaskFilter resultsPerPage={this.props.resultsPerPage}
												setResultsPerPage={this.props.setResultsPerPage}
												maxPage={this.state.maxPage}
												initialPage={this.state.initialPage}
												setInitialPage={this.setInitialPage}
												setFilter={this.setFilter}/>
									<div className="tasksWrapper">
										<div>{tasks}</div>
									</div>
								</div>
							) : (
								<React.Fragment />
							)}
						</React.Fragment>
					)}
				</div>
				<HelperPanel activeTeamData={this.props.activeTeamData}
					refreshUserData={this.props.refreshUserData}
					refreshActiveTeamData={this.props.refreshActiveTeamData}
					createNotification={this.props.createNotification}
					helperPanelMode={this.props.helperPanelMode}
					switchHelperPanelModeTo={this.props.switchHelperPanelModeTo}
					userTags={this.props.userTags}
					refreshTaskData={this.fetchVerificationTasks}
					source={this.props.source}/>
            </div>       
        )
    }
};

export default EmailVerification;