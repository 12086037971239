import React, { Component } from "react";
import download from 'downloadjs'

import ReferByEmail from "../../appComponents/blocks/referByEmail";
import ShareLink from "../../appComponents/blocks/shareLink";
import ReferralStats from "../../appComponents/blocks/referralStats";
import PayoutDetails from "../../appComponents/blocks/payoutDetails";

let settings = require("../../settings");

class Referrals extends Component {

	render() {
		let affiliateProgram =
			settings.CDN_HOST + "images/AffiliateProgram.svg";

		return (
			<div className="referralsWrapper">

				<div className="referralsStoryWrapper">
					<div className="referralsStory">
						<div>
							<h1>Affiliate program</h1>
						</div>
						<div>
							<img
								src={affiliateProgram}
								alt="Poirot bulk usage files - https://poirot.app"
								width="460"
								height="340"
							/>
						</div>
						<div>
							<p>
								Welcome! We are so glad you’re here and want to
								learn more about the Poirot Affiliate Program!
							</p>
							<p>
								First things first, affiliates are a very
								important part of our growth strategy. We
								appreciate anyone willing to promote our product
								in exchange for recurring revenue from our
								program each month. We are committed to creating
								better and more beneficial opportunities for our
								affiliates to earn commissions.
							</p>
							<p>
								As a Poirot affiliate you’ll earn a{" "}
								<span>30%</span> commission{" "}
								<span>each month</span> from everyone you refer.
								That means if you send us an account worth
								$99/month, you’ll earn $30. Every month. Besides
								that, you and everyone you refer will receive{" "}
								<span>25 permanent credits each</span> after
								the signup. There is <span>no limit</span> to
								the amount of credit or recurring revenue you
								can earn through referrals.
							</p>
							<p>
								Joining our affiliate program is simple.
								Actually, as a Poirot user, you are already a
								member. Just use your referral link provided on
								this page and start earning money online with
								the Poirot Affiliate Program.
							</p>
							<h2>The process</h2>
							<p>
								Here’s the process just to make everything
								crystal clear: You have been given share link
								(check the right side of this page) if you want
								to start sharing Poirot immediately. There, you
								can see all your stats, enter your PayPal email
								address information for payouts, while below
								this document you can download any logos or
								assets you need. Start promoting! You’ll see any
								clicks or activity reflected in your account.
							</p>
						</div>
						<div>
							<h2>Promotion Ideas</h2>
							<p>
								Here are a few promotion ideas to get you
								started:
							</p>

							<ul>
								<li>
									Write a Poirot vs. _________ (whatever tool
									you are most familiar with or personally
									switched from) post and publish that on your
									blog and send it out to your email list.
								</li>

								<li>
									Add details about Poirot, along with your
									affiliate link, to any pages or posts you
									have on how to effectively find relevant
									leads.
								</li>

								<li>
									Share how you use Integrations to automate
									email verification process to improve your
									business and link to Poirot using your
									affiliate link.
								</li>

								<li>
									Partner with us for a webinar that you
									promote to your list and we will teach one
									hour of free content on lead generation,
									email address lookup & verification and it's
									necessity.
								</li>

								<li>
									Add Poirot to your Resources page on your
									blog as your recommended email lookup and
									verification tool.
								</li>
							</ul>

							<p>
								Anything else you can think of! It’s good to
								have you on board!
							</p>
						</div>
						<div>
							<h2>Details and terms</h2>
							<p>
								The rules on our affiliate program are pretty
								simple, but we need a few rules in place to
								prevent abuse.
							</p>
							<p>
								Payouts are currently made on the 2nd of each
								month.
							</p>
							<p>
								Affiliate commissions are approved on the 1st of
								each month. There is a minimum delay of 30 days
								before approving pending commissions (to account
								for any refunds since we have a 30-day
								no-questions-asked refund policy).
							</p>
							<p>
								Depending on when a commission is earned in a
								month, it can be pending for up to 60 days. For
								example, if you earned a commission on August
								3rd, it along with all August commissions would
								be approved on Oct 1st (creating a 57 day delay)
								and paid on Oct 2nd. If you earned a commission
								on Aug 31st, it would also get approved on Oct
								1st (creating a 30 day delay) and paid on Oct
								2nd.
							</p>
							<p>
								Affiliate links are based on first click
								attribution, meaning the commission will go to
								the affiliate whose affiliate link they clicked
								first, not last.
							</p>
							<p>
								Self-referrals are not allowed. The point is to
								get you to refer other people. This is not a way
								to get a discount on your own account.
							</p>
							<p>
								Abuse, gaming, or shady behavior will get your
								account banned.
							</p>
							<p>
								In some cases, we can give credit to an
								affiliate even if the customer didn’t sign up
								through their link by mistake. If you have a
								case like that, contact us first so we can help.
							</p>
							<p>
								We reserve the right to change the terms at
								anytime.
							</p>
							<p>
								Now, with those details out of the way we can go
								about paying you for sending great customers our
								way!
							</p>
						</div>
						<hr />
						<div>
							<h2>Brand assets</h2>
							<h3>Logos</h3>
							<p>
								The Poirot logo only comes in long arrangement -
								wordmark next to logomark. Please use it that
								way and make sure to leave plenty of space
								around the logo while not distorting the
								dimensions. We prefer you use our logo with the
								wordmark attached, but if you need our logomark
								on its own, we've provided that too!
							</p>
							<div className="referralsStoryLogosDownloadWrapper">
								<div>
									<div>
										<img
											src={"/downloads/PoirotLogoDark.svg"}
											alt="Poirot Application Logo - https://poirot.app"
										/>
									</div>
									<div className="logoDownloadTypeSelectorWrapper">
										<div onClick={() => download("/downloads/PoirotLogoDark.svg")}>SVG</div>
										<div onClick={() => download("/downloads/PoirotLogoDark.png")}>PNG</div>
										<div onClick={() => download("/downloads/PoirotLogoDark.jpg")}>JPG</div>
									</div>
								</div>
								<div>
									<div>
										<img
											src={"/downloads/PoirotLogoLight.svg"}
											alt="Poirot Application Logo - https://poirot.app"
										/>
									</div>
									<div className="logoDownloadTypeSelectorWrapper">
										<div onClick={() => download("/downloads/PoirotLogoLight.svg")}>SVG</div>
										<div onClick={() => download("/downloads/PoirotLogoLight.png")}>PNG</div>
										<div onClick={() => download("/downloads/PoirotLogoLight.jpg")}>JPG</div>
									</div>
								</div>
								<div>
									<div>
										<img
											src={"/downloads/PoirotLogoGrayscale.svg"}
											alt="Poirot Application Logo - https://poirot.app"
										/>
									</div>
									<div className="logoDownloadTypeSelectorWrapper">
										<div onClick={() => download("/downloads/PoirotLogoGrayscale.svg")}>SVG</div>
										<div onClick={() => download("/downloads/PoirotLogoGrayscale.png")}>PNG</div>
										<div onClick={() => download("/downloads/PoirotLogoGrayscale.jpg")}>JPG</div>
									</div>
								</div>
							</div>
							<div className="referralsStoryStampsDownloadWrapper">
								<div>
									<div>
										<img
											src={"/downloads/PoirotStamp.svg"}
											alt="Poirot Application Logo - https://poirot.app"
										/>
									</div>
									<div className="logoDownloadTypeSelectorWrapper">
										<div onClick={() => download("/downloads/PoirotStamp.svg")}>SVG</div>
										<div onClick={() => download("/downloads/PoirotStamp.png")}>PNG</div>
										<div onClick={() => download("/downloads/PoirotStamp.jpg")}>JPG</div>
									</div>
								</div>
								<div>
									<div>
										<img
											src={"/downloads/PoirotStampGrayscale.svg"}
											alt="Poirot Application Logo - https://poirot.app"
										/>
									</div>
									<div className="logoDownloadTypeSelectorWrapper">
										<div onClick={() => download("/downloads/PoirotStampGrayscale.svg")}>SVG</div>
										<div onClick={() => download("/downloads/PoirotStampGrayscale.png")}>PNG</div>
										<div onClick={() => download("/downloads/PoirotStampGrayscale.jpg")}>JPG</div>
									</div>
								</div>
								<div className="downloadAlllogoAssetsWrapper">
									<button className="defaultButtonGreen" onClick={() => download("/downloads/PoirotLogosAll.zip")}>
										Download all logo assets
									</button>
								</div>
							</div>

							<h3>Product images</h3>
							<p>
								Want some examples to use when you mention
								Poirot? We've put together some screenshots of
								the main parts of our app.
							</p>

							<div className="referralsStoryProductImagesDownloadWrapper">
							</div>

							<h3>Colors</h3>
							<p>
								We use predominantely Bright Navy Blue & Yellow
								Green as our main colors, with the addition of
								Sunglow Yellow and Portland Orange when
								required. Our backgrounds range from pure White
								to Earie Black.
							</p>
							<div className="colorPalette">

								<div className="colorPaletteMainColors">
									<div>
										<div className="brightNavyBlue"></div>
										<div className="colorDescription">
											<div>Bright Navy Blue</div>
											<div>HEX&nbsp;<span>#0070C9</span></div>
										</div>
									</div>
									<div>
										<div className="yellowGreen"></div>
										<div className="colorDescription">
											<div>Yellow Green</div>
											<div>HEX&nbsp;<span>#A4D037</span></div>
										</div>
									</div>
									<div>
										<div className="sunglow"></div>
										<div className="colorDescription">
											<div>Sunglow</div>
											<div>HEX&nbsp;<span>#FECD35</span></div>
										</div>
									</div>
									<div>
										<div className="portlandOrange"></div>
										<div className="colorDescription">
											<div>Portland Orange</div>
											<div>HEX&nbsp;<span>#F05230</span></div>
										</div>
									</div>
								</div>

								<div className="colorPaletteGrayscale">
									<div>
										<div className="earieBlack"></div>
										<div className="colorDescription">
											<div>Eerie Black</div>
											<div>HEX&nbsp;<span>#212425</span></div>
										</div>
									</div>
									<div>
										<div className="outerSpaceCrayola"></div>
										<div className="colorDescription">
											<div>Outer Space</div>
											<div>HEX&nbsp;<span>#343F44</span></div>
										</div>
									</div>
									<div>
										<div className="silverSand"></div>
										<div className="colorDescription">
											<div>Silver Sand</div>
											<div>HEX&nbsp;<span>#C0C0C0</span></div>
										</div>
									</div>
									<div>
										<div className="gainsboro"></div>
										<div className="colorDescription">
											<div>Gainsboro</div>
											<div>HEX&nbsp;<span>#DEDEDE</span></div>
										</div>
									</div>
									<div>
										<div className="cultured"></div>
										<div className="colorDescription">
											<div>Cultured</div>
											<div>HEX&nbsp;<span>#F2F2F2</span></div>
										</div>
									</div>

								</div>
								
							</div>

						</div>

					</div>
				</div>

				<div className="referralsActionAreaWrapper">
					<div className="referralsActionArea">
						<div className="referralsActionAreaBlock">
							<ReferByEmail
								googleTokenPath={this.props.googleTokenPath}
								userData={this.props.userData}
								refreshUserData={this.props.refreshUserData}
								createNotification={
									this.props.createNotification
								}
							/>
						</div>
						<div className="referralsActionAreaBlock">
							<ShareLink createNotification={this.props.createNotification}
										userData={this.props.userData} />
						</div>
						<div className="referralsActionAreaBlock">
							<ReferralStats userData={this.props.userData} />
						</div>
						<div className="referralsActionAreaBlock">
							<PayoutDetails
								createNotification={
									this.props.createNotification
								}
								refreshUserData={this.props.refreshUserData}
								userData={this.props.userData}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Referrals;
