import React, { Component } from "react";
import axios from 'axios'

import PhoneInput from 'react-phone-input-2'

let settings = require("../../settings");
let authentication = require("../../authentication");

let icons = require("../icons");

class TwoFactorAuth extends Component {
	constructor(props) {
		super(props)

		this.state=({
			activationStatus: 'none',
			phone: '',
			defaultCountry: 'us',
			verificationCode: ''
		})

		this.handleInputChange = this.handleInputChange.bind(this)
		this.requestSMSCode = this.requestSMSCode.bind(this)
		this.activate2FA = this.activate2FA.bind(this)
		this.disable2FA = this.disable2FA.bind(this)
		this.handleKeyDown = this.handleKeyDown.bind(this)
	}

	componentDidMount() {
		var self = this;
		axios.get('https://www.cloudflare.com/cdn-cgi/trace')
            .then(function (response) {
            	//let locationString = response.data.replace(/(\r\n|\n|\r)/gm, "")
            	let index = response.data.indexOf('loc=')
            	self.setState({
            		defaultCountry: response.data.substring(index + 4, index + 6).toLowerCase(),
            	})       	
	        })
	            .catch(function (error) {
					console.log(error)
        });
	}

	handleInputChange(event) {

        const target = event.target;
        var value = target.value;
        const name = target.name;

        if (!isNaN(value) && value.length <= 6) {
	        this.setState({
	            [name]: value
	        });
	    }
    }

	requestSMSCode(event) {
		event.preventDefault();

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {			
			return;
		}

		const requestCredentials = {
			phoneNumber: this.state.phone
		};

		if (!/^([0-9+]{6,128})$/.test(this.state.phone)) {
			this.props.createNotification(
				"error",
				"Please enter a valid phone number",
				"Error - Phone Number",
				7000
			);
			return;
		}

		axios
			.post(settings.API_HOST + "user/requestsms", requestCredentials, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({activationStatus: 'verify'})
				} else {
					self.props.createNotification(
						"error",
						"Internal server error.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				self.props.createNotification(
					"error",
					"API server error occurred. Please try again.",
					"Error",
					6000
				);
			});
	}

	activate2FA(event) {
		event.preventDefault()

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {			
			return;
		}

		const requestCredentials = {
			code: this.state.verificationCode
		};

		if (isNaN(this.state.verificationCode || this.state.verificationCode.length !== 6)) {
			this.props.createNotification(
				"error",
				"Please enter a valid 6-digit verification code",
				"Error - Verification Code",
				7000
			);
			return;
		}

		axios
			.post(settings.API_HOST + "user/activate2fa", requestCredentials, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({activationStatus: 'activated', 
									phone: '',
									verificationCode: ''})
					self.props.refreshUserData()
				} else {
					self.props.createNotification(
						"error",
						"Internal server error.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				self.props.createNotification(
					"error",
					"API server error occurred. Please try again.",
					"Error",
					6000
				);
			});
	}

	disable2FA(event) {
		event.preventDefault()

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {			
			return;
		}

		axios
			.delete(settings.API_HOST + "user/2fa", {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({activationStatus: 'none', 
									phone: '',
									verificationCode: ''})
					self.props.refreshUserData()
				} else {
					self.props.createNotification(
						"error",
						"Internal server error.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				self.props.createNotification(
					"error",
					"API server error occurred. Please try again.",
					"Error",
					6000
				);
			});
	}

	handleKeyDown(event) {
		if (event.key === 'Enter') {
		      this.requestSMSCode(event)
	    }
	}

	render() {

		return (
			<React.Fragment>
				{this.props.enabled ?

					//
					// Check if user has already activated 2fa
					//
					<React.Fragment>
						{this.props.userData.twoFactorAuthentication ?
							<div className="twoFactorAuth activated2FA">
								<div><h1>Account secured</h1></div>
								<div className="tfaEnablerWrapper">
									<div className="textWrapper">
										<h3>Two-factor authentication is enabled on your account.</h3>
									</div>
									<div className="tfaActionButtonWrapper">
										<button className="defaultButtonRed" onClick={this.disable2FA}>
											Disable two-factor authentication
										</button>
									</div>
								</div>
							</div>

							:

							<div className="twoFactorAuth">
								<div><h1>Secure your account</h1></div>
								<div className="tfaEnablerWrapper">
									<div className="lockImageWrapper">
										{icons.lock()}
									</div>
									<div className="textWrapper">
										{this.state.activationStatus === 'none' ? <p>Two-factor authentication adds an extra layer of security to your account. To log in, you'll need to provide a code along with your username and password. This lets us know it's actually you.</p> : <React.Fragment />}
										{this.state.activationStatus === 'numberInput' ?
										<div className="phoneVerificationWrapper">
											<h3>Verify your phone number</h3>
											<p>Enter your cell phone number and we will send you a verification code.</p>
											<div>
												<PhoneInput
													country={this.state.defaultCountry}
													value={this.state.phone}
													enableSearch={true}
													disableSearchIcon={true}
													onChange={phone => this.setState({ phone })}
													onKeyDown={this.handleKeyDown}
													inputProps={{
													    autoFocus: true
													  }}
												/> 
											</div>
										</div>
										: <React.Fragment /> }
										{this.state.activationStatus === 'verify' ? 
										<div className="tfaVerificationCodeWrapper">
											<h3>Input verification code</h3> 
											<p>To activate two-factor authentication, please enter the 6-digit code from the message you received.</p>
											<form className="tfaVerificationCode" onSubmit={this.activate2FA} id="codeInputForm">
												<input type="text" name="verificationCode" onChange={this.handleInputChange} value={this.state.verificationCode} autoFocus/>
											</form>
										</div>
										:
										<React.Fragment />
										}
									</div>

									<div className="tfaActionButtonWrapper">
										{this.state.activationStatus === 'none' ? 
										<button className="defaultButtonBlue" onClick={() => this.setState({activationStatus: 'numberInput'})}>
											Enable two-factor authentication
										</button>
										:
										<React.Fragment />
										}

										{this.state.activationStatus === 'numberInput' ?
										<React.Fragment>
											<button className="defaultButtonBlue" type="submit" onClick={this.requestSMSCode}>
												Request verification code
											</button>
											<button className="cancelButton" onClick={() => this.setState({activationStatus: 'none',
																											phone: '',
																											verificationCode: ''})}>
												Cancel
											</button>
										</React.Fragment>
										:
										<React.Fragment/>
										}	

										{this.state.activationStatus === 'verify' ?
										<React.Fragment>
											<button className="defaultButtonBlue" type="submit" form="codeInputForm">
												Activate 2FA
											</button>
											<button className="cancelButton" onClick={() => this.setState({activationStatus: 'none',
																											phone: '',
																											verificationCode: ''})}>
												Cancel
											</button>
										</React.Fragment>
										:
										<React.Fragment />
										}	

									</div>

								</div>
								
							</div>
						}
					</React.Fragment>

				:
					<div className="twoFactorAuth">
						<div><h1>Secure your account</h1></div>
						<div className="tfaEnablerWrapper">
							<div className="textWrapper">
								<h3>Two-factor authentication is supported on paid plans only.</h3>
							</div>
						</div>
					</div>
				}
			</React.Fragment>
		)
	}
}

export default TwoFactorAuth;
