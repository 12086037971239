import React, { Component } from "react";

class AddressRemoval extends Component {
	render() {
		return (
			<div className="teamRemovalWrapper">
				<div className="teamRemoval">
					<h1>Remove billing address</h1>
					<p>
						Are you sure you would like to remove:{" "}
						<div><br /></div>
						<span>
							{
								<div>
									<div>{this.props.customerName}</div>
									<div>{this.props.streetLine1}</div>
									<div>{this.props.streetLine2}</div>
									<div>{this.props.city}, {this.props.postalCode}</div>
									<div>{this.props.region}</div>
									<div>{this.props.country}</div>
								</div>
							}
						</span>
						<div><br /></div>
					</p>
					<p>
						If removed, this address won't appear on your future invoices.
					</p>
					<h4>You can always add another billing address if required.</h4>
					<div className="teamRemovalButtonWrapper">
						<button className="cancelButton"
							onClick={this.props.toggleAddressRemove}
						>
							Cancel
						</button>
						<button
							className="defaultButtonRed"
							onClick={this.props.handleAddressDelete}
						>
							Remove
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default AddressRemoval;