import React, { Component } from "react";

import axios from 'axios'

let settings = require('./settings')

class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: "",
			honeyPot: "",
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handlePasswordResetRequest = this.handlePasswordResetRequest.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		var value = target.value;
		const name = target.name;

		if (name === "email") {
			value = value.toLowerCase();
		}

		this.setState({
			[name]: value,
		});
	}

	handlePasswordResetRequest(event) {
        event.preventDefault();

        const requestCredentials = {
            "email": this.state.email,
        };

        if (this.state.honeyPot !== "") {
        	return
        }

        if (this.state.email === "") {
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

        if (!/^[a-zA-Z0-9_.][a-zA-Z0-9_.]*(\.[a-zA-Z.][a-zA-Z0-9_.]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/.test(this.state.email)) {
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

        var self = this;

        axios.post(settings.API_HOST+'user/passwordrecovery', requestCredentials)
            .then(function (response) {
                if (response.status === 200) {
                    window.location.href =  settings.WEB_HOST+'resetpasswordnotification'; 
                    return
                } else {
                    self.props.createNotification('error', 'Internal server error.', 'Error', 6000);
                }

        })
            .catch(function (error) {
                self.props.createNotification('error', 'API server error occurred. Please try again.', 'Error', 6000);
        });
    }

	render() {
		let poirotLogo = settings.CDN_HOST + "images/PoirotLogoDark.svg";

		return (
			<div className="fullScreenWrapper">
				<div className="authenticationSectionWrapper">
					<div className="authenticationSectionHeader">
						<div className="logoWrapper">
							<a href={settings.WEB_HOST}>
								<img
									src={poirotLogo}
									alt="Poirot Application Logo - https://poirot.app"
								/>
							</a>
						</div>
					</div>
					<div className="resetPasswordWrapper">
						<form className="authenticationForm" onSubmit={this.handlePasswordResetRequest}>
							<div>
								<h1>Reset your Poirot password</h1>
							</div>
							<div>
								<div>
									<h2>
										Just tell us the email address you
										registered with Poirot
									</h2>
								</div>
								<div>
									<input
										autoFocus
										name="email"
										type="email"
										placeholder="Your email address"
										value={this.state.email}
										onChange={this.handleInputChange}
									/>
								</div>
								<div className="honeyPot">
									<input
										name="honeyPot"
										type="text"
										placeholder="honeyPot"
										value={this.state.honeyPot}
										onChange={this.handleInputChange}
									/>
								</div>
							</div>
							<div className="resetPasswordButtonsWrapper">
								<div>
									<a href="/signin">
									<svg
										height="32"
										viewBox="0 0 32 32"
										width="32"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="m16.01 32c8.782 0 15.99-7.224 15.99-16s-7.228-16-16.01-16-15.99 7.224-15.99 16 7.228 16 16.01 16zm0-1.449c-8.057 0-14.56-6.52-14.56-14.551s6.503-14.551 14.54-14.551c8.057 0 14.56 6.52 14.56 14.551s-6.503 14.551-14.54 14.551zm-8.347-14.551c0 .207.083.373.228.517l6.069 6.065c.124.124.29.207.497.207.352 0 .621-.29.621-.642 0-.206-.083-.351-.228-.496l-3.065-3.043-2.05-1.987 2.568.083h11.391c.394 0 .684-.29.684-.683 0-.414-.29-.704-.684-.704h-11.391l-2.568.083 2.05-1.987 3.065-3.043c.145-.145.228-.29.228-.497 0-.352-.269-.641-.621-.641-.207 0-.373.082-.497.207l-6.069 6.064c-.165.166-.228.331-.228.497z"
											fill="#343f44"
										/>
									</svg>
									</a>
								</div>
								<div>
									<button className="defaultButtonBlue">
										Reset Password
									</button>
								</div>
							</div>
						</form>
					</div>
					<div className="authenticationSectionFooter">
						<div>
							<a href="/register">
								New to Poirot? Register your account
							</a>
						</div>
						<div>|</div>
						<div>
							<a href="https://www.poirot.app/systemstatus">
								System status
							</a>
						</div>
						<div>|</div>
						<div>
							<p>
								Copyright &copy; {new Date().getFullYear()}{" "}
								Intuos llc. All rights reserved.
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ResetPassword;
