import React, { Component } from 'react';
import md5 from 'md5';

class Gravatar extends Component {

    render() {
        var userEmailAddress = this.props.userEmailAddress
        userEmailAddress = userEmailAddress.replace(/^\s+|\s+$/g, "");
        userEmailAddress = userEmailAddress.toLowerCase();
        userEmailAddress = md5(userEmailAddress);

        let hashedUserImage = typeof this.props.defaultImage !== 'undefined' ? 
        "https://www.gravatar.com/avatar/"+userEmailAddress+"?s=256&d=" + this.props.defaultImage
        :
        "https://www.gravatar.com/avatar/"+userEmailAddress+"?s=256&d=https%3A%2F%2Fcdn.poirot.app%2Fimages%2Fperson-circle.png"

        let imageSize = typeof this.props.imageSize !== 'undefined' ?
        this.props.imageSize
        :
        "32"

        return(
            <img className="gravatarImage" src={hashedUserImage} alt="UserGravatarImage" width={imageSize} height={imageSize}/>        
        )
    }
};

export default Gravatar;