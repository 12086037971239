import React from "react";

export const chevronRight = () => (
	<svg
		className="chevronRight"
		height="12.009"
		viewBox="0 0 6.775 12.009"
		width="6.775"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m.568 21.275c.153 0 .288-.063.396-.171l5.613-5.406c.126-.126.198-.27.198-.432s-.072-.315-.198-.423l-5.613-5.406c-.108-.108-.243-.171-.396-.171-.325 0-.568.243-.568.558 0 .153.063.297.162.406l5.225 5.036-5.225 5.036c-.099.108-.162.253-.162.406 0 .324.243.567.568.567z"
			fill="#fff"
			transform="translate(0 -9.266)"
		/>
	</svg>
);

export const prevPage = () => (
	<svg
		className="prevPage"
		height="14"
		viewBox="0 0 7.898 14"
		width="7.898"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="m6.764 13.8-6.543-6.301c-.137-.137-.221-.305-.221-.494s.084-.367.221-.504l6.543-6.301c.136-.126.283-.2.472-.2.368 0 .662.284.662.662 0 .178-.074.346-.189.472l-6.092 5.871 6.092 5.871c.115.126.189.284.189.473 0 .367-.294.651-.662.651-.189 0-.346-.074-.472-.2z" />
	</svg>
);
export const nextPage = () => (
	<svg
		className="nextPage"
		height="14"
		viewBox="0 0 7.898 14"
		width="7.898"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="m1.134 13.8 6.543-6.301c.137-.137.221-.305.221-.494s-.084-.367-.221-.504l-6.543-6.301c-.136-.126-.283-.2-.472-.2-.368 0-.662.284-.662.662 0 .178.074.346.189.472l6.092 5.871-6.092 5.871c-.115.126-.189.284-.189.473 0 .367.294.651.662.651.189 0 .346-.074.472-.2z" />
	</svg>
);

export const cloudUpload = () => (
	<svg
		height="90.597"
		viewBox="0 0 121.029 90.597"
		width="121.029"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m367.564 368.618c19.613 0 35.512 15.899 35.512 35.512l-.014.559c14.726.931 24.309 13.153 25.018 27.205 0 15.089-12.232 27.321-27.32 27.321h-.003-22.696v-20.458h14.85c2.289-.842 3.181-3.175 3.405-5.4-.154-1.534.037-.858-.466-2.053l-28.284-29.196-28.284 29.195c-.503 1.196-.312.52-.467 2.054.094 2.285 1.229 4.485 3.405 5.4h14.85v20.458h-22.696-.002c-15.089 0-27.321-12.232-27.321-27.321.254-13.676 10.839-26.489 25.016-27.204l-.015-.56c0-19.613 15.9-35.512 35.512-35.512z"
			transform="translate(-307.051 -368.618)"
		/>
	</svg>
);

export const crispChatIcon = () => (
	<svg
		className="crispChatIcon"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		x="0"
		y="0"
		width="54"
		height="54"
		viewBox="0, 0, 54, 54"
	>
		<defs>
			<linearGradient
				id="Gradient_1"
				gradientUnits="userSpaceOnUse"
				x1="6.437"
				y1="-2.163"
				x2="6.437"
				y2="89.142"
			>
				<stop className="stop1" offset="0" stopColor="#42A1EC" />
				<stop className="stop2" offset="1" stopColor="#0070C9" />
			</linearGradient>
			<clipPath id="Clip_1">
				<path d="M12.47,14.007 L51.53,14.007 L51.53,51.993 L12.47,51.993 z M44.099,22.007 L18.47,24.839 L19.901,40.444 L29.031,39.435 L33.318,43.993 L36.661,38.592 L45.53,37.612 L44.099,22.007 z" />
			</clipPath>
			<filter id="Shadow_2">
				<feGaussianBlur in="SourceAlpha" stdDeviation="2.5" />
				<feOffset dx="0" dy="2" result="offsetblur" />
				<feFlood floodColor="#000000" />
				<feComposite in2="offsetblur" operator="in" />
			</filter>
		</defs>
		<g id="Layer_1" transform="translate(-5, -6)">
			<g>
				<path
					d="M32,60 C17.088,60 5,47.912 5,33 C5,18.088 17.088,6 32,6 C46.912,6 59,18.088 59,33 C59,47.912 46.912,60 32,60 z"
					fill="url(#Gradient_1)"
				/>
				<g>
					<path
						d="M44.099,22.007 L45.53,37.612 L36.661,38.592 L33.318,43.993 L29.031,39.435 L19.901,40.444 L18.47,24.839 L44.099,22.007 z"
						clipPath="url(#Clip_1)"
						filter="url(#Shadow_2)"
						fill="rgba(0,0,0,0.2)"
					/>
					<path
						d="M29.031,39.435 L33.318,43.993 L36.661,38.592 L45.53,37.612 L44.099,22.007 L18.47,24.839 L19.901,40.444 z"
						fill="#FFFFFF"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export const checkCircle = () => (
	<svg
		className="checkCircle"
		height="28"
		viewBox="0 0 28 28"
		width="28"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m13.991 28c7.646 0 14.009-6.35 14.009-14 0-7.651-6.38-14-14.026-14-7.629 0-13.974 6.349-13.974 14 0 7.65 6.363 14 13.991 14z"
			fill="#a4d037"
		/>
		<path
			d="m12.309 20.8c-.52 0-.918-.225-1.334-.728l-3.624-4.58c-.225-.295-.347-.625-.347-.972 0-.676.52-1.232 1.196-1.232.417 0 .781.174 1.145.642l2.895 3.886 5.86-9.472c.278-.468.676-.711 1.075-.711.642 0 1.266.434 1.266 1.145 0 .347-.174.694-.382 1.006l-6.501 10.288c-.312.485-.728.728-1.249.728z"
			fill="#fff"
		/>
	</svg>
);

export const timesCircle = () => (
	<svg
		className="timesCircle"
		height="28"
		viewBox="0 0 28 28"
		width="28"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m13.991 28c7.646 0 14.009-6.35 14.009-14 0-7.651-6.38-14-14.026-14-7.629 0-13.974 6.349-13.974 14 0 7.65 6.363 14 13.991 14z"
			fill="#f05230"
		/>
		<path
			d="m9.414 19.846c-.676 0-1.231-.572-1.231-1.266 0-.33.139-.625.364-.85l3.693-3.713-3.693-3.712c-.225-.226-.364-.521-.364-.85 0-.694.555-1.215 1.231-1.215.347 0 .624.104.85.347l3.71 3.713 3.762-3.73c.26-.26.52-.365.85-.365.676 0 1.231.538 1.231 1.232 0 .33-.122.607-.364.85l-3.711 3.73 3.693 3.695c.226.243.364.521.364.868 0 .694-.554 1.266-1.231 1.266-.364 0-.659-.156-.884-.381l-3.71-3.713-3.693 3.713c-.208.242-.52.381-.867.381z"
			fill="#fff"
		/>
	</svg>
);

export const trashCan = () => (
	<svg
		height="20"
		viewBox="0 0 17.322 20"
		width="17.322"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m4.561 20h8.223c1.041 0 1.859-.772 1.918-1.813l.713-14.023h1.532c.223 0 .375-.164.375-.375 0-.222-.164-.385-.375-.385h-4.467v-1.579c0-1.147-.737-1.825-1.977-1.825h-3.684c-1.228 0-1.965.678-1.965 1.825v1.579h-4.468c-.199 0-.386.175-.386.385 0 .211.187.375.386.375h1.544l.713 14.023c.059 1.041.866 1.813 1.918 1.813zm1.076-18.152c0-.678.457-1.088 1.193-1.088h3.661c.749 0 1.205.41 1.205 1.088v1.556h-6.059zm-1.029 17.392c-.631 0-1.146-.503-1.181-1.123l-.714-13.953h11.895l-.702 13.953c-.035.632-.549 1.123-1.181 1.123zm1.298-1.965c.211 0 .34-.14.34-.328l-.328-10.479c0-.187-.152-.304-.339-.304-.199 0-.328.128-.328.327l.328 10.468c0 .187.129.316.327.316zm2.772 0c.199 0 .34-.129.34-.328v-10.456c0-.199-.141-.327-.34-.327-.198 0-.339.128-.339.327v10.456c0 .199.141.328.339.328zm2.749.012c.199 0 .327-.129.339-.316l.328-10.48c0-.199-.141-.316-.34-.316-.187 0-.327.117-.327.305l-.328 10.479c-.011.187.117.328.328.328z"
			fill="#343f44"
		/>
	</svg>
);

export const settings = () => (
	<svg
		height="20"
		viewBox="0 0 23.643 20"
		width="23.643"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m16.589 5.101c1.209 0 2.233-.868 2.481-2h4.062c.279 0 .511-.233.511-.527 0-.295-.232-.527-.511-.527h-4.047c-.232-1.163-1.271-2.047-2.496-2.047s-2.263.884-2.496 2.047h-13.566c-.294 0-.527.232-.527.527 0 .294.233.527.527.527h13.582c.248 1.132 1.271 2 2.48 2zm0-.992c-.868 0-1.566-.698-1.566-1.566 0-.884.698-1.566 1.566-1.566.884 0 1.566.682 1.566 1.566 0 .868-.682 1.566-1.566 1.566zm-16.077 5.364c-.279 0-.512.232-.512.527s.233.527.512.527h4.527c.232 1.163 1.271 2.031 2.496 2.031s2.248-.868 2.496-2.031h13.085c.295 0 .527-.232.527-.527s-.232-.527-.527-.527h-13.1c-.249-1.132-1.272-2-2.481-2-1.225 0-2.248.868-2.496 2zm7.023 2.108c-.884 0-1.566-.697-1.566-1.581 0-.868.682-1.55 1.566-1.55s1.566.682 1.566 1.55c0 .884-.682 1.581-1.566 1.581zm9.054 8.419c1.225 0 2.248-.884 2.496-2.031h4.047c.279 0 .511-.233.511-.527 0-.295-.232-.527-.511-.527h-4.047c-.248-1.148-1.287-2.016-2.496-2.016s-2.248.868-2.496 2.016h-13.566c-.294 0-.527.232-.527.527 0 .294.233.527.527.527h13.566c.248 1.147 1.271 2.031 2.496 2.031zm0-.992c-.868 0-1.566-.698-1.566-1.566 0-.884.698-1.566 1.566-1.566.884 0 1.566.682 1.566 1.566 0 .868-.682 1.566-1.566 1.566z"
			fill="#343f44"
		/>
	</svg>
);

export const lock = () => (
	<svg
		height="74.414"
		viewBox="0 0 74.463 74.414"
		width="74.463"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="m37.207 74.414c20.459 0 37.256-16.748 37.256-37.207s-16.797-37.207-37.256-37.207-37.207 16.748-37.207 37.207 16.748 37.207 37.207 37.207zm0-2.197c-19.287 0-34.961-15.723-34.961-35.01s15.674-35.01 34.961-35.01c19.336 0 35.01 15.723 35.01 35.01s-15.674 35.01-35.01 35.01zm-13.965-19.971c0 3.174 1.367 4.541 4.248 4.541h19.483c2.832 0 4.248-1.367 4.248-4.541v-16.016c0-3.173-1.416-4.541-4.248-4.541v-5.664c0-6.25-3.907-10.351-9.766-10.351-5.811 0-9.766 4.101-9.766 10.351v5.664c-2.832 0-4.199 1.368-4.199 4.541zm6.299-20.557v-5.664c0-4.98 3.076-8.3 7.666-8.3s7.715 3.32 7.715 8.3v5.664z" />
	</svg>
);

export const creditCard = () => (
	<svg
		className="creditCardIcon"
		height="30"
		viewBox="0 0 40 30"
		width="40"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="m4.525 30h30.972c2.958 0 4.503-1.592 4.503-4.448v-21.104c0-2.878-1.545-4.448-4.503-4.448h-30.972c-2.958 0-4.525 1.548-4.525 4.448v21.104c0 2.9 1.567 4.448 4.525 4.448zm-3.532-25.487c0-2.333 1.214-3.532 3.577-3.532h30.883c2.251 0 3.554 1.199 3.554 3.532v2.529h-38.014zm3.577 24.506c-2.363 0-3.577-1.199-3.577-3.532v-14.738h38.014v14.738c0 2.333-1.303 3.532-3.554 3.532zm2.494-4.317h4.238c1.016 0 1.7-.676 1.7-1.635v-3.183c0-.96-.684-1.635-1.7-1.635h-4.238c-1.015 0-1.7.675-1.7 1.635v3.183c0 .959.685 1.635 1.7 1.635z" />
	</svg>
);

export const googleLogo = () => (
	<svg
		height="20"
		viewBox="0 0 20 20"
		width="20"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m20 10.222c0-.822-.068-1.422-.215-2.044h-9.581v3.711h5.624c-.114.922-.726 2.311-2.087 3.244l-.019.125 3.03 2.299.209.021c1.928-1.745 3.039-4.311 3.039-7.356"
			fill="#4285f4"
		/>
		<path
			d="m10.204 20c2.755 0 5.068-.889 6.757-2.422l-3.22-2.445c-.861.589-2.018 1-3.537 1-2.698 0-4.989-1.744-5.805-4.155l-.12.01-3.149 2.389-.042.112c1.678 3.267 5.125 5.511 9.116 5.511"
			fill="#34a853"
		/>
		<path
			d="m4.399 11.978c-.215-.622-.34-1.289-.34-1.978s.125-1.356.329-1.978l-.006-.132-3.189-2.428-.105.049c-.691 1.356-1.088 2.878-1.088 4.489s.397 3.133 1.088 4.489z"
			fill="#fbbc05"
		/>
		<path
			d="m10.204 3.867c1.916 0 3.209.811 3.946 1.489l2.879-2.756c-1.768-1.611-4.07-2.6-6.825-2.6-3.991 0-7.438 2.244-9.116 5.511l3.3 2.511c.827-2.411 3.118-4.155 5.816-4.155"
			fill="#eb4335"
		/>
	</svg>
);

export const plus = () => (
	<svg
		height="30.783"
		viewBox="0 0 33.42 30.783"
		width="33.42"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="m16.645 30.783c-.868 0-1.476-.653-1.476-1.609v-12.13h-12.286c-.913 0-1.651-.609-1.651-1.479 0-.957.695-1.609 1.651-1.609h12.286v-12.304c0-.957.608-1.652 1.476-1.652.998 0 1.607.695 1.607 1.652v12.304h12.286c.955 0 1.65.652 1.65 1.609 0 .87-.738 1.479-1.65 1.479h-12.286v12.13c0 .956-.609 1.609-1.607 1.609z" />
	</svg>
);

export const minus = () => (
	<svg
		height="30.783"
		viewBox="0 0 33.42 30.783"
		width="33.42"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="m1.634 16.897c-.946 0-1.634-.603-1.634-1.462 0-.947.645-1.549 1.634-1.549h30.151c.99 0 1.635.602 1.635 1.549 0 .859-.688 1.462-1.635 1.462z" />
	</svg>
);

export const linkedInLogo = () => (
	<svg
		className="linkedInLogo"
		height="65.326"
		viewBox="0 0 267.504 65.326"
		width="267.504"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="#dedede">
			<path
				d="m1165 274.515a1.2 1.2 0 0 0 1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025 1.291 2.029h.724l-1.389-2.1zm-.783-.472h-.785v-1.593h.995c.514 0 1.1.084 1.1.757 0 .774-.593.836-1.314.836"
				transform="translate(-901.256 -213.406)"
			/>
			<path
				d="m203.8 92.5h9.993v36.4h18.5v9.222h-28.493z"
				transform="translate(-203.8 -82.913)"
			/>
			<path
				d="m322.443 105.384h9.6v30.916h-9.6zm4.8-15.37a5.569 5.569 0 1 1 -5.57 5.569 5.569 5.569 0 0 1 5.57-5.569"
				transform="translate(-289.532 -81.108)"
			/>
			<path
				d="m506.953 92.486h9.6v27.265l10.88-12.583h11.77l-12.6 14.313 12.335 16.63h-12.066l-10.191-15.282h-.126v15.28h-9.6z"
				transform="translate(-424.284 -82.906)"
			/>
			<path
				d="m379.2 144.337h9.219v4.225h.131a10.085 10.085 0 0 1 9.09-4.994c9.735 0 11.527 6.405 11.527 14.726v16.954h-9.6v-15.031c0-3.588-.066-8.2-5-8.2-4.99 0-5.76 3.907-5.76 7.939v15.288h-9.6z"
				transform="translate(-331.366 -120.058)"
			/>
			<path
				d="m639.93 155.719a5.756 5.756 0 0 0 -5.894-5.89 6.406 6.406 0 0 0 -6.784 5.89zm8.132 13.7a16.909 16.909 0 0 1 -13.128 6.151c-9.6 0-17.286-6.408-17.286-16.331s7.685-16.328 17.286-16.328c8.973 0 14.6 6.4 14.6 16.328v3.01h-22.282a7.171 7.171 0 0 0 7.235 6.019 8.193 8.193 0 0 0 6.851-3.778z"
				transform="translate(-504.794 -119.581)"
			/>
			<path
				d="m761.844 114.645c-4.8 0-7.68 3.205-7.68 7.875s2.879 7.878 7.68 7.878 7.687-3.2 7.687-7.878-2.881-7.875-7.687-7.875m16.525 23.437h-8.838v-4.1h-.131a12.071 12.071 0 0 1 -9.544 4.868c-9.224 0-15.3-6.657-15.3-16.071 0-8.646 5.377-16.585 14.216-16.585 3.973 0 7.684 1.087 9.861 4.1h.126v-17.821h9.609z"
				transform="translate(-597.094 -82.896)"
			/>
			<path
				d="m958.98 112.559h-9.6v-15.034c0-3.585-.064-8.2-4.993-8.2-5 0-5.765 3.906-5.765 7.939v15.294h-9.6v-30.916h9.216v4.225h.129a10.1 10.1 0 0 1 9.093-4.994c9.73 0 11.524 6.4 11.524 14.726zm-40.79-35.143a5.571 5.571 0 1 1 5.57-5.572 5.571 5.571 0 0 1 -5.57 5.572m4.8 35.143h-9.61v-30.917h9.61zm40.776-55.2h-55.21a4.728 4.728 0 0 0 -4.781 4.67v55.439a4.731 4.731 0 0 0 4.781 4.675h55.21a4.741 4.741 0 0 0 4.8-4.675v-55.443a4.738 4.738 0 0 0 -4.8-4.67"
				transform="translate(-712.896 -57.355)"
			/>
			<path
				d="m1156.525 264.22a4.418 4.418 0 1 0 .085 0zm0 8.33a3.874 3.874 0 1 1 3.809-3.938v.065a3.791 3.791 0 0 1 -3.708 3.871h-.1"
				transform="translate(-893.482 -207.809)"
			/>
		</g>
	</svg>
);

export const assign = () => (
	<svg
		className="assign"
		height="14"
		viewBox="0 0 14 14"
		width="14"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="#343f44">
			<path d="m6.995 14c3.847 0 7.005-3.151 7.005-7s-3.158-7-7.005-7c-3.846 0-6.995 3.151-6.995 7s3.149 7 6.995 7z" />
			<path d="m6.995 0c3.847 0 7.005 3.151 7.005 7s-3.158 7-7.005 7c-3.846 0-6.995-3.151-6.995-7s3.149-7 6.995-7zm0 .413c-3.626 0-6.573 2.958-6.573 6.587s2.947 6.587 6.573 6.587c3.636 0 6.583-2.958 6.583-6.587s-2.947-6.587-6.583-6.587z" />
			<path d="m6.986 10.261c-.119 0-.211-.092-.211-.211v-2.802h-2.8c-.119 0-.22-.101-.22-.211 0-.129.092-.23.22-.23h2.8v-2.802c0-.128.092-.22.211-.22.138 0 .23.092.23.22v2.802h2.8c.128 0 .22.101.22.23 0 .11-.11.211-.22.211h-2.8v2.802c0 .119-.092.211-.23.211z" />
		</g>
	</svg>
);

// export const assign = () => (
// 	<svg className="assign" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg"><g fill="#343f44"><path d="m6.995 0c3.847 0 7.005 3.151 7.005 7s-3.158 7-7.005 7c-3.846 0-6.995-3.151-6.995-7s3.149-7 6.995-7zm0 .413c-3.626 0-6.573 2.958-6.573 6.587s2.947 6.587 6.573 6.587c3.636 0 6.583-2.958 6.583-6.587s-2.947-6.587-6.583-6.587z"/><path d="m6.986 10.261c-.119 0-.211-.092-.211-.211v-2.802h-2.8c-.119 0-.22-.101-.22-.211 0-.129.092-.23.22-.23h2.8v-2.802c0-.128.092-.22.211-.22.138 0 .23.092.23.22v2.802h2.8c.128 0 .22.101.22.23 0 .11-.11.211-.22.211h-2.8v2.802c0 .119-.092.211-.23.211z"/></g></svg>
// )

export const ring = () => (
	<svg
		className="ring"
		height="14"
		viewBox="0 0 14 14"
		width="14"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m6.995 0c3.847 0 7.005 3.151 7.005 7s-3.158 7-7.005 7c-3.846 0-6.995-3.151-6.995-7s3.149-7 6.995-7zm0 .413c-3.626 0-6.573 2.958-6.573 6.587s2.947 6.587 6.573 6.587c3.636 0 6.583-2.958 6.583-6.587s-2.947-6.587-6.583-6.587z"
			fill="#c0c0c0"
		/>
	</svg>
);

export const success = () => (
	<svg
		height="9.336"
		viewBox="0 0 9.053 9.336"
		width="9.053"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m136.214 113.719q.332 0 .508.547.342 1.035.488 1.035.118 0 .235-.176 2.392-3.848 4.443-6.221.528-.615 1.68-.615.273 0 .366.049.093.049.093.127 0 .127-.283.469-3.301 3.964-6.114 8.378-.195.313-.8.313-.616 0-.733-.059-.293-.127-.683-1.308-.44-1.309-.44-1.641 0-.351.596-.683.361-.215.644-.215z"
			fill="#a4d037"
			transform="translate(-134.974 -108.289)"
		/>
	</svg>
);

export const fail = () => (
	<svg
		height="9.9"
		viewBox="0 0 9.88 9.9"
		width="9.88"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m133.18 109.92-4.04 4.04 4.04 4.02-.9.92-4.04-4.04-4.04 4.04-.9-.92 4.02-4.02-4.02-4.04.9-.92 4.04 4.04 4.04-4.04z"
			fill="#f05230"
			transform="translate(-123.3 -109)"
		/>
	</svg>
);
