import React, { Component } from "react";

class UserRemoval extends Component {
	render() {
		return (
			<div className="teamRemovalWrapper">
				<div className="teamRemoval">
					<h1>Deactivate Account</h1>
					<p>
						Are you sure you would like to permanently remove your account and delete all data?
					</p>
					<p>
						All email address searches, verifications, team and user data will also be removed.
					</p>
					<h4>THIS ACTION IS IRREVERSIBLE.</h4>
					<div className="teamRemovalButtonWrapper">
						<button className="cancelButton"
							onClick={this.props.toggleRemoval}
						>
							Cancel
						</button>
						<button
							className="defaultButtonRed"
							onClick={this.props.removeUser}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default UserRemoval;
