import React, { Component } from "react";
import axios from 'axios';

import Gravatar from '../gravatar.js'

let settings = require("../../settings");
let authentication = require("../../authentication");

class ProfileData extends Component {
	constructor(props) {
		super(props)

		this.state=({
			name: this.props.userData.name,
			email: this.props.userData.email,
			inEditMode: false
		})

		this.toggleEditMode = this.toggleEditMode.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleUpdateProfileData = this.handleUpdateProfileData.bind(this);
	}

	toggleEditMode(event) {
		event.preventDefault()

		this.setState({
			name: this.props.userData.name,
			email: this.props.userData.email,
			inEditMode: !this.state.inEditMode,
		})
	}

    handleInputChange(event) {

        const target = event.target;
        var value = target.value;
        const name = target.name;

        if (name === "email") {
        	value = value.toLowerCase();
        }

        this.setState({
            [name]: value
        });
    }

    handleUpdateProfileData(event) {
    	event.preventDefault();

		 if (this.state.email === "") {
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

        if (!/^[a-z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/.test(this.state.email)) {
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

		if (this.state.name === "") {
			this.props.createNotification(
				"error",
				"Please enter a valid name.",
				"Error - team name",
				6000
			);
			return;
		}

		if (this.state.name.length < 2 || this.state.name.length > 32) {
			this.props.createNotification(
				"error",
				"Please enter a valid name. Name should be between 2 and 32 characters long.",
				"Error - team name",
				7000
			);
			return;
		}

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			this.setState({
				name: this.props.userData.name,
				email: this.props.userData.email,
				inEditMode: false
			})
			
			return;
		}

		if (this.state.name === this.props.userData.name && this.state.email.toLowerCase() === this.props.userData.email.toLowerCase()) {

			this.setState({
				name: this.props.userData.name,
				email: this.props.userData.email,
				inEditMode: false
			})
			
			return;
		}

		const requestData = {};

		if (this.state.name !== this.props.userData.name) {
			requestData.name = this.state.name
		}

		if (this.state.email.toLowerCase() !== this.props.userData.email.toLowerCase()) {
			requestData.email = this.state.email
		}

		axios
			.patch(settings.API_HOST + "user", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.props.createNotification(
                        "success",
                        "User profile has been successfully updated.",
                        "User Profile Updated",
                        7000
                    );
                    self.nameInput.blur();
					self.props.refreshUserData();
					self.setState({
						inEditMode: false,
					});
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while updating user profile data. Please try again later.",
						"Error - User Profile Update",
						7000
					);
				}
			})
			.catch(function (error) {
				if (error.response.status === 409) {
					self.props.createNotification(
						"error",
						"User email address already in use.",
						"Error - User Profile Update",
						6000
					);
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while updating user profile data. Please try again later.",
						"Error - User Profile Update",
						7000
					);
				}
			});
    }

    componentDidUpdate(prevProps, prevState) {
    	if (prevState.inEditMode === false) {
			this.nameInput.focus();
		}
	}

	render() {
		var signupDate = new Date(this.props.userData.signupDate * 1000).toLocaleDateString()

		return (
			<div className="profileData">
				<div><h1>Personal settings</h1></div>
				<div className="personalSettingsWrapper">
					<div className="settingsGravatarWrapper">
						<Gravatar userEmailAddress={this.props.userData.email}/>
					</div>
					<form id='personalDataForm' className="personalSettingsData" onSubmit={this.state.inEditMode ? this.handleUpdateProfileData : this.toggleEditMode}>
						
							<div><input ref={(input) => { this.nameInput = input; }}  className="nameInput" disabled={!this.state.inEditMode} autoFocus name="name" type="text" value={this.state.name} onChange={this.handleInputChange}></input></div>
							<div><input className="emailInput" disabled={!this.state.inEditMode} name="email" type="email" value={this.state.email} onChange={this.handleInputChange}></input></div>
						
						<div>Member since {signupDate}</div>
					</form>
					<div className="editProfileButtonWrapper">
						<button form='personalDataForm' type="submit" className="defaultButtonGreen">
							{this.state.inEditMode ? 'Update profile' : 'Edit profile' }
						</button>
						{this.state.inEditMode ? <button className="cancelButton" onClick={this.toggleEditMode}>Cancel</button> : <React.Fragment /> }
					</div>
				</div>
			</div>
		)
	}
}

export default ProfileData;
