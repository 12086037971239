import React, { Component } from "react";
import axios from "axios";

import LoaderAnimation from "../LoaderAnimation";

import TaskFilter from "../appComponents/taskFilter";
import TaskLookup from "../appComponents/taskLookup";

import HelperPanel from "../appComponents/helperPanel";

let settings = require("../settings");
let authentication = require("../authentication");

let refreshListInterval;

class EmailFinder extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			company: "",
			tasks: [],
			loadingProcessActive: true,
			maxPage: undefined,
			initialPage: 1,
			filter: "",
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.fetchLookupTasks = this.fetchLookupTasks.bind(this);
		this.handleLookup = this.handleLookup.bind(this);
		this.setInitialPage = this.setInitialPage.bind(this);
		this.setFilter = this.setFilter.bind(this);
	}

	setFilter(value) {
		var self = this;
		this.setState({
			filter: value
		},() => {
			self.fetchLookupTasks();
		})
	}

	setInitialPage(page) {
		var self = this;
		if (page !== this.state.initialPage) {
			this.setState({
				initialPage: page
			}, () => {
				self.fetchLookupTasks();
			})
		}
	}

	componentDidMount() {
		this.fetchLookupTasks();
	}

	componentWillUnmount() {
		clearInterval(refreshListInterval);
	}

	fetchLookupTasks(shouldUpdateTeam) {
		var self = this;

		var update = shouldUpdateTeam

		if (typeof update === 'undefined') {
			update = false
		}

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		const requestData = {
			start: this.state.initialPage * this.props.resultsPerPage - this.props.resultsPerPage + 1,
			total: this.props.resultsPerPage,
		};

		if (this.state.filter.length > 2) {
			requestData.filter = this.state.filter
		}

		axios
			.post(settings.API_HOST + "lookups", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				self.setState({ loadingProcessActive: false });
				if (response.status === 200) {

					if (response.data.length > 0) {
						let maxPage = Math.ceil(response.data[0].totalTasks / self.props.resultsPerPage)
						self.setState({
							maxPage: maxPage
						})
					}

					if (self.state.tasks !== response.data) {
						self.props.refreshUserData();
						if (typeof shouldUpdateTeam !== 'undefined') {
							if (!update) {
								self.props.refreshActiveTeamData();
							}
						}
					}

					self.setState(
						{
							tasks: response.data,
						},
						() => {
							const hasProcessingValue = self.state.tasks.some(
								(el) =>
									el.status === "pending" ||
									el.status === "processing"
							);
							if (hasProcessingValue) {
								clearInterval(refreshListInterval);

								refreshListInterval = setInterval(function () {
									self.fetchLookupTasks();
								}, 3000);
							} else {
								clearInterval(refreshListInterval);

								refreshListInterval = setInterval(function () {
									self.fetchLookupTasks();
								}, 60000);
							}
						}
					);
				} else {
					clearInterval(refreshListInterval);

					refreshListInterval = setInterval(function () {
						self.fetchLookupTasks();
					}, 60000);

					self.props.createNotification(
						"error",
						"An error occurred fetching previous domain lookups.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				clearInterval(refreshListInterval);

				refreshListInterval = setInterval(function () {
					self.fetchLookupTasks();
				}, 60000);

				self.props.createNotification(
					"error",
					"An error occurred fetching previous domain lookups.",
					"Error",
					6000
				);
			});
	}

	handleLookup(event) {
		event.preventDefault();

		if (this.state.name === "" || this.state.company === "") {
			return;
		}

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		const requestData = {
			company: this.state.company,
			name: this.state.name,
		};

		axios
			.post(settings.API_HOST + "find", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({ name: "", company: "" });
					self.fetchLookupTasks();
					self.props.refreshUserData();
					self.props.refreshActiveTeamData();
				} else {
					self.fetchLookupTasks();
					self.props.createNotification(
						"error",
						"An error occurred performing email lookup.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				self.fetchLookupTasks();
				self.props.createNotification(
					"error",
					"An error occurred performing email lookup.",
					"Error",
					6000
				);
			});
	}

	handleInputChange(event) {
		const target = event.target;
		var value = target.value;
		const name = target.name;

		value = value.toLowerCase();

		this.setState({
			[name]: value,
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.activeTeamData !== this.props.activeTeamData) {
			this.fetchLookupTasks(true);
		}

		if (prevProps.resultsPerPage !== this.props.resultsPerPage) {
			var self = this;
			this.setState({
				initialPage: 1
			}, () => {
				self.fetchLookupTasks();
			})
		}
	}

	render() {

		let tasks = this.state.tasks.map((item, index) => {
			return (
				<TaskLookup
					key={item.id}
					index={index}
					item={item}
					activeTeamData={this.props.activeTeamData}
					userTags={this.props.userTags}
					displayTagSelector={this.props.displayTagSelector}
					displayTagRemovalSelector={
						this.props.displayTagRemovalSelector
					}
					handleTaskRemoval={this.props.handleTaskRemoval}
					refreshTaskData={this.fetchLookupTasks}
					setDetailsHelperPanelSource={() => this.props.setDetailsHelperPanelSource(item)}
				/>
			);
		});

		return (
			<div className="emailFinderWrapper">
				<div className="emailFinder">
					<div className="requestForm">
						<div>
							<h1>Enter prospect's full name.</h1>
							<h1>Enter company name or url address.</h1>
						</div>
						<form onSubmit={this.handleLookup}>
							<input
								autoFocus
								name="name"
								type="text"
								placeholder="First & last name"
								value={this.state.name}
								onChange={this.handleInputChange}
							/>
							<span>@</span>
							<input
								name="company"
								type="text"
								placeholder="Company name or domain name"
								value={this.state.company}
								onChange={this.handleInputChange}
							/>
							<div>
								<button
									className="defaultButtonGreen"
									type="submit"
								>
									Find email address
								</button>
							</div>
						</form>
					</div>

					{this.state.loadingProcessActive ? (
						<div className="singleComponent">
							<LoaderAnimation />
						</div>
					) : (
						<React.Fragment>
							{this.state.tasks.length > 0 || this.state.filter !== "" ? (
								<div className="appTasksWrapper">
									<TaskFilter resultsPerPage={this.props.resultsPerPage}
												setResultsPerPage={this.props.setResultsPerPage}
												maxPage={this.state.maxPage}
												initialPage={this.state.initialPage}
												setInitialPage={this.setInitialPage}
												setFilter={this.setFilter}/>
									<div className="tasksWrapper">
										<div>{tasks}</div>
									</div>
								</div>
							) : (
								<React.Fragment />
							)}
						</React.Fragment>
					)}
				</div>
				<HelperPanel
					activeTeamData={this.props.activeTeamData}
					refreshUserData={this.props.refreshUserData}
					refreshActiveTeamData={this.props.refreshActiveTeamData}
					createNotification={this.props.createNotification}
					helperPanelMode={this.props.helperPanelMode}
					switchHelperPanelModeTo={this.props.switchHelperPanelModeTo}
					userTags={this.props.userTags}
					refreshTaskData={this.fetchLookupTasks}
					source={this.props.source}
				/>
			</div>
		);
	}
}

export default EmailFinder;
