import React, { Component } from "react";
import axios from 'axios';
import download from 'downloadjs'

import BillingEvent from '../../appComponents/billingEvent'

import LoaderAnimation from '../../LoaderAnimation';


let settings = require("../../settings");
let authentication = require("../../authentication");

//
// Set time interval to constantly fetch billing history.
//
var refreshBillingHistoryInterval;

class BillingHistory extends Component {
	constructor(props) {
		super(props)

		this.state={
			processing: false,
			billingHistory: undefined
		}

		this.fetchBillingHistory = this.fetchBillingHistory.bind(this)
		this.fetchInvoicePdf = this.fetchInvoicePdf.bind(this)
	}

	componentDidMount() {
		this.fetchBillingHistory()

		var self = this;
		// Performs billing history refreshes on every 120 seconds
		refreshBillingHistoryInterval = setInterval(function () {
			self.fetchBillingHistory();
		}, 120000);
	}

	fetchInvoicePdf(number) {
		this.setState({processing: true})

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			self.setState({processing: false})
			return;
		}

		const requestCredentials = {
            "number": number,
        };

        let invoiceName = number + ".pdf"

		axios.post(settings.API_HOST+'user/invoice', requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
                responseType: 'blob',
            })
            .then(function (response) {
            	self.setState({processing: false})
                if (response.status === 200) {
                	const content = response.headers['content-type'];
					download(response.data, invoiceName, content)

                } else {
                    self.props.createNotification('error', 'Internal server error.', 'Error', 6000);
                }

        })
            .catch(function (error) {
                self.props.createNotification('error', 'API server error occurred. Please try again.', 'Error', 6000);
        });
	}

	fetchBillingHistory() {
		this.setState({processing: true})

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			self.setState({processing: false})
			return;
		}

		axios
			.get(settings.API_HOST + "user/billingevents", {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({
						billingHistory: response.data,
					});
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while trying to fetch billing history.",
						"Error - Billing History",
						7000
					);
				}
			})
			.catch(function (error) {
				self.props.createNotification(
					"error",
					"An error occurred while trying to fetch billing history.",
					"Error - Billing History",
					7000
				);
			});
		self.setState({processing: false})	
	}

	componentWillUnmount() {
		clearInterval(refreshBillingHistoryInterval);
	}

	render() {

		var billingEvents
		if (typeof this.state.billingHistory !== "undefined") {
			billingEvents = this.state.billingHistory.map((item, index) => {
				return <BillingEvent key={index} 
								     date={item.date}
								     description={item.description}
								     amount={item.amount}
								     currency={item.currency}
								     eventType={item.eventType}
								     number={item.number}
								     index={index}
								     fetchInvoicePdf={this.fetchInvoicePdf}
				/>;
			});
		}

		return (
			<React.Fragment>
				{this.state.processing ? <LoaderAnimation /> : <React.Fragment />}
				<div className="billingHistoryWrapper">
					<div className="billingHistory">
						<div className="billingHistoryInvoicesBlock">
							<div className="invoicesWrapper">
								<div>
									<h1>Billing events</h1>	
								</div>
								<div className="invoicesHeader">
									<div>
										Date
									</div>
									<div>
										Number
									</div>
									<div>
										Description
									</div>
									<div>
										Amount {(typeof this.state.billingHistory !== 'undefined') ? 
													(this.state.billingHistory.length > 0) ?
														<span>{this.state.billingHistory[0].currency}</span> 
														:
														<React.Fragment />
													: 
													<React.Fragment />
												}
									</div>
								</div>
								{billingEvents}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default BillingHistory;
