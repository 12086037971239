import React, { Component } from "react";

let icons = require("./icons");

class TaskFilter extends Component {
	constructor(props) {
		super(props)

		this.state=({
			search: '',
		})

		this.handleInputChange = this.handleInputChange.bind(this);
		this.selectPrevPage = this.selectPrevPage.bind(this);
		this.selectNextPage = this.selectNextPage.bind(this);
	}

	selectPrevPage() {
		if (this.props.initialPage === 1) {
			return
		} else {
			this.props.setInitialPage(this.props.initialPage - 1)
		}
	}

	selectNextPage() {
		if (this.props.maxPage === this.props.initialPage) {
			return
		} else {
			this.props.setInitialPage(this.props.initialPage + 1)
		}
	}

    handleInputChange(event) {

        const target = event.target;
        var value = target.value;
        const name = target.name;

        var self = this;

        this.setState({
            [name]: value
        }, () => {
        	self.props.setFilter(value)
        });

        
    }

	render() {
		return(
			<div className="taskFilterWrapper">
				<div className="taskFilter">
					<input type="search" name="search" value={this.state.search} placeholder="Search tasks..." onChange={this.handleInputChange}/>
				</div>
				<div className="pagination">
				{ typeof this.props.maxPage !== 'undefined' ?
					<div><span onClick={this.selectPrevPage}>{icons.prevPage()}</span> Page {this.props.initialPage} of {this.props.maxPage} <span onClick={this.selectNextPage}>{icons.nextPage()}</span></div>
					:
					<React.Fragment />
				}
				</div>
				<div className="numberOfResultsSelector">
					
						<div>Results per page:</div>
					<div>
						<ul>
							<li className={this.props.resultsPerPage === 10 ? "selectedNumber" : ""} onClick={() => this.props.setResultsPerPage(10)}>10</li>
							<li className={this.props.resultsPerPage === 20 ? "selectedNumber" : ""} onClick={() => this.props.setResultsPerPage(20)}>20</li>
							<li className={this.props.resultsPerPage === 50 ? "selectedNumber" : ""} onClick={() => this.props.setResultsPerPage(50)}>50</li>
							<li className={this.props.resultsPerPage === 100 ? "selectedNumber" : ""} onClick={() => this.props.setResultsPerPage(100)}>100</li>
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

export default TaskFilter;
