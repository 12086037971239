import React, { Component } from "react";

class PayoutAddressRemoval extends Component {
	render() {
		return (
			<div className="teamRemovalWrapper">
				<div className="teamRemoval">
					<h1>Remove PayPal payout email address</h1>
					<p>
						Are you sure you would like to remove:{" "}
						<div><br /></div>
						<span>
							{
								this.props.email
							}
						</span>
						<div><br /></div>
					</p>
					<p>
						If removed, this address won't be used for future referral payouts.
					</p>
					<h4>You can always assign another PayPal email address if required.</h4>
					<div className="teamRemovalButtonWrapper">
						<button className="cancelButton"
							onClick={this.props.toggleEmailAddressRemove}
						>
							Cancel
						</button>
						<button
							className="defaultButtonRed"
							onClick={this.props.handleEmailAddressDelete}
						>
							Remove
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default PayoutAddressRemoval;