import React, { Component } from 'react';
import axios from 'axios';

import LoaderAnimation from '../../LoaderAnimation';
import AddNewCreditCard from './newCreditCardAddition'
import PaymentMethodRemoval from './paymentMethodRemoval'

let settings = require("../../settings");
let authentication = require("../../authentication");

let icons = require("../../appComponents/icons");


class CreditCards extends Component {
    constructor(props) {
        super(props)

        this.state={
            creditCardInputIsVisible: false,
            visibleRemovalConfirmation: false,
            processing: false,
            methodId: '',
            last4: ''
        }

        this.toggleNewCreditCardInputVisibility = this.toggleNewCreditCardInputVisibility.bind(this)
        this.setAsDefault = this.setAsDefault.bind(this)
        this.removePaymentMethod = this.removePaymentMethod.bind(this)
        this.toggleRemovalConfirmationVisibility = this.toggleRemovalConfirmationVisibility.bind(this)
    }

    toggleNewCreditCardInputVisibility() {
        this.setState({
            creditCardInputIsVisible: !this.state.creditCardInputIsVisible
        })
    }

    toggleRemovalConfirmationVisibility(event, methodId, last4) {
        event.preventDefault();

        if (!this.state.visibleRemovalConfirmation) {
            this.setState({
                visibleRemovalConfirmation: true,
                methodId: methodId,
                last4: last4
              })
        } else {
            this.setState({
                            visibleRemovalConfirmation: false,
                            methodId: '',
                            last4: ''
                        })
        }
    }

    setAsDefault(event, methodId, expMonth, expYear) {
        event.preventDefault();

        var date = new Date();
        var month = date.getUTCMonth() + 1;
        var year = date.getUTCFullYear();

        //
        // Check if card has expired
        //
        if (expMonth < month && expYear <= year) {
            this.props.createNotification(
                "error",
                "Payment card has expired",
                "Error - Card Expired"
            );
            return
        }

        this.setState({processing: true})

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const requestCredentials = {
            id: methodId
        };

        var self = this;

        axios
            .patch(settings.API_HOST + "user/paymentmethod", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {       
                self.setState({processing: false});    
                if (response.status === 200) {
                    self.props.refreshUserData();
                    self.props.createNotification(
                        "success",
                        "Default payment method changed.",
                        "Success - Payment Method",
                        6000
                    );
                } else {
                    self.props.createNotification(
                        "error",
                        "Error occurred setting default payment method",
                        "Error - Payment Method",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.setState({processing: false});
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error - Payment Method",
                    6000
                );
            });
    }

    removePaymentMethod(event) {
        event.preventDefault()

        this.setState({processing: true})

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const headers = { Authorization: "Bearer " + tokenHolder.token };

        const data = {
            id: this.state.methodId,
        };

        axios
            .delete(settings.API_HOST + "user/paymentmethod", { headers, data })
            .then(function (response) {       
                self.setState({processing: false, methodId: '', last4: '', visibleRemovalConfirmation: false});    
                if (response.status === 200) {
                    self.props.refreshUserData();
                    self.props.createNotification(
                        "success",
                        "Payment method removed.",
                        "Success - Payment Method",
                        6000
                    );
                } else {
                    self.props.createNotification(
                        "error",
                        "Error occurred removing payment method",
                        "Error - Payment Method",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.setState({processing: false, methodId: '', last4: '', visibleRemovalConfirmation: false});
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error - Payment Method",
                    6000
                );
            });
    }


    render() {
        var date = new Date();
        var month = date.getUTCMonth() + 1;
        var year = date.getUTCFullYear();
        
        let visa = settings.CDN_HOST + 'images/cards/visa.svg'
        let mastercard = settings.CDN_HOST + 'images/cards/mastercard.svg'
        let amex = settings.CDN_HOST + 'images/cards/amex.svg'
        let discover = settings.CDN_HOST + 'images/cards/discover.svg'
        let diners = settings.CDN_HOST + 'images/cards/diners.svg'
        let jcb = settings.CDN_HOST + 'images/cards/jcb.svg'
        let unionpay = settings.CDN_HOST + 'images/cards/unionpay.svg'
        let maestro = settings.CDN_HOST + 'images/cards/maestro.svg'
        let elo = settings.CDN_HOST + 'images/cards/elo.svg'
        let hipercard = settings.CDN_HOST + 'images/cards/hipercard.svg'
        let laser = settings.CDN_HOST + 'images/cards/laser.svg'

        let months = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December"]

        var cards = this.props.userData.paymentMethods.map((item, index) => {
            return (
                <div className="creditCardWrapper" key={index}>
                    <div>
                        {item.brand === 'visa' ? <img src={visa} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'mastercard' ? <img src={mastercard} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'amex' ? <img src={amex} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'discover' ? <img src={discover} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'diners' ? <img src={diners} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'jcb' ? <img src={jcb} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'unionpay' ? <img src={unionpay} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'maestro' ? <img src={maestro} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'elo' ? <img src={elo} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'hipercard' ? <img src={hipercard} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'laser' ? <img src={laser} width="64" height="40" alt="Poirot Card"/> : <React.Fragment />}
                        {item.brand === 'visa' || 
                        item.brand === 'mastercard' ||
                        item.brand === 'amex' ||
                        item.brand === 'discover' ||
                        item.brand === 'diners' ||
                        item.brand === 'jcb' ||
                        item.brand === 'unionpay' ||
                        item.brand === 'maestro' ||
                        item.brand === 'elo' ||
                        item.brand === 'hipercard' ||
                        item.brand === 'laser' ? <React.Fragment/> : icons.creditCard()}
                    </div>
                    <div>
                        **** **** **** {item.last4}
                    </div>
                    <div>
                        {
                        (item.expMonth < month && item.expYear <= year) ? 
                            <div className="expiredCreditCard"><span>Expired&nbsp;</span> {months[item.expMonth - 1]} {item.expYear}</div>
                            :
                            <div><span>Valid through&nbsp;</span> {months[item.expMonth - 1]} {item.expYear}</div>
                        }
                    </div>
                    <div>
                        {item.default ? <div className="defaultPaymentMethodMarker">{icons.checkCircle()} Default</div> : (item.expMonth < month && item.expYear <= year) ? <React.Fragment /> : <li onClick={(event) => this.setAsDefault(event, item.id, item.expMonth, item.expYear)}>Set as default</li>}
                    </div>
                    <div>
                        {item.default ? <React.Fragment /> : <div className="removePaymentMethodWrapper" onClick={(event) => this.toggleRemovalConfirmationVisibility(event, item.id, item.last4)}>{icons.trashCan()}</div>}
                    </div>
                </div>        
            )
        })

        return(
            <React.Fragment>
            {this.state.processing ? <LoaderAnimation /> : <React.Fragment/>}
            {this.state.visibleRemovalConfirmation ? <PaymentMethodRemoval methodId={this.state.methodId}
                                                                           last4={this.state.last4}
                                                                           removePaymentMethod={this.removePaymentMethod}
                                                                           toggleRemovalConfirmationVisibility={this.toggleRemovalConfirmationVisibility}/> : <React.Fragment/>}
                <div className="creditCards">
                    <div><h1>Credit / Debit cards</h1></div>
                    {this.props.userData.paymentMethods.length > 0 ? cards
                        : 
                        <div className="creditCardsNotificationWrapper">
                            <h3>
                                You haven't associated any payment card with your account yet.
                            </h3>
                        </div>
                    }
                    <AddNewCreditCard createNotification={this.props.createNotification}
                                      creditCardInputIsVisible={this.state.creditCardInputIsVisible}
                                      refreshUserData={this.props.refreshUserData}
                                      associatedCards={this.props.userData.paymentMethods.length > 0}
                                      toggleNewCreditCardInputVisibility={this.toggleNewCreditCardInputVisibility}/>
                </div>       
            </React.Fragment>
        )
    }
};

export default CreditCards;


