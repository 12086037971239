import React, { Component } from "react";
import axios from 'axios';

import Switch from "../switch";

let settings = require("../../settings");
let authentication = require("../../authentication");

class TeamStatusSet extends Component {
	constructor(props) {
		super(props)

		this.handleToggle = this.handleToggle.bind(this);
		this.handleLabelClick = this.handleLabelClick.bind(this);
	}

	handleToggle() {
        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const requestCredentials = {
            id: this.props.listOfTeams[this.props.selectedTeamIndex].id,
            active: !this.props.listOfTeams[this.props.selectedTeamIndex]
                .active,
        };

        var self = this;

        axios
            .patch(settings.API_HOST + "team", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.props.updateListOfTeams();
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
    }

    handleLabelClick(status) {
        if (
            this.props.listOfTeams[this.props.selectedTeamIndex].active !==
            status
        ) {
            this.handleToggle();
        }
    }

	render() {
		return (
			<div className="cycleSwitch">
				<div
					className="toggleLabel"
					onClick={() => this.handleLabelClick(false)}
				>
					<h5>Disabled</h5>
				</div>
				<div>
					<Switch
						isOn={
							this.props.listOfTeams[this.props.selectedTeamIndex]
								.active
						}
						handleToggle={() => this.handleToggle()}
					/>
				</div>
				<div
					className="toggleLabel"
					onClick={() => this.handleLabelClick(true)}
				>
					<h5>Enabled</h5>
				</div>
			</div>
		);
	}
}

export default TeamStatusSet;
