import React, { Component } from "react";

let icons = require('../icons')

class UpgradePlanTable extends Component {
	render() {

		return (
			<div className="upgradePlanFeatures">
				<div><h1>{this.props.plan.name}</h1></div>
				<div className="planPrice">{(this.props.plan.selected.currency === 'usd') ? '$' : this.props.plan.selected.currency}{(this.props.plan.selected.unitAmount/100).toLocaleString()}<span>/{this.props.annualInterval ? 'year' : 'month'}</span></div>

				{this.props.currentProduct.metadata.lookupCredits < this.props.plan.metadata.lookupCredits ? 
					<div><button className="defaultButtonGreen" onClick={() => this.props.togglePaymentProcessFormVisibility(this.props.plan)}>Choose this plan</button></div>
					:
					<div><button className="defaultButtonGreen" onClick={() => this.props.togglePaymentProcessFormVisibility(this.props.plan)}>Downgrade</button></div>
				}

				<div className="creditsFeature"><div className="boldFeature">{(this.props.plan.metadata.lookupCredits).toLocaleString()}</div><div>Lookup credits</div></div>
				<div className="creditsFeature"><div className="boldFeature">{(this.props.plan.metadata.verificationCredits).toLocaleString()}</div><div>Verification credits</div></div>
				<div><hr /></div>
				<div className="imageFeature"><div>Teams</div><div>{this.props.plan.metadata.teams ? icons.checkCircle() : icons.timesCircle()}</div></div>
				<div className="imageFeature"><div>Bulk usage</div><div>{this.props.plan.metadata.bulk ? icons.checkCircle() : icons.timesCircle()}</div></div>
				<div className="imageFeature"><div>API usage</div><div>{this.props.plan.metadata.api ? icons.checkCircle() : icons.timesCircle()}</div></div>
				<div className="imageFeature"><div>CSV export</div><div>{this.props.plan.metadata.csv ? icons.checkCircle() : icons.timesCircle()}</div></div>
			</div>
		)
	}
}

export default UpgradePlanTable;