import React, { Component } from "react";

class ModeSelector extends Component {
	render() {
		var bulkMenuItem = () => {
			if (this.props.currentProduct.metadata.bulk || this.props.activeTeamData !== "unavailable") {
				return (
					<li className={this.props.mode === 'bulkActions' ? "activeMode" : ""} onClick={() => this.props.switchModeTo("bulkActions")}>Bulk Actions</li>
				) 
			} else {
				return (
					<li className="disabled">Bulk Actions</li>
				)
			}
		}

		var teamworkMenuItem = () => {
			if ((this.props.currentProduct.metadata.teams && this.props.isActiveTeamTeamMaster) || (this.props.currentProduct.metadata.teams && this.props.activeTeamData === "unavailable")) {
				return (
					<li className={this.props.mode === 'teamwork' ? "activeMode" : ""} onClick={() => this.props.switchModeTo("teamwork")}>Teamwork</li>
				) 
			} else {
				return (
					<li className="disabled">Teamwork</li>
				)
			}
		}

		var accountMenuItem = () => {
			if (this.props.activeTeamData !== "unavailable" && !this.props.isActiveTeamTeamMaster) {
				return (
					<li className="disabled">Account</li>
				)
			} else {
				return (
					<li className={this.props.mode === 'accountPlans' ||
									this.props.mode === 'profile' ||
									this.props.mode === 'referrals' ||
									this.props.mode === 'paymentMethods' ||
									this.props.mode === 'billingSettings' ||
									this.props.mode === 'billingHistory' ? "activeMode" : ""} onClick={() => this.props.switchModeTo("accountPlans")}>Account</li>
				)
			}
		}

		return (
			<div className="modeSelector">
				<ul>
					<li className={this.props.mode === 'emailFinder' ? "activeMode" : ""} onClick={() => this.props.switchModeTo("emailFinder")}>Email Finder</li>
					<li className={this.props.mode === 'domainSearch' ? "activeMode" : ""} onClick={() => this.props.switchModeTo("domainSearch")}>Domain Search</li>
					<li className={this.props.mode === 'emailVerification' ? "activeMode" : ""} onClick={() => this.props.switchModeTo("emailVerification")}>Email Verification</li>
					<li className={this.props.mode === 'contactLists' ? "activeMode" : ""} onClick={() => this.props.switchModeTo("contactLists")}>Contact Lists</li>
					{bulkMenuItem()}
					{teamworkMenuItem()}
					{accountMenuItem()}

				</ul>
			</div>
		)
	}
}

export default ModeSelector;
