import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

class DomainSearchChart extends Component {
	render() {

		return (
			<React.Fragment>
				<ReactTooltip
					className={this.props.totalCount !== 0 ? "reactTooltip" : "reactTooltipHidden"}
					id={"domainSearchChart" + this.props.index}
					delayShow={100}
				>
					<div className="toolTipDomainSearchChartContent">

						{typeof this.props.prospectPart !== "undefined" &&
						this.props.prospectPart !== 0 ? (
							<React.Fragment>
								<div>
									<div>
										<svg height="14" width="14">
											<circle
												cx="7"
												cy="7"
												r="6"
												stroke="#a4d037"
												strokeWidth="1"
												fill="#a4d037"
											/>
										</svg>
									</div>
									<div>Prospect emails</div>
									<div>
										<span>
											{Math.round(
												this.props.prospectPart
											)}
											%
										</span>
									</div>
								</div>
							</React.Fragment>
						) : (
							<React.Fragment />
						)}

						{typeof this.props.genericPart !== "undefined" &&
						this.props.genericPart !== 0 ? (
							<React.Fragment>
								<div>
									<div>
										<svg height="14" width="14">
											<circle
												cx="7"
												cy="7"
												r="6"
												stroke="#3eb0ef"
												strokeWidth="1"
												fill="#3eb0ef"
											/>
										</svg>
									</div>
									<div>Generic emails</div>
									<div>
										<span>
											{Math.round(this.props.genericPart)}
											%
										</span>
									</div>
								</div>
							</React.Fragment>
						) : (
							<React.Fragment />
						)}

						{typeof this.props.unknownPart !== "undefined" &&
						this.props.unknownPart !== 0 ? (
							<React.Fragment>
								<div>
									<div>
										<svg height="14" width="14">
											<circle
												cx="7"
												cy="7"
												r="6"
												stroke="#fecd35"
												strokeWidth="1"
												fill="#fecd35"
											/>
										</svg>
									</div>
									<div>Unknown emails</div>
									<div>
										<span>
											{Math.round(this.props.unknownPart)}
											%
										</span>
									</div>
								</div>
							</React.Fragment>
						) : (
							<React.Fragment />
						)}

						{typeof this.props.relevantPart !== "undefined" &&
						this.props.relevantPart !== 0 ? (
							<React.Fragment>
								<div>
									<div>
										<svg height="14" width="14">
											<circle
												cx="7"
												cy="7"
												r="6"
												stroke="#f05230"
												strokeWidth="1"
												fill="#f05230"
											/>
										</svg>
									</div>
									<div>Relevant emails</div>
									<div>
										<span>
											{Math.round(
												this.props.relevantPart
											)}
											%
										</span>
									</div>
								</div>
							</React.Fragment>
						) : (
							<React.Fragment />
						)}

						{typeof this.props.fraudulentPart !== "undefined" &&
						this.props.fraudulentPart !== 0 ? (
							<React.Fragment>
								<div>
									<div>
										<svg height="14" width="14">
											<circle
												cx="7"
												cy="7"
												r="6"
												stroke="#ad26b4"
												strokeWidth="1"
												fill="#ad26b4"
											/>
										</svg>
									</div>
									<div>Fraudulent emails</div>
									<div>
										<span>
											{Math.round(
												this.props.fraudulentPart
											)}
											%
										</span>
									</div>
								</div>
							</React.Fragment>
						) : (
							<React.Fragment />
						)}
					</div>
				</ReactTooltip>
				<div>

				<svg
					data-tip
					data-for={"domainSearchChart" + this.props.index}
					height="8"
					viewBox="0 0 123 8"
					width="123"
					xmlns="http://www.w3.org/2000/svg"
				>
					<linearGradient
						id={this.props.index}
						gradientUnits="userSpaceOnUse"
						x1="0"
						x2="100%"
						y1="0"
						y2="0%"
					>
					{this.props.totalCount !== 0 ? (
							<React.Fragment>
								{this.props.prospectPart !== 0 ? (
									<React.Fragment>
										<stop offset="0" stopColor="#a4d037" />
										<stop
											offset={
												(this.props.prospectPart).toString() + "%"
											}
											stopColor="#a4d037"
										/>
									</React.Fragment>
								) : (
									<React.Fragment />
								)}

								{this.props.genericPart !== 0 ? (
									<React.Fragment>
										<stop
											offset={
												(this.props.prospectPart).toString() + "%"
											}
											stopColor="#3eb0ef"
										/>
										<stop
											offset={
												(Math.round(
													parseInt(
														this.props.prospectPart
													) +
													parseInt(
														this.props.genericPart
													)
												)).toString() + "%"
											}
											stopColor="#3eb0ef"
										/>
									</React.Fragment>
								) : (
									<React.Fragment />
								)}

								{this.props.unknownPart !== 0 ? (
									<React.Fragment>
										<stop
											offset={
												(Math.round(
													parseInt(
														this.props.prospectPart
													) +
													parseInt(
														this.props.genericPart
													)
												)).toString() + "%"
											}
											stopColor="#fecd35"
										/>
										<stop
											offset={
												(Math.round(
													parseInt(
														this.props.prospectPart
													) +
													parseInt(
														this.props.genericPart
													) +
													parseInt(
														this.props.unknownPart
													)
												)).toString() + "%"
											}
											stopColor="#fecd35"
										/>
									</React.Fragment>
								) : (
									<React.Fragment />
								)}

								{this.props.relevantPart !== 0 ? (
									<React.Fragment>
										<stop
											offset={
												(Math.round(
													parseInt(
														this.props.prospectPart
													) +
													parseInt(
														this.props.genericPart
													) +
													parseInt(
														this.props.unknownPart
													)
												)).toString() + "%"
											}
											stopColor="#f05230"
										/>
										<stop
											offset={
												(Math.round(
													parseInt(
														this.props.prospectPart
													) +
													parseInt(
														this.props.genericPart
													) +
													parseInt(
														this.props.unknownPart
													) +
													parseInt(
														this.props.relevantPart
													)
												)).toString() + "%"
											}
											stopColor="#f05230"
										/>
									</React.Fragment>
								) : (
									<React.Fragment />
								)}

								{this.props.fraudulentPart !== 0 ? (
									<React.Fragment>
										<stop
											offset={
												(Math.round(
													parseInt(
														this.props.prospectPart
													) +
													parseInt(
														this.props.genericPart
													) +
													parseInt(
														this.props.unknownPart
													) +
													parseInt(
														this.props.relevantPart
													)
												)).toString() + "%"
											}
											stopColor="#ad26b4"
										/>
										<stop offset="1" stopColor="#ad26b4" />
									</React.Fragment>
								) : (
									<React.Fragment />
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								<stop offset="0" stopColor="#f2f2f2" />
								<stop offset="1" stopColor="#f2f2f2" />
							</React.Fragment>
						)}
					</linearGradient>
					<path d="m0 0h123v8h-123z" fill={"url(#" + this.props.index + ")"} />
				</svg>
				</div>
			</React.Fragment>
		);
	}
}

export default DomainSearchChart;
