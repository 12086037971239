import React, { Component } from 'react';
import axios from "axios";

import ColorPicker from './blocks/colorPicker';

import TagLabel from './blocks/tagLabel';
import TagLabelRemoval from './blocks/tagLabelRemoval';

let settings = require("../settings");
let authentication = require("../authentication");

let icons = require("./icons");

class TagsHelperPanel extends Component {
    constructor(props) {
        super(props)

        this.state=({
            selectedTagIndex: undefined,
            focusedTagIndex: undefined,
            inputNewIsVisible: false,
            newLabel: '',
            newColor: 'dedede',
            labelRemovalVisible: false,
            labelRemovalColor: undefined,
            labelRemovalLabel: undefined,
            selectedTagId: undefined,
        })

        this.colorPicker = React.createRef();
        this.newInput = React.createRef();

        this.selectTag=this.selectTag.bind(this);
        this.focusTag=this.focusTag.bind(this);
        this.cancelSelection=this.cancelSelection.bind(this);
        this.selectColor = this.selectColor.bind(this);
        this.closeColorPicker = this.closeColorPicker.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleNewLabelInput = this.handleNewLabelInput.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleLabelRemoval = this.handleLabelRemoval.bind(this);
        this.showLabelRemoval=this.showLabelRemoval.bind(this);
        this.closeLabelRemoval=this.closeLabelRemoval.bind(this);
    }

    showLabelRemoval(event) {
        event.preventDefault();

        if (typeof this.state.selectedTagIndex !== 'undefined') {

            let tags = this.props.activeTeamData === "unavailable" ? this.props.userTags : this.props.activeTeamData.tags

            this.setState({
                labelRemovalVisible: true,
                labelRemovalColor: tags[this.state.selectedTagIndex].color,
                labelRemovalLabel: tags[this.state.selectedTagIndex].label
            })
        }

        event.stopPropagation();
    }

    closeLabelRemoval() {
        this.setState({
            labelRemovalVisible: false,
            labelRemovalColor: undefined,
            labelRemovalLabel: undefined,
        })
    }

    handleLabelRemoval(event) {
        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

        const headers = { Authorization: "Bearer " + tokenHolder.token };

        const data = {
            id: this.state.selectedTagId,
        };

        event.persist();

        axios
            .delete(settings.API_HOST + "tag", { headers, data })
            .then(function (response) {
                
                if (response.status === 200) {
                    self.props.refreshTaskData();
                    self.props.refreshUserData();
                    self.props.refreshActiveTeamData();
                    self.closeLabelRemoval(); 
                    self.cancelSelection(event);
                } else {
                    self.props.createNotification(
                        "error",
                        "An error occurred while removing selected label. Please try again later.",
                        "Error - Label removal",
                        7000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "An error occurred while removing selected label. Please try again later.",
                    "Error - Label removal",
                    6000
                );
            });
    }

    handleInputBlur(event) {
        event.preventDefault();
        
        if (this.state.newLabel === '') {
            this.setState({
                inputNewIsVisible: false,
            });
        } else {

            if (this.state.newLabel.length < 2 || this.state.newLabel.length > 32) {
            this.props.createNotification(
                "error",
                "Please enter a valid tag label. Label should be between 2 and 32 characters long.",
                "Error - Tag label",
                7000
            );

            this.newInput.current.focus();
            return;
        }

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            self.setState({
                inputNewIsVisible: false,
            })
            return;
        }

        const requestData = {
            label: this.state.newLabel,
            color: this.state.newColor,
        };

        axios
            .post(settings.API_HOST + "tag/create", requestData, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.setState({
                        inputNewIsVisible: false,
                        newColor: "dedede",
                        newLabel: '',
                    });
                    self.props.refreshUserData();
                    self.props.refreshActiveTeamData();
                } else {
                    self.props.createNotification(
                        "error",
                        "An error occurred while trying to create a new tag. Please try again later.",
                        "Error - Tag Creation",
                        7000
                    );
                }
            })
            .catch(function (error) {
                if (error.response.status === 409) {
                    self.props.createNotification(
                        "error",
                        "Tag label already exists.",
                        "Error - Tag Creation",
                        6000
                    );
                } else {
                    self.props.createNotification(
                        "error",
                        "An error occurred while trying to create a new tag. Please try again later.",
                        "Error - Tag Creation",
                        7000
                    );
                }
            });
        }
    }

    handleNewLabelInput() {
        var self = this;
        this.setState({inputNewIsVisible: true}, () => {
            self.newInput.current.focus();
        })
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    cancelSelection(event) {
        if (event.target.name !== 'cancelButton') {
            this.setState({
                selectedTagIndex: undefined,
                focusedTagIndex: undefined,
                selectedTagId: undefined,
            })
        }
    }

    selectTag(index, id) {
        this.setState({
            selectedTagIndex: index,
            focusedTagIndex: undefined,
            selectedTagId: id
        })
    }

    focusTag(index) {
        this.setState({
            focusedTagIndex: index,
            selectedTagIndex: undefined,
            selectedTagId: undefined
        })
    }

    selectColor(event, index, id) {
        event.persist();

        this.setState({
            selectedTagId: id,
        }, () => {

            this.colorPicker.current.style.top = (event.clientY + 10).toString() + "px";
            this.colorPicker.current.style.left = (event.clientX - 139).toString() + "px";
            this.colorPicker.current.style.display = "block";
        })
    }

    closeColorPicker() {
        this.setState({
            selectedTagId: undefined,
        }, () => {
            this.colorPicker.current.style.display = "none";
        })
    }

    render() {

        let tags = this.props.activeTeamData === "unavailable" ? this.props.userTags : this.props.activeTeamData.tags
        let tag = tags.map((item, index) => {
            return (
                <TagLabel item={item} 
                          index={index} 
                          key={item.id} 
                          selectedTagIndex={this.state.selectedTagIndex} 
                          selectTag={this.selectTag} 
                          focusedTagIndex={this.state.focusedTagIndex}
                          focusTag={this.focusTag}
                          selectColor={this.selectColor}
                          refreshUserData={this.props.refreshUserData}
                          refreshActiveTeamData={this.props.refreshActiveTeamData}
                          createNotification={this.props.createNotification}
                          />
            )
        })

        return(
            <div className="tagsHelperPanelWrapper" onClick={(event) => this.cancelSelection(event)}>
                <div className="tagsTitle"><h5>Available tags - labels</h5></div>
                <div>
                    <div className="tagsList">
                        <div>
                        <div className="colorPickerWrapper" ref={this.colorPicker} onMouseLeave={this.closeColorPicker}>
                            <ColorPicker selectedTagId={this.state.selectedTagId}
                                         refreshUserData={this.props.refreshUserData}
                                         refreshActiveTeamData={this.props.refreshActiveTeamData}
                                         createNotification={this.props.createNotification}
                                         closeColorPicker={this.closeColorPicker}/>
                        </div>

                        {tag}

                        {this.state.inputNewIsVisible ? 
                            <div className="tagLabelWrapper">
                                <div className="tagLabelColor">
                                    <svg height="14" width="14">
                                        <circle cx="7" cy="7" r="7" fill={'#dedede'} />
                                    </svg>
                                </div>
                                <div className="tagLabel">
                                    <form onSubmit={(event) => this.handleInputBlur(event)}>
                                    <input ref={this.newInput} type="text" name={'newLabel'} value={this.state.newLabel} onChange={this.handleInputChange} onBlur={(event) => this.handleInputBlur(event)}/>
                                    </form>
                                </div>
                            </div>
                            :
                            <React.Fragment />
                        }

                        </div>

                    </div>
                </div>
                <div className="tagsAction">
                    <button onClick={this.handleNewLabelInput}>{icons.plus()}</button>
                    <button onClick={(event) => this.showLabelRemoval(event)}>{icons.minus()}</button>
                </div>

                {this.state.labelRemovalVisible ?
                    <TagLabelRemoval color={'#' + this.state.labelRemovalColor} label={this.state.labelRemovalLabel} closeLabelRemoval={this.closeLabelRemoval} selectedTagId={this.state.selectedTagId} handleLabelRemoval={this.handleLabelRemoval}/>
                    :
                    <React.Fragment />
                }
            </div>       
        )
    }
};

export default TagsHelperPanel;