import React, { Component } from "react";
import axios from 'axios';

import LoaderAnimation from "../../LoaderAnimation";

let settings = require("../../settings");
let authentication = require("../../authentication");

class EmailPreferences extends Component {
	constructor(props) {
		super(props)

		this.state={
			remotePreferences: null,
			preferences: null,
			unsubscribeAllSelected: false
		}

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleUnsubscribeAllToggle = this.handleUnsubscribeAllToggle.bind(this)
		this.refreshMailingPreferences = this.refreshMailingPreferences.bind(this)
		this.handleUpdate = this.handleUpdate.bind(this);
	}

	componentDidMount() {
		this.refreshMailingPreferences()
	}

	handleUpdate(event) {
		event.preventDefault()

		var self = this;

		if (JSON.stringify(this.state.remotePreferences) === JSON.stringify(this.state.preferences)) {
			self.props.createNotification(
						"info",
						"Mailing preferences haven't changed.",
						"Info - Mailing Preferences",
						7000
					);
			return
		}

		let diffObjects = []
		this.state.preferences.forEach(function(item, index) {
			if (item.subscribed !== self.state.remotePreferences[index].subscribed) {
				diffObjects.push(item)
			}
		});

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {			
			return;
		}

		axios
			.patch(settings.API_HOST + "user/mailingpreferences", diffObjects, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.props.createNotification(
                        "success",
                        "Mailing prefereneces updated successfully.",
                        "Success - Mailing Preferences Updated",
                        7000
                    );
                    self.setState({
                    	unsubscribeAllSelected: false,
                    })
					self.refreshMailingPreferences()
					
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while updating your mailing preferences. Please try again later.",
						"Error - Mailing Preferences",
						7000
					);
					
				}
			})
			.catch(function (error) {
					self.props.createNotification(
						"error",
						"An error occurred while updating your mailing preferences. Please try again later.",
						"Error - Mailing Preferences",
						7000
					);
					
			});
	}

	handleInputChange(key) {

		this.setState({unsubscribeAllSelected: false})

		this.setState(prevState => ({
			preferences: prevState.preferences.map((el, index) => index === key? 
				{ ...el, subscribed: !prevState.preferences[index].subscribed }
				: 
				el
				)
		}))
	}
	
	handleUnsubscribeAllToggle() {

		if (this.state.unsubscribeAllSelected) {

			this.setState(prevState => ({
				preferences: prevState.remotePreferences,
				unsubscribeAllSelected: false
			}))
		} else {

			this.setState(prevState => ({
				preferences: prevState.preferences.map((el, index) => ({ ...el, subscribed: false }) )
			}))

			this.setState({
				unsubscribeAllSelected: true
			})
		}
	}

	refreshMailingPreferences() {
		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {			
			return;
		}

		axios
			.get(settings.API_HOST + "user/mailingpreferences", {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({
						preferences: response.data,
						remotePreferences: response.data
					})					

				} else {
					self.props.createNotification(
						"error",
						"An error occurred while fetching mailing preferences. Please try again later.",
						"Error - User Profile Update",
						7000
					);
					
				}
			})
			.catch(function (error) {
					self.props.createNotification(
						"error",
						"An error occurred while fetching mailing preferences. Please try again later.",
						"Error - User Profile Update",
						7000
					);
					
			});
	}

	render() {

		return (
			<div className="emailPreferences">
				<div><h1>Email preferences</h1></div>
				<div><h3>Manage what emails you get</h3></div>
				<div className="emailPreferencesWrapper">
					{this.state.preferences !== null && this.state.remotePreferences !== null ?
						<React.Fragment>
							<div>
								<input id="productAnnouncements" type="checkbox" checked={this.state.preferences[0].subscribed} onChange={() => this.handleInputChange(0)}/>
								<label htmlFor="productAnnouncements">{this.state.preferences[0].name}</label><br/>
								<input id="userResearchSurveys" type="checkbox" checked={this.state.preferences[1].subscribed} onChange={() => this.handleInputChange(1)}/>
								<label htmlFor="userResearchSurveys">{this.state.preferences[1].name}</label><br/>
							</div>
							<div>
								<input id="poirotNewsletter" type="checkbox" checked={this.state.preferences[2].subscribed} onChange={() => this.handleInputChange(2)}/>
								<label htmlFor="poirotNewsletter">{this.state.preferences[2].name}</label><br/>
								<input id="monthlySearchReports" type="checkbox" checked={this.state.preferences[3].subscribed}  onChange={() => this.handleInputChange(3)}/>
								<label htmlFor="monthlySearchReports">{this.state.preferences[3].name}</label><br/>
							</div>
							<div>
								<h5>Opt me out instead</h5>
								<input id="unsubscribeAll" type="checkbox" onChange={() => this.handleUnsubscribeAllToggle()} checked={this.state.unsubscribeAllSelected}/>
								<label htmlFor="unsubscribeAll">Unsubscribe me from all emails</label><br/>
							</div>
							<div className="emailPreferencesbuttonWrapper">
								<button className="defaultButtonGreen" onClick={this.handleUpdate}>Update preferences</button>
							</div>
						</React.Fragment>
						:
						<div className="singleComponent"><LoaderAnimation /></div>
					}
				</div>
			</div>
		)
	}
}

export default EmailPreferences;