import React, { Component } from "react";

import TeamMember from "./teamMember";

class TeamMembers extends Component {

	render() {
		var teamMember = this.props.listOfTeams[
			this.props.selectedTeamIndex
		].teamMembers.map((member, index) => {
			return <TeamMember key={index} 
							   member={member}
							   teamName={this.props.listOfTeams[this.props.selectedTeamIndex].name}
							   teamId={this.props.listOfTeams[this.props.selectedTeamIndex].id}
							   createNotification={this.props.createNotification}
							   userEmailAddress={this.props.userEmailAddress}
							   updateListOfTeams={this.props.updateListOfTeams}
								/>;
		});

		return (
			<React.Fragment>
				<h2>Team members & invites</h2>
				{teamMember}
			</React.Fragment>
		);
	}
}

export default TeamMembers;
