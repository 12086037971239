import React, { Component } from "react";

import Gravatar from './gravatar.js';
import Tags from './blocks/tags';

let icons = require("./icons");
let utilities = require("../utilities");

class TaskLookup extends Component {
	constructor(props) {
		super(props)

		this.state = ({
			isSelected: false
		})

		this.handleTaskLookupClick = this.handleTaskLookupClick.bind(this);
		this.handleRightClick = this.handleRightClick.bind(this);
	}

	handleTaskLookupClick() {
		this.props.setDetailsHelperPanelSource()
	}

	handleRightClick(event, taskID) {
		event.preventDefault()

		this.props.handleTaskRemoval(event, taskID, this.props.refreshTaskData)
	}
	
	render() {

		const processingBarRandomClass = Math.floor(
			Math.random() * Math.floor(5)
		);
		
		let email = typeof this.props.item.result === 'undefined' ? 
		"" 
		:
		this.props.item.result.payload.length === 0 ?
		""
		: 
		this.props.item.result.payload[0].email
		let name = typeof this.props.item.name === 'undefined' ? "" : utilities.capitalizeTheFirstLetterOfEachWord(this.props.item.name)
		let domain = typeof this.props.item.domain !== 'undefined' ? this.props.item.domain.payload[0] : 'identifying...';

		let date = new Date(
			this.props.item.createdAt * 1000
		).toLocaleDateString();

		let status = () => {
			switch (this.props.item.status) {
				case "pending":
					return (
						<div>
							<div className="processingBarWrapper">
								<div
									className={
										"processingBar processingBar" +
										processingBarRandomClass
									}
								></div>
							</div>
						</div>
					);
				case "processing":
					return (
						<div>
							<div className="processingBarWrapper">
								<div
									className={
										"processingBar processingBar" +
										processingBarRandomClass
									}
								></div>
							</div>
						</div>
					);
				case "succeeded":
					return (
						
							typeof this.props.item.result === 'undefined' ? 
							<div className="verificationStatusFail"><div>{icons.timesCircle()}</div></div> 
							: 
							<div className="verificationStatusPass"><div>{icons.checkCircle()}</div></div>
						
					)
				case "failed":
					return <div className="verificationStatusFail"><div>{icons.timesCircle()}</div></div> 
				default:
					return <React.Fragment />;
			}
		};

		return (
			<div
				className={
					this.state.isSelected
						? "taskWrapper inFocus"
						: "taskWrapper"
				}
			>
				<div className="taskLookup" onClick={() => this.handleTaskLookupClick()} onContextMenu={(event) => this.handleRightClick(event, this.props.item.id)}>
					<div className="contactAvatar">
						<Gravatar userEmailAddress={email} defaultImage="https%3A%2F%2Fcdn.poirot.app%2Fimages%2Fperson-circle.png" imageSize="24"/>
					</div>
					<div className="taskDomainName">{name}</div>
					<div className="taskDomainName">{domain}</div>
					<div className="taskEventTime">{date}</div>
					<div className="taskStatus">{status()}</div>
					<div className="foundEmailAddress">
						{
							typeof this.props.item.result !== "undefined" && this.props.item.result.payload.length > 0 ?
							<a href={"mailto:" + email + "?&Content-Type=text/html&body=%0D%0A%0D%0ASent with help from www.poirot.app"}>{email}</a>
							:
							<React.Fragment />	
						}
					</div>

					<div className="contactTags">
					{
						typeof this.props.item.result !== "undefined" && this.props.item.result.payload.length > 0
						?
									<Tags activeTeamData={this.props.activeTeamData}
										  displayTagSelector={this.props.displayTagSelector}
										  displayTagRemovalSelector={this.props.displayTagRemovalSelector}
										  userTags={this.props.userTags}
										  taskId={this.props.item.id}
										  email={email}
										  label={typeof this.props.item.result.payload[0].label === 'undefined' ? undefined : this.props.item.result.payload[0].label}
										  refreshTaskData={this.props.refreshTaskData} />
										  :
										  <React.Fragment />
					}
					</div>
				
					
				</div>

				
			</div>
		);
	}
}

export default TaskLookup;