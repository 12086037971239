import React, { Component } from 'react'
import qs from 'qs'

import axios from 'axios'

import LoaderAnimation from './LoaderAnimation'

let settings = require('./settings')
let authentication = require('./authentication')

let utilities = require('./utilities')

class Register extends Component {
	constructor(props) {
		super(props)

		this.state=({
			name: "",
			email: "",
			password: "",
			newsletterConsent: true,
			coupon: null,
			honeyPot: "",
			processing: false,
		})

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleRegistration = this.handleRegistration.bind(this);
	}

	handleInputChange(event) {

        const target = event.target
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name

        if (name === "email") {
        	value = value.toLowerCase()
        }

        this.setState({
            [name]: value
        })
    }

    handleRegistration(event) {
    	event.preventDefault();

    	this.setState({processing: true})

    	var requestCredentials = {
            "email": this.state.email,
            "name": this.state.name,
            "password": this.state.password,
            "newsletterConsent": this.state.newsletterConsent,
            "listId": settings.MAILCHIMP_LIST_ID,
        };

        let cookie = utilities.getCookie("poirot_ref")
        if (cookie !== "") {
        	requestCredentials.referrerLink = cookie
        }
     //    let cookie = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).ref
     //    if (typeof cookie !== undefined) {
	    //     if (cookie !== "") {
	    //     	requestCredentials.referrerLink = cookie
	    //     }
	    // }

		let invite = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).invite
		if (invite !== undefined) {
			requestCredentials.teamInvitationLink = invite
		}

        if (this.state.honeyPot !== "") {
        	this.setState({processing: false})
        	return
        }

        if (this.state.email === "") {
        	this.setState({processing: false})
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

        if (!/^[a-zA-Z0-9_.][a-zA-Z0-9_.]*(\.[a-zA-Z.][a-zA-Z0-9_.]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/.test(this.state.email)) {
        	this.setState({processing: false})
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

        if (this.state.name === "") {
        	this.setState({processing: false})
			this.props.createNotification(
				"error",
				"Please enter a valid name.",
				"Error - team name",
				6000
			);
			return;
		}
		if (this.state.name.length < 2 || this.state.name.length > 32) {
			this.setState({processing: false})
			this.props.createNotification(
				"error",
				"Please enter a valid name. Name should be between 2 and 32 characters long.",
				"Error - team name",
				7000
			);
			return;
		}

        if (!/^([a-zA-Z0-9@*#._$&!%-]{6,128})$/.test(this.state.password)) {
        	this.setState({processing: false})
            this.props.createNotification('error', 'Please enter a valid password. Minimum length for the password is 6 characters. Valid characters are A-Z, a-z, 0-9 and @*#._$&!%-', 'Error - password', 7000);
            return
        }

        var self = this;

        axios.post(settings.API_HOST+'user/register', requestCredentials)
            .then(function (response) {
                if (response.status === 200) {
                    authentication.setAccessToken(response.data.token, "localStorage");
                    window.location.href = "/";
                } else {
                	this.setState({processing: false})
                    self.props.createNotification('error', 'Internal server error.', 'Error', 6000);
                }
        	}).catch(function (error) {
        		self.setState({processing: false})
                self.props.createNotification('error', 'You have entered invalid data or email address already in use. Please try again.', 'Error', 7000);
        });
    }

	render() {
		let poirotLogo = settings.CDN_HOST + 'images/PoirotLogoDark.svg'

		return (
			<React.Fragment>
			{this.state.processing ? <LoaderAnimation /> : <React.Fragment />}
				<div className="fullScreenWrapper noselect">
					<div className="authenticationSectionWrapper">
						
						<div className="authenticationSectionHeader">
							<div className="logoWrapper">
			    				<a href={settings.WEB_HOST}><img src={poirotLogo} alt="Poirot Application Logo - https://poirot.app"/></a>
			    			</div>
						</div>
						<div className="registerFormWrapper">
							<form className="authenticationForm" onSubmit={this.handleRegistration}>
								<div>
									<h1>Register your Poirot account</h1>
								</div>
								<div>
									<div>
										<h2>Register using your name, email & password</h2>
									</div>
									<div>
										<input autoFocus name="name" type="text" placeholder="Your name" value={this.state.name} onChange={this.handleInputChange}/>
									</div>
									<div>
										<input name="email" type="email" placeholder="Your email address" value={this.state.email} onChange={this.handleInputChange}/>
									</div>
									<div>
										<input name="password" type="password" placeholder="Your password" value={this.state.password} onChange={this.handleInputChange}/>
									</div>
									<div className="honeyPot">
										<input name="honeyPot" type="text" placeholder="honeyPot" value={this.state.honeyPot} onChange={this.handleInputChange}/>
									</div>
									<div className="authenticationFormCheckboxWrapper">
										<input name="newsletterConsent" type="checkbox" id="newsletterConsent" checked={this.state.newsletterConsent} onChange={this.handleInputChange}/>
										<label htmlFor="newsletterConsent" >Join Poirot's newsletter</label>
										<p>We would like to send you occasional news, information and special offers by email. It is possible to opt-out at any time by using the unsubscribe link in the emails.</p>
									</div>
									<div>
										<hr />
									</div>
									<div>
										<p>By signing up, you agree to the <a href="https://poirot.app/termsofservice">Terms of Service</a></p>
									</div>
								</div>
								<div>
									<button className="defaultButtonBlue">Register</button>
								</div>
							</form>
						</div>
						<div className="authenticationSectionFooter">
							<div>
								<a href="/signin">Have an account? Sign in.</a>
							</div>
							<div>
								|
							</div>
							<div>
								<a href="https://www.poirot.app/systemstatus">System status</a>
							</div>
							<div>
								|
							</div>
							<div>
								<p>Copyright &copy; {(new Date().getFullYear())} llc. All rights reserved.</p>
							</div> 
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Register;
