import React, { Component } from "react";
import axios from 'axios';
import ImportReferralEmail from "./importReferralEmail"

let settings = require("../../settings");

class ReferByEmail extends Component {
	constructor(props) {
		super(props)

		this.state=({
			importVisible: false,
			processing: false,
			importedEmailAddresses: []
		})

		this.toggleImportVisible = this.toggleImportVisible.bind(this);
		this.setLoaderVisibility = this.setLoaderVisibility.bind(this);
	}

	setLoaderVisibility(status) {
		this.setState({
			processing: status
		})
	}

	componentDidMount() {
		//
		// Check if user is in process in inviting Google contacts as a referral and decode token from hash
		//
		if (this.props.googleTokenPath !== null) {

				this.setState({
					importVisible: true,
					processing: true,
					importedEmailAddresses: []
				})

				var hashParts = this.props.googleTokenPath.location.hash.substr(1).split('&')

				var googleHash = {}
				hashParts.forEach((item) => {
					var parts = item.split('=')
					googleHash[parts[0]] = parts[1];
				})		

				if (typeof googleHash.error !== "undefined") {
					this.setLoaderVisibility(false)
					this.props.createNotification(
						"error",
						"An error occurred accessing Google contacts",
						"Error - Google contacts",
						6000
					);
				} else {

					///
					/// Fetch Google contact emails
					///
			        var self = this;

			        axios
			            .get(settings.GOOGLE_PEOPLE_CONNECTIONS_API_ENDPOINT, {
			                headers: { Authorization: "Bearer " + googleHash.access_token },
			            })
			            .then(function (response) {
			            	//console.log("RESPONSE: ", response)
			                if (response.status === 200) {
			                	if (response.data.totalPeople !== 0) {
			                		let googleContacts = response.data.connections
			                		var importedEmailAddresses = []

			                		googleContacts.forEach((item, index) => {
			                			let personAddresses = item.emailAddresses
			                			personAddresses.forEach((emailItem, emailIndex) => {
			                				importedEmailAddresses.push(emailItem.value)
			                			})
			                		})

			                		self.setState({
			                			importedEmailAddresses: importedEmailAddresses
			                		})

				                    self.props.createNotification(
				                        "success",
				                        "Emails uploaded from Google contacts",
				                        "Success",
				                        7000
				                    );
				                    self.setLoaderVisibility(false)
			                	} else {
			                		self.props.createNotification(
				                        "info",
				                        "No Google contacts found",
				                        "Info",
				                        6000
				                    );
				                    self.setLoaderVisibility(false)
			                	}
			                } else {
			                    self.props.createNotification(
			                        "error",
									"An error occurred accessing Google contacts",
									"Error - Google contacts",
			                        6000
			                    );
			                }
			            })
			            .catch(function (error) {
			                self.props.createNotification(
			                    "error",
								"An error occurred accessing Google contacts",
								"Error - Google contacts",
			                    6000
			                );
			            });
				}		
		}
	}

	toggleImportVisible() {
		this.setState({
			importVisible: !this.state.importVisible
		})
	}

	render() {

		return (
			<React.Fragment>
				{this.state.importVisible ? <ImportReferralEmail importedEmailAddresses={this.state.importedEmailAddresses}
																 setLoaderVisibility={this.setLoaderVisibility}
																 processing={this.state.processing}
																 userData={this.props.userData}
																 refreshUserData={this.props.refreshUserData}
																 createNotification={this.props.createNotification}
																 toggleImportVisible={this.toggleImportVisible}/> 
					: 
					<React.Fragment />}
				<div className="referByEmailWrapper">
					<div><h1>Refer by email</h1></div>
	        			<div className="referByEmail">
	        				
	        					<h4>
	        						Import your contacts from Gmail – or enter your contacts manually – and we’ll invite them for you.
	    						</h4>
							
							<div>
								<button className="defaultButtonGreen" onClick={this.toggleImportVisible}>Invite contacts</button>
							</div>
	        			</div>
				</div>
			</React.Fragment>
		)
	}
}

export default ReferByEmail;