import React, { Component } from "react";

class TagLabelRemoval extends Component {
	render() {

		return (
			<div className="teamRemovalWrapper">
				<div className="teamRemoval">
					<h1>Remove label tag</h1>
					<p>
						Are you sure you would like to remove:{" "}
						<br />
						<br />
						<svg height="14" width="14">
                                    <circle cx="7" cy="7" r="7" fill={this.props.color} />
                        </svg>
                        &nbsp;
                        &nbsp;
						<span>
							{
								this.props.label
							}
						</span>
						<br />
						<br />
					</p>
					<p>
						If removed, this label will be disassociated from all email addresses.
					</p>
					<h4>THIS ACTION IS IRREVERSIBLE.</h4>
					<div className="teamRemovalButtonWrapper">
						<button name="cancelButton" className="cancelButton"
							onClick={() => this.props.closeLabelRemoval()}
						>
							Cancel
						</button>
						<button
							className="defaultButtonRed"
							onClick={(event) => this.props.handleLabelRemoval(event)}
						>
							Remove
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default TagLabelRemoval;