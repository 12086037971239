import React, { Component } from 'react';
import axios from 'axios'

import Cleave from 'cleave.js/react';
import LoaderAnimation from '../../LoaderAnimation';

let settings = require("../../settings");
let authentication = require("../../authentication");

var valid = require("card-validator");

class AddNewCreditCard extends Component {
	constructor(props) {
		super(props)

		this.state={
			name: '',
			cardType: null,
			cardNumber: '',
			validThru: '',
			cvc: '',
			cvcName: "CVC",
			postalCode: '',
			processing: false
		}

		this.handleInputChange = this.handleInputChange.bind(this);
		this.cancelNewCardInput = this.cancelNewCardInput.bind(this);
		this.handleCardSave = this.handleCardSave.bind(this);
	}

	handleCardSave(event, isDefault) {
		event.preventDefault();

		var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {  
            return;
        }

        const requestCredentials = {
            name: this.state.name,
            cardNumber: this.state.cardNumber,
            month: this.state.validThru.slice(0,2),
            year: this.state.validThru.slice(3,5),
            cvc: this.state.cvc,
            postalCode: this.state.postalCode,
            isDefault: isDefault
        }

        var numberValidation = valid.number(requestCredentials.cardNumber)

        if (this.state.name === "") {
            this.props.createNotification(
                "error",
                "Please enter a valid name.",
                "Error - Cardholder Name",
                6000
            );
            return;
        }

        if (!numberValidation.isValid) {
            this.props.createNotification(
                "error",
                "Please enter a valid card number.",
                "Error - Card Number",
                6000
            );
            return;
        }

        var monthValidation = valid.expirationMonth(requestCredentials.month)
        var yearValidation = valid.expirationYear(requestCredentials.year)
        ///
        /// cvvValidation has a bug when 4 digits are required
        ///
        var cvvValidation = requestCredentials.cvc.length === numberValidation.card.code.size
        var postalCodeValidation = valid.postalCode(requestCredentials.postalCode)

        if (!monthValidation.isValid || !yearValidation.isValid) {
            this.props.createNotification(
                "error",
                "Please enter a valid card expiry date.",
                "Error - Card Expiry",
                6000
            );
            return;
        }

        if (!cvvValidation) {
            this.props.createNotification(
                "error",
                "Please enter a valid card CVC code.",
                "Error - Card CVC Code",
                6000
            );
            return;
        }

        if (!postalCodeValidation.isValid) {
            this.props.createNotification(
                "error",
                "Please enter a valid postal code.",
                "Error - Postal Code",
                6000
            );
            return;
        }

        this.setState({processing: true})

        axios
            .post(settings.API_HOST + "user/paymentmethod", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.setState({
                    				processing: false,
                    			 	name: '',
									cardType: null,
									cardNumber: '',
									validThru: '',
									cvc: '',
									cvcName: "CVC",
									postalCode: ''
								})
                    self.props.createNotification(
                    	"success",
                    	"Payment method successfully added.",
                    	"Success - Payment Method"
                    );
                    self.props.refreshUserData();
                    self.props.toggleNewCreditCardInputVisibility();
                    return;
                } else {
                    self.setState({processing: false})
                    self.props.createNotification(
                        "error",
                        "There was an error adding your card. Please try again using different payment method.",
                        "Error - Payment Method",
                        6000
                    );
                    return;
                }
            })
            .catch(function (error) {
            	if (error.response.status === 409) {
					self.setState({processing: false})
                	self.props.createNotification(
                    	"error",
                    	"Payment method already exists.",
                    	"Error - Payment Method"
                    );
                    return
				} else {
	                self.setState({processing: false})
	                self.props.createNotification(
	                    "error",
	                    "There was an error adding your card. Please try again using different payment method.",
	                    "Error - Payment Method",
	                    6000
	                );
                return;
	            }
            });


	}

	cancelNewCardInput(event) {
		event.preventDefault()

		this.setState({
			name: '',
			cardType: null,
			cardNumber: '',
			validThru: '',
			cvc: '',
			cvcName: "CVC",
			postalCode: ''
		})

		this.props.toggleNewCreditCardInputVisibility();
	}

    handleInputChange(event) {

        const target = event.target;
        var value = target.value;
        const name = target.name;
        
        if (name === 'cardNumber') {
            var numberValidation = valid.number(value);
                if (numberValidation.card) {
                    this.setState({cardType: numberValidation.card.type, cvcName: numberValidation.card.code.name});
                } else {
                    this.setState({cardType: null, cvcName: 'CVC'})
                }
        }

        if (name === 'cvc') {
            var size = 3

            var validations = valid.number(this.state.cardNumber)
            if (validations.card) {
                size = validations.card.code.size
            }

            if (value.length > size || isNaN(value)) {
                return
            }
        }
        

        this.setState({
            [name]: value
        });
    }

    render() {

    	let visa = settings.CDN_HOST + 'images/cards/visa.svg'
        let mastercard = settings.CDN_HOST + 'images/cards/mastercard.svg'
        let amex = settings.CDN_HOST + 'images/cards/amex.svg'
        let discover = settings.CDN_HOST + 'images/cards/discover.svg'
        let diners = settings.CDN_HOST + 'images/cards/diners.svg'
        let jcb = settings.CDN_HOST + 'images/cards/jcb.svg'
        let unionpay = settings.CDN_HOST + 'images/cards/unionpay.svg'
        let maestro = settings.CDN_HOST + 'images/cards/maestro.svg'
        let elo = settings.CDN_HOST + 'images/cards/elo.svg'
        let hipercard = settings.CDN_HOST + 'images/cards/hipercard.svg'
        let laser = settings.CDN_HOST + 'images/cards/laser.svg'

        return(
        	<React.Fragment>
        	{this.state.processing ? <LoaderAnimation /> : <React.Fragment />}
	        	<div className="creditCardAdditionWrapper">
	        		{this.props.creditCardInputIsVisible ?
	        			<form onSubmit={(event) => this.handleCardSave(event, true)}>
	        				<div>
			        			<div className="cardInputFieldLarge">
			        				<h4>Cardholder name</h4>
			        				<input name="name" type="text" value={this.state.name} onChange={this.handleInputChange} placeholder="Full name" autoComplete="cc-name" autoFocus/>
			        			</div>
			        			<div className="cardInputFieldLarge cardNumberInputField">
			        				<h4>Card number</h4>
			        				<Cleave placeholder="Enter your credit card number" options={{creditCard: true}} onChange={this.handleInputChange} name="cardNumber" value={this.state.cardNumber} autoComplete="cc-number"/>
					    				{this.state.cardType === 'visa' ? <img className="InputCardType" src={visa} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'mastercard' ? <img className="InputCardType" src={mastercard} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'american-express' ? <img className="InputCardType" src={amex} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'discover' ? <img className="InputCardType" src={discover} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'diners-club' ? <img className="InputCardType" src={diners} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'jcb' ? <img className="InputCardType" src={jcb} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'unionpay' ? <img className="InputCardType" src={unionpay} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'maestro' ? <img className="InputCardType" src={maestro} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'elo' ? <img className="InputCardType" src={elo} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'hipercard' ? <img className="InputCardType" src={hipercard} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
		                                {this.state.cardType === 'laser' ? <img className="InputCardType" src={laser} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
			        			</div>
			        			<div className="cardInputFieldShort">
			        				<h4>Valid through</h4>
	                                <Cleave placeholder="MM/YY" options={{date: true, datePattern: ['m', 'y']}} name="validThru" value={this.state.validThru} onChange={this.handleInputChange} autoComplete="cc-exp"/>
			        			</div>
		        			</div>
		        			<div>
			        			<div className="cardInputFieldShort">
			        				<h4>{this.state.cvcName}</h4>
	                                <input type="text" name="cvc" value={this.state.cvc} onChange={this.handleInputChange} placeholder="Code" autoComplete="cc-csc"/>
			        			</div>
			        			<div className="cardInputFieldShort">
			        				<h4>Postal code</h4>
	                                <input name="postalCode" type="text" value={this.state.postalCode} onChange={this.handleInputChange} placeholder="Zip"/>
			        			</div>
			        			<div className="cardInputFieldLarge creditCardsAdditionButtonsWrapper">
			        				{this.props.associatedCards ? <button className="defaultButtonBlue" type="submit">Save & Set as default</button> : <React.Fragment/>}
			        				<button className="defaultButtonGreen" onClick={(event) => this.handleCardSave(event, false)}>Save card</button>
			        				<button className="cancelButton" onClick={this.cancelNewCardInput}>Cancel</button>
			        			</div>
		        			</div>
	        			</form>
	        		:
			            <button
							className="defaultButtonGreen"
							onClick={this.props.toggleNewCreditCardInputVisibility}>
								Add new payment card
						</button>     
					}
				</div>
			</React.Fragment>
        )
    }
};

export default AddNewCreditCard;