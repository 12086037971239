import React, { Component } from "react";

import TeamMemberRemoval from './teamMemberRemoval';
import Gravatar from '../gravatar';

let icons = require("../../appComponents/icons");

class TeamMember extends Component {
    constructor(props) {
        super(props)

        this.state=({
            visibleRemovalConfirmation: false,
        })

        this.toggleTeamMemberRemoval = this.toggleTeamMemberRemoval.bind(this);
    }

    toggleTeamMemberRemoval() {
        this.setState({
            visibleRemovalConfirmation: !this.state.visibleRemovalConfirmation,
        })
    }

	render() {
		return (
            <React.Fragment>
            {this.state.visibleRemovalConfirmation ? (
                    <TeamMemberRemoval
                        teamId={this.props.teamId}
                        teamName={this.props.teamName}
                        email={this.props.member.email}
                        memberName={this.props.member.name}
                        createNotification={this.props.createNotification}
                        toggleTeamMemberRemoval={this.toggleTeamMemberRemoval}
                        updateListOfTeams={this.props.updateListOfTeams}
                        memberStatus={this.props.member.status}
                    />
                ) : (
                    <React.Fragment />
                )}
			<div className="teamMember">
                <div>
                    <Gravatar userEmailAddress={this.props.member.email} />
                </div>
                <div>{this.props.member.name}</div>
                <div>{this.props.member.email}</div>
                <div className={this.props.member.status === "Active" ? "memberStatusActive" : "memberStatusPending"}>{this.props.member.status}</div>
                <div><div className={this.props.member.email === this.props.userEmailAddress ? "displayNone" : "editIcon"} onClick={this.toggleTeamMemberRemoval}>{icons.trashCan()}</div></div>
            </div>
            </React.Fragment>
		)
	}
}

export default TeamMember;