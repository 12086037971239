import React, { Component } from "react";

class ReferralStats extends Component {

	render() {
		return (
			<div className="referralStatsWrapper">
				<div><h1>Referral stats</h1></div>
    				<div className="referralStats">
    					<h4>
    						Your current referral efforts and earnings.
						</h4>
					</div>
					<div className="referralStatsTable">
						<div>
							<div className="referralStat">Clicks</div>
							<div className="referralStatsDescription">Number of times your link has been clicked.</div>
							<div className="referralStatsAmount">{this.props.userData.referralCounter}</div>
						</div>
						<div>
							<div className="referralStat">Referrals</div>
							<div className="referralStatsDescription">People who have signed up using your link.</div>
							<div className="referralStatsAmount">{this.props.userData.referrals}</div>
						</div>
						<div>
							<div className="referralStat">Pending</div>
							<div className="referralStatsDescription">Amount you stand to earn when your commissions are approved.</div>
							<div className="referralStatsAmount">${(this.props.userData.pendingCommissions / 100).toFixed(2)}</div>
						</div>
						<div>
							<div className="referralStat">Earned</div>
							<div className="referralStatsDescription">Amount of approved commissions waiting for payout.</div>
							<div className="referralStatsAmount">${(this.props.userData.approvedCommissions / 100).toFixed(2)}</div>
						</div>
						<div>
							<div className="referralStat">Paid</div>
							<div className="referralStatsDescription">Total amount of previously paid commissions.</div>
							<div className="referralStatsAmount">${(this.props.userData.paidCommissions / 100).toFixed(2)}</div>
						</div>
        			</div>
			</div>
		)
	}
}

export default ReferralStats;
