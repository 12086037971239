import React, { Component } from "react";
import axios from 'axios'

let settings = require("../../settings");
let authentication = require("../../authentication");
let icons = require("../../appComponents/icons");

class TeamMembership extends Component {
	constructor(props) {
		super(props)

		this.state=({inEditMode: false, 
					teamId: '', 
					teamName: ''
				})

		this.removeFromTeam = this.removeFromTeam.bind(this);
	}

	removeFromTeam(event) {
		console.log("EVENT: ", event)
		event.preventDefault()

		let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

        const headers = { Authorization: "Bearer " + tokenHolder.token };

        const data = {
            teamId: this.state.teamId,
        };

        axios
            .delete(settings.API_HOST + "user/fromteam", { headers, data })
            .then(function (response) {
                if (response.status === 200) {
                    self.props.createNotification(
                        "success",
                        "You have been successfully removed from team.",
                        "Team Updated",
                        7000
                    );
                    self.setState({
                    	inEditMode: false,
                    	teamId: '',
                    	teamName: ''
                    })
                    self.props.refreshUserData();
                    return
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                console.log("ERROR: ", error.response)
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
	}

	render() {

		let defaultTeamAvatar = settings.CDN_HOST + "images/team-circle.svg";
		var team
		if (this.props.userData.isTeamMember) {
			team = this.props.userData.teams.map((team, index) => {
				return (

					<div key={index} className="profileTeamWrapper">

						<div className="profileTeamImage">
							<img
								src={team.avatar !== undefined ? team.avatar : defaultTeamAvatar}
								alt="Poirot Team Avatar - https://poirot.app"
								width="38"
								height="38"
							/>   
						</div>
						<div><h4>{team.name}</h4></div>
						<div><div className="removalFromTeam" onClick={() => this.setState({inEditMode: true, teamId: team.id, teamName: team.name})} >{icons.trashCan()}</div></div>


					</div>
				)
			});
		} else {
			team = () => { return (<React.Fragment/>)}			
		}

		return (
			<React.Fragment>

				{this.state.inEditMode ?
					<div className="teamMemberRemovalWrapper">
				<div className="teamMemberRemoval">
					<h1>Leave team {this.state.teamName}</h1>
					<p>
						Are you sure you would like to remove{" "}
						<span>
							yourself
						</span>{" "}
						from{" "}
						<span>
							{
								this.state.teamName
							}
						</span>{" "}
						team?
					</p>
					<p>
						All email address searches and verifications associated
						with you will still be accessible to other team members.
					</p>
					<p>
						You can always ask for reinvite to rejoin this team.
					</p>
					<div className="teamMemberRemovalButtonWrapper">
						<button className="cancelButton"
							onClick={() => this.setState({inEditMode: false, teamId: '', teamName: ''})}
						>
							Cancel
						</button>
						<button
							className="defaultButtonRed"
							onClick={this.removeFromTeam}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
				:
					<React.Fragment />
				}


				{this.props.userData.isTeamMember ?
				<div className="teamMembership">
					<div><h1>Team memberships</h1></div>
					<div className="teamMembershipWrapper">
						{team.length !== 0 ? team : <h3>You are only a member of your own teams. Your teams are managed on a 'Teamwork' page.</h3>}
					</div>
				</div>
				:
				<div className="teamMembership">
					<div><h1>Team memberships</h1></div>
					<div className="teamMembershipWrapper">
						<h3>You are not a member of any team.</h3>
					</div>
				</div>
				}
			</React.Fragment>
		)
	}
}

export default TeamMembership;
