import React, { Component } from "react";
import axios from 'axios'

import { HighlightWithinTextarea } from 'react-highlight-within-textarea'
import LoaderAnimation from '../../LoaderAnimation';


let settings = require("../../settings");
let authentication = require("../../authentication");

let icons = require("../../appComponents/icons");

class ImportReferralEmail extends Component {
	constructor(props) {
		super(props)

		this.state={
			extractedAddresses: [],
			addresses: "",
			importedEmailAddresses: this.props.importedEmailAddresses
		}

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleInviteSend = this.handleInviteSend.bind(this)
	}

	static getDerivedStateFromProps(props, state) {
	    // Any time the current user changes,
	    // Reset any parts of state that are tied to that user.
	    // In this simple example, that's just the email.
	    if (props.importedEmailAddresses !== state.importedEmailAddresses) {

    		///
			/// Detect email addresses in fields
			///
			function extractEmails(text) {
			    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
			}

			let emails = extractEmails(props.importedEmailAddresses.toString())

			if (emails === null) {
				emails = []
			}

		return {
	        importedEmailAddresses: props.importedEmailAddresses,
	        addresses: props.importedEmailAddresses.toString(),
	        extractedAddresses: emails
	      };
	    }
	    return null;
  }

	handleGmailInvite(event) {
		event.preventDefault();
		window.location.href = "https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/contacts.readonly&include_granted_scopes=true&response_type=token&redirect_uri=https%3A//system.poirot.app/googleinvite&client_id=80500229147-g0uo3h1vu0gn6i163ek37lq6nc6oidvq.apps.googleusercontent.com";
	}

	handleInviteSend(event) {
		event.preventDefault();

		if (this.state.extractedAddresses === []) {
			this.props.createNotification(
				"error",
				"Please enter a valid email addresses.",
				"Error - Email Addresses",
				6000
			);
			return;
		}

		var self = this;

		this.props.setLoaderVisibility(true);

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		const requestData = {
			addresses: this.state.extractedAddresses,
		};

		axios
			.post(settings.API_HOST + "user/sendreferralinvite", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.props.refreshUserData()
					self.props.toggleImportVisible()
					self.props.setLoaderVisibility(false);
					self.props.createNotification(
						"success",
						"You have successfully sent invites to specified email addresses.",
						"Success - Invites sent",
						7000
					);
				} else {
					self.props.setLoaderVisibility(false);
					self.props.createNotification(
						"error",
						"An error occurred while trying to send invites.",
						"Error - Invites",
						7000
					);
				}
			})
			.catch(function (error) {
				if (error.response.status === 401) {
					self.props.setLoaderVisibility(false);
					self.props.createNotification(
						"error",
						"You have reached maximum number of invites for today",
						"Error - Invites number",
						7000
					);
				} else {
					self.props.setLoaderVisibility(false);
					self.props.createNotification(
						"error",
						"An error occurred while trying to send invites.",
						"Error - Invites",
						7000
					);
				}
				});
	}

	handleInputChange(event) {
		const target = event.target;
		var value = target.value.toLowerCase();
		const name = target.name;

		///
		/// Detect email addresses in fields
		///
		function extractEmails(text) {
		    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
		}

		let emails = extractEmails(value)

		if (emails === null) {
			emails = []
		}

		this.setState({
			[name]: value,
			extractedAddresses: emails
		});
	}

	render() {

		const highlight = [
		    {
		      highlight: (/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi),
		      className: 'textAreaHighlight'
		    }
	    ]

		return (
			<React.Fragment>
				{this.props.processing ? <LoaderAnimation /> : <React.Fragment />}
				<div className="referralEmailInviteWrapper">
					<form id="inviteForm" className="referralEmailInvite" onSubmit={(event) => this.handleInviteSend(event)}>
						<div><h1>Invite your friends</h1></div>
						<div><h4>Invite your contacts that you'd like to send <span>{settings.REFEREE_PERMANENT_CREDITS}</span> permanent credits to, and earn <span>{settings.REFERRAL_COMMISSION}% recurring</span> referral commission.</h4></div>
						<div className="inviteGmailContactsButtonWrapper"><button className="inviteGmailContactsButton" onClick={(event) => this.handleGmailInvite(event)}>{icons.googleLogo()} Invite Google contacts</button></div>
						<div className="highlightContainerWrapper">
							<HighlightWithinTextarea 
								autoFocus
								highlight={highlight}
								name="addresses" 
								onChange={this.handleInputChange} 
								value={this.state.addresses} 
								containerClassName="highlightContainer"
								placeholder="Type or paste email addresses here"/>
						</div>
						<div className="heighlightTextAreaNotification">
							<div className={this.state.extractedAddresses.length > this.props.userData.remainingNumberOfDailyInvites ? "excedeedNumberOfMessages" : "displayNone"}>Exceeded max. number of addresses.</div>
							<div className="availableInvitesCounter">You have {this.props.userData.remainingNumberOfDailyInvites} invites remaining today</div>
						</div>
						<div className="inviteReferralEmailsActionWrapper">
							{(this.state.extractedAddresses.length === 0 || this.state.extractedAddresses.length > this.props.userData.remainingNumberOfDailyInvites)? <button className="defaultButtonBlue" disabled="disabled">Send invites</button> 
							: 
								<React.Fragment>
									{this.state.extractedAddresses.length === 1 ? <button type="submit" className="defaultButtonBlue">Send 1 invite</button> : <React.Fragment />} 
									{this.state.extractedAddresses.length > 1 ? <button type="submit" className="defaultButtonBlue">Send {this.state.extractedAddresses.length} invites</button> : <React.Fragment />} 
								</React.Fragment>
							}
							<button className="cancelButton" onClick={this.props.toggleImportVisible}>Cancel</button>
						</div>
					</form>
				</div>
			</React.Fragment>
		)
	}
}

export default ImportReferralEmail;