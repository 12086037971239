import React, { Component } from "react";
import axios from "axios";

let settings = require("../../settings");
let authentication = require("../../authentication");

class TaskRemovalSelector extends Component {

	handleTaskRemoval() {

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		if (typeof this.props.selectedTaskForRemoval === 'undefined') {
			return;
		}

		const headers = { Authorization: "Bearer " + tokenHolder.token };

        const data = {
            taskId: this.props.selectedTaskForRemoval,
        };

		axios
			.delete(settings.API_HOST + "task", {headers, data})
			.then(function (response) {
				if (response.status === 200) {
					self.props.hideTaskRemovalSelector();
					self.props.refreshTaskData();
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while trying to remove task",
						"Error - Task Removal",
						7000
					);
				}
			})
			.catch(function (error) {
					self.props.createNotification(
						"error",
						"An error occurred while trying to remove task",
						"Error - Task Removal",
						6000
					);
			});

	}


	render() {
		return (
			<div className="tagRemovalSelector" onClick={() => this.handleTaskRemoval()}>
				<div>
					Remove lookup task
				</div>
			</div>
		);
	}
}

export default TaskRemovalSelector;