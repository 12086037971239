import React, { Component } from 'react'
import axios from 'axios'

let settings = require('./settings')
let authentication = require('./authentication')

class SignIn extends Component {
	constructor(props) {
		super(props)

		this.state=({
			email: "",
			password: "",
			keepMeSignedIn: true,
			honeyPot: "",
			verificationRequired: false,
			code: ''
		})

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleSignIn = this.handleSignIn.bind(this)
	}

    handleInputChange(event) {

        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "email") {
        	value = value.toLowerCase();
        }

        if (name === "code") {
        	if (isNaN(value) || value.length > 6) {
		        return
	    	}
        }

        this.setState({
            [name]: value
        });
    }

    componentDidUpdate(prevProps, prevState) {
    	if (prevState.verificationRequired === false && this.state.verificationRequired === true) {
			this.nameInput.focus();
		}
	}


    handleSignIn(event) {
        event.preventDefault();

        const requestCredentials = {
            "email": this.state.email,
            "password": this.state.password
        };

        if (this.state.code !== '') {
        	requestCredentials["code"] = this.state.code
        }

        if (this.state.honeyPot !== "") {
        	return
        }

        if (this.state.email === "") {
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

        if (!/^[a-zA-Z0-9_.][a-zA-Z0-9_.]*(\.[a-zA-Z_.][a-zA-Z0-9_.]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/.test(this.state.email)) {
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

        if (!/^([a-zA-Z0-9@*#._$&!%-]{6,128})$/.test(this.state.password)) {
            this.props.createNotification('error', 'Please enter a valid password. Minimum length for the password is 6 characters. Valid characters are A-Z, a-z, 0-9 and @*#._$&!%-', 'Error - password', 7000);
            return
        }

        var self = this;

        axios.post(settings.API_HOST+'user/signin', requestCredentials)
            .then(function (response) {
                if (response.status === 200) {
                    if (self.state.keepMeSignedIn) {
                        authentication.setAccessToken(response.data.token, "localStorage");
                    } else {
                        authentication.setAccessToken(response.data.token, "sessionStorage");
                    }
                    self.props.toggleAuthentication();
                } else if (response.status === 201) {
                	self.setState({
                		verificationRequired: true
                	})
                } else {
                    self.props.createNotification('error', 'Internal server error.', 'Error', 6000);
                }

        })
            .catch(function (error) {
                self.props.createNotification('error', 'An error occurred. Please try again.', 'Error', 6000);
        });
    }


	render() {
		let poirotLogo = settings.CDN_HOST + 'images/PoirotLogoDark.svg'

		return (
			<React.Fragment>

				{this.state.verificationRequired ? 

					<div className="fullScreenWrapper">
						<div className="authenticationSectionWrapper">
							<div className="authenticationSectionHeader">
								<div className="logoWrapper">
				    				<a href={settings.WEB_HOST}><img src={poirotLogo} alt="Poirot Application Logo - https://poirot.app"/></a>
				    			</div>
							</div>
							<div className="signInFormWrapper">
								<form className="authenticationForm" onSubmit={this.handleSignIn}>
									<div>
										<h1>Sign in to your Poirot account</h1>
									</div>
									<div>
										<div>
											<h2>Enter 2FA verification code</h2>
										</div>
										<div>
											<input ref={(input) => { this.nameInput = input; }} className="authenticationFormTfa" name="code" type="code" placeholder="Your code" value={this.state.code} onChange={this.handleInputChange}/>
										</div>
										<div className="honeyPot">
											<input name="honeyPot" type="text" placeholder="honeyPot" value={this.state.honeyPot} onChange={this.handleInputChange}/>
										</div>
										
										<div>
											<hr />
										</div>
										<div>
											<a href="/">Message not received?</a>
										</div>
									</div>
									<div>
										<button className="defaultButtonBlue">Continue</button>
									</div>
								</form>
							</div>
							<div className="authenticationSectionFooter">
								<div>
									<a href="/register">New to Poirot? Register your account</a>
								</div>
								<div>
									|
								</div>
								<div>
									<a href="https://www.poirot.app/systemstatus">System status</a>
								</div>
								<div>
									|
								</div>
								<div>
									<p>Copyright &copy; {(new Date().getFullYear())} Intuos llc. All rights reserved.</p>
								</div> 
							</div>
						</div>
					</div>

				:	

					<div className="fullScreenWrapper">
						<div className="authenticationSectionWrapper">
							<div className="authenticationSectionHeader">
								<div className="logoWrapper">
				    				<a href={settings.WEB_HOST}><img src={poirotLogo} alt="Poirot Application Logo - https://poirot.app"/></a>
				    			</div>
							</div>
							<div className="signInFormWrapper">
								<form className="authenticationForm" onSubmit={this.handleSignIn}>
									<div>
										<h1>Sign in to your Poirot account</h1>
									</div>
									<div>
										<div>
											<h2>Enter your email address and password</h2>
										</div>
										<div>
											<input autoFocus name="email" type="email" placeholder="Your email address" value={this.state.email} onChange={this.handleInputChange}/>
										</div>
										<div>
											<input name="password" type="password" placeholder="Your password" value={this.state.password} onChange={this.handleInputChange}/>
										</div>
										<div className="honeyPot">
											<input name="honeyPot" type="text" placeholder="honeyPot" value={this.state.honeyPot} onChange={this.handleInputChange}/>
										</div>
										<div className="authenticationFormCheckboxWrapper">
											<input name="keepMeSignedIn" type="checkbox" id="keepMeSignedIn" checked={this.state.keepMeSignedIn} onChange={this.handleInputChange}/>
											<label htmlFor="keepMeSignedIn">Keep me signed in</label>
										</div>
										<div>
											<hr />
										</div>
										<div>
											<a href="/requestpasswordreset">Forgot your password?</a>
										</div>
									</div>
									<div>
										<button className="defaultButtonBlue">Continue</button>
									</div>
								</form>
							</div>
							<div className="authenticationSectionFooter">
								<div>
									<a href="/register">New to Poirot? Register your account</a>
								</div>
								<div>
									|
								</div>
								<div>
									<a href="https://www.poirot.app/systemstatus">System status</a>
								</div>
								<div>
									|
								</div>
								<div>
									<p>Copyright &copy; {(new Date().getFullYear())} Intuos llc. All rights reserved.</p>
								</div> 
							</div>
						</div>
					</div>
				}
			</React.Fragment>
		)
	}
}

export default SignIn;
