import React, { Component } from 'react';
import axios from 'axios'

import Select from 'react-select'
import LoaderAnimation from '../../LoaderAnimation';

let settings = require("../../settings");
let authentication = require("../../authentication");

//
// Set time interval to constantly check user credit amount.
//
var refreshUserCreditDataInterval;

class PayPal extends Component {
	constructor(props) {
		super(props)

		var processing = false
		var loaderAnimationMessage = undefined

		if (this.props.payPalTokenPath === 'approvedprepaidcredits') {
			processing = true;
			loaderAnimationMessage = 'Waiting for PayPal to finish processing your request. This may take up to a minute to complete.'
		}

		this.state={
			customAmount: '',
			selectedAmount: 100,
			accountBalance: 0,
			processing: processing,
			loaderAnimationMessage: loaderAnimationMessage,
			isInitialResponse: true
		}

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleAmountSelection = this.handleAmountSelection.bind(this)
		this.refreshUserAccountBalance = this.refreshUserAccountBalance.bind(this)
		this.initiatePayPalPayment = this.initiatePayPalPayment.bind(this)
	}

    componentWillUnmount() {
        clearInterval(refreshUserCreditDataInterval);
    }

	initiatePayPalPayment(event) {
		event.preventDefault()

		this.setState({processing: true})

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {			
			return;
		}

        const requestData = {
            "amount": this.state.selectedAmount !== 'customAmount' ? this.state.selectedAmount : Number(this.state.customAmount),
            "type": "pre-paid",
            "approveRedirectUrl": settings.PAYPAL_PREPAID_CREDITS_APPROVED_REDIRECT_URL,
            "cancelRedirectUrl": settings.PAYPAL_PREPAID_CREDITS_CANCEL_REDIRECT_URL,
		}

		axios
			.post(settings.API_HOST + "user/paypal", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
						    self.setState({loaderAnimationMessage: 'Waiting for PayPal to finish processing your request. This may take up to a minute to complete.'})
						    window.location.href = response.data.url;
						    setTimeout(function(){ self.setState({loaderAnimationMessage: undefined, processing: false}); }, 3000);
						 //    let newTab = window.open();
							// newTab.location.href = response.data.url;
						 //    var timer = setInterval(function() {
						 //        if (newTab.closed) {
						 //            clearInterval(timer);
						 //            self.setState({processing: false, loaderAnimationMessage: undefined})
						 //        }
						 //    }, 500);
							
				} else {
					self.props.createNotification(
						"error",
						"An error occurred processing your request.",
						"Error - PayPal Processing",
						7000
					);
					
				}
			})
			.catch(function (error) {
				self.setState({processing: false})
				self.props.createNotification(
					"error",
					"An error occurred processing your request.",
					"Error - PayPal Processing",
					7000
				);	
			});

	}

	handleAmountSelection(item) {
		var self = this;
		this.setState({selectedAmount: item.value}, () => {
			if (item.value === 'customAmount') {
				self.nameInput.focus();
			}
		})
	}

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;

        if (name === 'customAmount') {
        	if (isNaN(value) || value > 10000) {
        		return
        	} 
        }

        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {
    	var self = this;

    	this.refreshUserAccountBalance()

    	refreshUserCreditDataInterval = setInterval(function () {
	            self.refreshUserAccountBalance();
	        }, self.props.payPalTokenPath === null ? 60000 : 5000);    	
    }

	refreshUserAccountBalance() {
		var self = this;
		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {			
			return;
		}

		axios
			.get(settings.API_HOST + "user/accountbalance", {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (typeof self.state.loaderAnimationMessage === "undefined") {
					self.setState({processing: false})
				}
				if (response.status === 200) {
					if (self.state.isInitialResponse) {
						self.setState({
							accountBalance: response.data.amount,
							isInitialResponse: false,
						})
					} else if (self.state.accountBalance !== response.data.amount && !self.state.isInitialResponse) {
						self.setState({
							processing: false,
							loaderAnimationMessage: undefined,
							accountBalance: response.data.amount
						})
						self.props.createNotification(
							"success",
							"You have successfully added credit to your account.",
							"Success - User Account Balance",
							7000
						);	
						clearInterval(refreshUserCreditDataInterval);
							refreshUserCreditDataInterval = setInterval(function () {
					            self.refreshUserAccountBalance();
					        }, 60000);
				        self.props.setPayPalTokenPathToNull()
					}
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while fetching current account balance.",
						"Error - User Account Balance",
						7000
					);
					
				}
			})
			.catch(function (error) {
				self.setState({processing: false})
				self.props.createNotification(
					"error",
					"An error occurred while fetching current account balance.",
					"Error - User Account Balance",
					7000
				);	
			});
	}

    render() {

    	var selectOptions = 
                    [
                {value: 'customAmount', 
                    label: 'Custom amount'},
                {value: 50, 
                    label: ' $50.00'},
                {value: 100, 
                    label: ' $100.00'},
                {value: 150, 
                    label: ' $150.00'},
                {value: 200, 
                    label: ' $200.00'},
                {value: 500,
                	label: ' $500.00'},
                {value: 1000,
                	label: ' $1000.00'}
	                ]

        return(
        	<React.Fragment>
	        	{this.state.processing ? <LoaderAnimation message={this.state.loaderAnimationMessage}/> : <React.Fragment />}
	            <div className="payPal">
	                <div><h1>PayPal payment</h1></div>
	                <div><h4>To use PayPal as your payment method, you will need to make pre-payments each month before your bill is due.</h4></div>
	                <div className="payPalSelectionWrapper">
	                	<div className="payPalSelection">
	                		<div><Select className="reactSelectComponent" classNamePrefix="react-select" options={selectOptions} autoFocus blurInputOnSelect="true" onChange={this.handleAmountSelection} defaultValue={selectOptions[2]}/></div>
	                		<div className="customAmountInputFieldWrapper">
		                		<h6 className={this.state.selectedAmount !== "customAmount" ? "displayNone" : ""}>Payments between $49.00 and $10,000.00 are accepted.</h6>
	                			<input className={this.state.selectedAmount !== "customAmount" ? "displayNone" : ""} ref={(input) => { this.nameInput = input; }} type="text" name="customAmount" value={this.state.customAmount} onChange={this.handleInputChange} placeholder="Custom amount" autoFocus/>
                			</div>
		                	<div><button className="defaultButtonBlue" onClick={this.initiatePayPalPayment}>Continue to PayPal</button></div>
	                	</div>
	                	<div>
	                		<div className="currentBalanceChartWrapper">
								<div className="currentBalanceChartName">Current account balance</div>
								<div className="currentBalanceChartStatus">
									<div><span>$</span></div>
									<div>
											{(Math.round(this.state.accountBalance * 100) / 100).toFixed(2)}
									</div>
								</div>
							</div>
		                </div>
	               	</div>
	            </div>       
	        </React.Fragment>
        )
    }
};

export default PayPal;