import React, { Component } from 'react';
import axios from 'axios';

import Cleave from 'cleave.js/react';

import LoaderAnimation from '../LoaderAnimation';

import {loadStripe} from '@stripe/stripe-js';

let settings = require("../settings");
let authentication = require("../authentication");

var valid = require("card-validator");

//
// Set time interval to constantly fetch updated user data.
//
var refreshUserDataInterval;

class PurchaseCreditsFormNewCard extends Component {
    constructor(props) {
        super(props)

        this.state={
            method: 'card',
            name: '',
            cardType: null,
            cardNumber: '',
            validThru: '',
            cvc: '',
            cvcName: 'CVC',
            postalCode: '',
            processing: false,
            loaderAnimationMessage: undefined,
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.selectPaymentMethod = this.selectPaymentMethod.bind(this)

        this.handlePurchase = this.handlePurchase.bind(this)
        this.checkIfUserIsUpdated = this.checkIfUserIsUpdated.bind(this);

        this.handlePaymentFailure = this.handlePaymentFailure.bind(this);

        this.initiatePayPalPayment = this.initiatePayPalPayment.bind(this);
    }

    initiatePayPalPayment(event) {
        event.preventDefault()

        this.setState({processing: true})

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {         
            return;
        }

        const requestData = {
            "amount": this.state.payPalOptions[this.state.selectedPayPalOption].value,
            "type": "subscription",
            "plan": this.props.selectedPlan.selected.id,
            "approveRedirectUrl": settings.PAYPAL_SUBSCRIBE_APPROVED_REDIRECT_URL,
            "cancelRedirectUrl": settings.PAYPAL_SUBSCRIBE_CANCEL_REDIRECT_URL,
        }

        axios
            .post(settings.API_HOST + "user/paypal", requestData, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                            self.setState({loaderAnimationMessage: 'Waiting for PayPal to finish processing your request. This may take up to a minute to complete.'})
                            window.location.href = response.data.url;
                            setTimeout(function(){ self.setState({loaderAnimationMessage: undefined, processing: false}); }, 3000);
                } else {
                    self.setState({processing: false})
                    self.props.createNotification(
                        "error",
                        "An error occurred processing your request.",
                        "Error - PayPal Processing",
                        7000
                    );
                    
                }
            })
            .catch(function (error) {
                self.setState({processing: false})
                self.props.createNotification(
                    "error",
                    "An error occurred processing your request.",
                    "Error - PayPal Processing",
                    7000
                );  
            });
    }

    checkIfUserIsUpdated() {
        var self = this;
        authentication.getUserData().then(function (userData) {
            if (userData !== null || userData !== "unavailable") {

                if (self.props.userData.permanentCredits + self.props.volume === userData.permanentCredits) {
                    self.props.createNotification(
                        "success",
                        "You have successfully added permanent credits to your account.",
                        "Success - Payment Successful",
                        6000
                    )
                    self.setState({
                                    processing: false,
                                    name: '',
                                    cardType: null,
                                    cardNumber: '',
                                    validThru: '',
                                    cvc: '',
                                    cvcName: 'CVC',
                                    postalCode: ''
                                })
                    self.props.refreshUserData()
                    self.props.togglePurchaseCreditsVisibility();
                }  
            }
        });
    }

    handlePaymentFailure(paymentMethodId, token) {
        var self = this;

        const data = {
            id: paymentMethodId,
        }
        const headers = { Authorization: "Bearer " + token };

        axios
            .delete(settings.API_HOST + "stripe/paymentmethod", { headers, data })
            .then(function (detachResponse) {
                self.setState({
                    processing: false,
                })

                self.nameInput.focus();

                if (detachResponse.status === 200) {
                    self.props.createNotification(
                        "error",
                        "There was an error processing your payment. Please try again using different payment method.",
                        "Error - Payment Failed",
                        6000
                    );
                    return;
                } else {
                    self.props.createNotification(
                        "error",
                        "There was an error processing your payment. Please try again using different payment method.",
                        "Error - Payment Failed",
                        6000
                    );
                    return;
                }
            })
        .catch(function (error) {
            self.props.createNotification(
                "error",
                "There was an error processing your payment. Please try again using different payment method.",
                "Error - Payment Failed",
                6000
            );
            return;
        })
    }


    handlePurchase(event) {
        event.preventDefault()

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {  
            return;
        }

        const requestCredentials = {
            name: this.state.name,
            cardNumber: this.state.cardNumber,
            month: this.state.validThru.slice(0,2),
            year: this.state.validThru.slice(3,5),
            cvc: this.state.cvc,
            postalCode: this.state.postalCode,
            volume: this.props.volume,
        }
        var numberValidation = valid.number(requestCredentials.cardNumber)

        if (this.state.name === "") {
            this.props.createNotification(
                "error",
                "Please enter a valid name.",
                "Error - Cardholder Name",
                6000
            );
            return;
        }

        if (!numberValidation.isValid) {
            this.props.createNotification(
                "error",
                "Please enter a valid card number.",
                "Error - Card Number",
                6000
            );
            return;
        }

        var monthValidation = valid.expirationMonth(requestCredentials.month)
        var yearValidation = valid.expirationYear(requestCredentials.year)
        ///
        /// cvvValidation has a bug when 4 digits are required
        ///
        var cvvValidation = requestCredentials.cvc.length === numberValidation.card.code.size
        var postalCodeValidation = valid.postalCode(requestCredentials.postalCode)

        if (!monthValidation.isValid || !yearValidation.isValid) {
            this.props.createNotification(
                "error",
                "Please enter a valid card expiry date.",
                "Error - Card Expiry",
                6000
            );
            return;
        }

        if (!cvvValidation) {
            this.props.createNotification(
                "error",
                "Please enter a valid card CVC code.",
                "Error - Card CVC Code",
                6000
            );
            return;
        }

        if (!postalCodeValidation.isValid) {
            this.props.createNotification(
                "error",
                "Please enter a valid postal code.",
                "Error - Postal Code",
                6000
            );
            return;
        }

        this.setState({processing: true})

        axios
            .post(settings.API_HOST + "user/newcardcreditspurchase", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    
                    switch (response.data.status) {

                        case "requires_action":
                            loadStripe(settings.STRIPE_PUBLISHABLE_KEY).then(function(stripe) {

                            stripe.confirmCardPayment(response.data.clientSecret, {
                                payment_method: response.data.paymentMethodId,
                                })
                                .then(function (result) {
                                    if (result.error) {
                                        // Start code flow to handle updating the payment details.
                                        // Display error message in your UI.
                                        // The card was declined (i.e. insufficient funds, card has expired, etc).
                                        self.handlePaymentFailure(response.data.paymentMethodId, tokenHolder.token)
                                        return;
                                    } else {
                                        if (result.paymentIntent.status === 'succeeded') {
                                            // Show a success message to your customer.
                                            // There's a risk of the customer closing the window before the callback.
                                            // We recommend setting up webhook endpoints later in this guide.
                                            refreshUserDataInterval = setInterval(function () {
                                                self.checkIfUserIsUpdated();
                                            }, 2000);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    self.handlePaymentFailure(response.data.paymentMethodId, tokenHolder.token)
                                    return;
                                });
                            });
                            break;

                        case "requires_payment_method":
                            //
                            // If the payment attempt fails for example due to a decline,
                            // current payment method should be detached, and
                            // payment process restarted.
                            //
                            self.handlePaymentFailure(response.data.paymentMethodId, tokenHolder.token);
                            break;

                        case "requires_confirmation":
                            //
                            // Should never execute.
                            //
                            break;

                        case "requires_capture":
                            break;

                        case "processing":
                            //
                            // Wait till processing is over. Notification 
                            // email should be sent after operation succeeds. 
                            // 
                            break;

                        case "canceled":
                            //
                            // This should never execute
                            //
                            break;

                        case "succeeded":
                            refreshUserDataInterval = setInterval(function () {
                                self.checkIfUserIsUpdated();
                            }, 2000);
                            break;

                        default:
                            self.setState({processing: false})
                            self.props.createNotification(
                                "error",
                                "Internal server error.",
                                "Error",
                                6000
                            );
                            break;
                    }
                    
                } else {
                    self.setState({processing: false})
                    self.props.createNotification(
                        "error",
                        "There was an error processing your payment. Please try again using different payment method.",
                        "Error - Payment Failed",
                        6000
                    );
                    return;
                }
            })
            .catch(function (error) {
                self.setState({processing: false})
                self.props.createNotification(
                    "error",
                    "There was an error processing your payment. Please try again using different payment method.",
                    "Error - Payment Failed",
                    6000
                );
                return;
            });
    }

    componentWillUnmount() {
        clearInterval(refreshUserDataInterval);
    }

    handleInputChange(event) {

        const target = event.target;
        var value = target.value;
        const name = target.name;
        
        if (name === 'cardNumber') {
            var numberValidation = valid.number(value);
                if (numberValidation.card) {
                    this.setState({cardType: numberValidation.card.type, cvcName: numberValidation.card.code.name});
                } else {
                    this.setState({cardType: null, cvcName: 'CVC'})
                }
        }

        if (name === 'cvc') {
            var size = 3

            var validations = valid.number(this.state.cardNumber)
            if (validations.card) {
                size = validations.card.code.size
            }

            if (value.length > size || isNaN(value)) {
                return
            }
        }
        

        this.setState({
            [name]: value
        });
    }

    selectPaymentMethod(method) {
            this.setState({method: method})
    }

    render() {

        let creditCard = settings.CDN_HOST + 'images/cards/CreditCard.svg'
        let payPalLogo = settings.CDN_HOST + 'images/PayPalLogo.svg'
        let visa = settings.CDN_HOST + 'images/cards/visa.svg'
        let mastercard = settings.CDN_HOST + 'images/cards/mastercard.svg'
        let amex = settings.CDN_HOST + 'images/cards/amex.svg'
        let discover = settings.CDN_HOST + 'images/cards/discover.svg'
        let diners = settings.CDN_HOST + 'images/cards/diners.svg'
        let jcb = settings.CDN_HOST + 'images/cards/jcb.svg'
        let unionpay = settings.CDN_HOST + 'images/cards/unionpay.svg'
        let maestro = settings.CDN_HOST + 'images/cards/maestro.svg'
        let elo = settings.CDN_HOST + 'images/cards/elo.svg'
        let hipercard = settings.CDN_HOST + 'images/cards/hipercard.svg'
        let laser = settings.CDN_HOST + 'images/cards/laser.svg'

        return(
            <React.Fragment>
            {this.state.processing ? <LoaderAnimation message={this.state.loaderAnimationMessage}/> : <React.Fragment />}
                <div className="paymentFormWrapper">
                    <div className="paymentForm">
                            <div className="paymentMethodWrapper">
                                <div className="paymentMethodSelector">
                                    <div>
                                        <h3>Selected payment method</h3>
                                    </div>
                                    <div>
                                        <ul>
                                            <li className={this.state.method === 'card' ? 'paymentMethodSelectorItem active' : 'paymentMethodSelectorItem'} onClick={() => this.selectPaymentMethod('card')}>Credit / Debit Card</li>
                                            <li className={this.state.method === 'payPal' ? 'paymentMethodSelectorItem active' : 'paymentMethodSelectorItem'} onClick={() => this.selectPaymentMethod('payPal')}>PayPal</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="paymentPlanAmount">
                                    <div>
                                        <h3>Purchase {this.props.volume.toLocaleString()} permanent credits</h3>
                                        <h3>for one time payment of ${parseInt(this.props.unitPrice * this.props.volume)}</h3>
                                    </div>
                                </div>

                                {this.state.method === 'card' ?
                                    <div className="upgradeCard">
                                        <div>
                                            <img src={creditCard} alt="Poirot Application Credit Card - https://poirot.app" height="220" width="337"/>
                                            <div className={this.state.method === 'card' ? "cardValidity" : "displayNone"}>{this.state.validThru}</div>
                                            <div className={this.state.method === 'card' ? "cardNumber" : "displayNone"}>{this.state.cardNumber}</div>
                                            <div className={this.state.method === 'card' ? "cardCvc" : "displayNone"}>{this.state.cvcName} <span>▶</span></div>
                                            <div className={this.state.method === 'card' ? "cardCvcNumber" : "displayNone"}>{this.state.cvc}</div>
                                            <div className={this.state.method === 'card' ? "cardName" : "displayNone"}>{this.state.name.toUpperCase()}</div>
                                            {this.state.cardType === 'visa' ? <img className="cardType" src={visa} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'mastercard' ? <img className="cardType" src={mastercard} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'american-express' ? <img className="cardType" src={amex} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'discover' ? <img className="cardType" src={discover} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'diners-club' ? <img className="cardType" src={diners} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'jcb' ? <img className="cardType" src={jcb} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'unionpay' ? <img className="cardType" src={unionpay} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'maestro' ? <img className="cardType" src={maestro} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'elo' ? <img className="cardType" src={elo} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'hipercard' ? <img className="cardType" src={hipercard} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'laser' ? <img className="cardType" src={laser} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                        </div>
                                    </div>
                                    :
                                    <div className="payPalLogoWrapper">
                                        <div>
                                            <img src={payPalLogo} alt="PayPal Logo - https://poirot.app"/>
                                        </div>
                                    </div>
                                }

                            </div>

                            {this.state.method === 'card' ?
                                <form className="paymentDetailsWrapper" onSubmit={this.handlePurchase} id="subscribeForm">
                                    <h1>Payment details</h1>
                                    <div>
                                        <h4>Cardholder name</h4>
                                        <input ref={(input) => { this.nameInput = input; }} name="name" type="text" value={this.state.name} onChange={this.handleInputChange} placeholder="Full name" autoComplete="cc-name" autoFocus/>
                                    </div>
                                    <div>
                                        <h4>Card number</h4>
                                        <Cleave placeholder="Enter your credit card number" options={{creditCard: true}} onChange={this.handleInputChange} name="cardNumber" value={this.state.cardNumber} autoComplete="cc-number"/>
                                    </div>
                                    <div className="paymentShortDetails">
                                        <div>
                                            <h4>Valid through</h4>
                                            <Cleave placeholder="MM/YY" options={{date: true, datePattern: ['m', 'y']}} name="validThru" value={this.state.validThru} onChange={this.handleInputChange} autoComplete="cc-exp"/>
                                        </div>
                                        <div>
                                            <h4>{this.state.cvcName}</h4>
                                            <input type="text" name="cvc" value={this.state.cvc} onChange={this.handleInputChange} placeholder="Code" autoComplete="cc-csc"/>
                                        </div>
                                        <div>
                                            <h4>Postal code</h4>
                                            <input name="postalCode" type="text" value={this.state.postalCode} onChange={this.handleInputChange} placeholder="Zip"/>
                                        </div>
                                    </div>
                                    <div className="paymentActionWrapper">
                                        <div><button className="cancelButton" type="button" onClick={(event) => this.props.togglePurchaseCreditsVisibility(event)}>Cancel</button></div>
                                        <div><button className="defaultButtonBlue" type="submit" form="subscribeForm">Purchase</button></div>
                                    </div>
                                </form>
                            :
                                <div className="paymentDetailsWrapper">
                                   <div className="paymentActionWrapper creditsPurchase">
                                        <div><button className="cancelButton" onClick={() => this.props.togglePurchaseCreditsVisibility(this.props.selectedPlan)}>Cancel</button></div>
                                        <div><button className="defaultButtonBlue" onClick={this.initiatePayPalPayment}>Continue to PayPal</button></div>
                                    </div>
                                </div>
                            }
                    </div>
                </div>       
            </React.Fragment>
        )
    }
};

export default PurchaseCreditsFormNewCard;