import React, { Component } from "react";
import axios from 'axios';

let settings = require("../../settings");
let authentication = require("../../authentication");

class ApiCode extends Component {
	constructor(props) {
		super(props)

        this.state=({
            webhookInputVisible: false,
            webhook: ""
        })

		this.handleRegenerateAPIKey = this.handleRegenerateAPIKey.bind(this)
		this.handleRemoveAPIKey = this.handleRemoveAPIKey.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.toggleWebhookInput = this.toggleWebhookInput.bind(this)

        this.handleWebhookAddition = this.handleWebhookAddition.bind(this)
        this.handleWebhookRemoval = this.handleWebhookRemoval.bind(this)
	}

    handleWebhookRemoval(event) {
        event.preventDefault();

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const headers = { Authorization: "Bearer " + tokenHolder.token };

        axios
            .delete(settings.API_HOST + "/user/webhook", { headers })
            .then(function (response) {
                if (response.status === 200) {
                    self.setState({
                        webhookInputVisible: false,
                        webhook: ""
                    });
                    self.props.refreshUserData();
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                console.log("ERROR: ", error.response)
                self.props.createNotification(
                    "error",
                    "Internal server error.",
                    "Error",
                    6000
                );
            });
    }

    handleWebhookAddition(event) {
        event.preventDefault();

        if (this.state.webhook === "") {
            this.props.createNotification(
                "error",
                "Please enter a valid webhook url.",
                "Error - webhook",
                6000
            );
            return;
        }

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const requestData = {
            url: this.state.webhook,
        };

        axios
            .post(settings.API_HOST + "user/webhook", requestData, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.setState({
                        webhookInputVisible: false,
                        webhook: ""
                    });
                    self.props.refreshUserData();
                    self.props.createNotification(
                        "success",
                        "Webhook endpoint saved.",
                        "Webhook endpoint",
                        7000
                    );
                } else {
                    self.props.createNotification(
                        "error",
                        "An error occurred while trying save webhook endpoint url",
                        "Webhook endpoint",
                        7000
                    );
                }
            })
            .catch(function (error) {
                    self.props.createNotification(
                        "error",
                        "An error occurred while trying save webhook endpoint url",
                        "Webhook endpoint",
                        7000
                    );
            });
    }

    toggleWebhookInput() {
        this.setState({
            webhookInputVisible: !this.state.webhookInputVisible
        })
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    copyToClipboard(str) {
        if (str !== "") {
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.props.createNotification(
                    "success",
                    "Access token copied to clipboard.",
                    "Access token",
                    7000
                );
        }
    }

	handleRegenerateAPIKey(event) {

		event.preventDefault()

		let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

        axios
            .get(settings.API_HOST + "user/apikey", {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                	self.props.createNotification(
                        "success",
                        "API token generated",
                        "Success - API token",
                        6000
                    );
                    self.props.refreshUserData();
                } else {
                    self.props.createNotification(
                        "error",
                        "Error occurred generating new API token.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
	}

	handleRemoveAPIKey(event) {

		event.preventDefault()

		let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

        axios
            .delete(settings.API_HOST + "user/apikey", {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                	self.props.createNotification(
                        "success",
                        "API token removed",
                        "Success - API token",
                        6000
                    );
                    self.props.refreshUserData();
                } else {
                    self.props.createNotification(
                        "error",
                        "Error occurred removing an API token.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
	}

	render() {

		return (
            <React.Fragment>
                {this.props.enabled ?

        			<div className="apiCode">
        				<div><h1>API access</h1></div>
        				<div className="apiCodeRegenerateWrapper">
        					<div>
        						<h3>Personal access token</h3>
        						<h5>Personal access token functions like a combined name and password for API Authentication. Generate a token to access the Poirot API.</h5>
        					</div>
        					<div className="apiCodeRegenerateAction">
        						<div className={(typeof this.props.userData.apiKey === "undefined" ? "personalAccessToken" : "personalAccessToken clickable")} onClick={() => this.copyToClipboard(typeof this.props.userData.apiKey === "undefined" ? "" : this.props.userData.apiKey)}>{this.props.userData.apiKey !== undefined ? (<div className="tokenKey">{this.props.userData.apiKey}</div>) : 'API token not generated'}</div>
        						{this.props.userData.apiKey !== undefined ? (
        																	<div className="apiCodeRegenerateActionButtons">
        																		<button className="defaultButtonGreen" onClick={this.handleRegenerateAPIKey}>Regenerate token</button>
        																		<button className="defaultButtonRed" onClick={this.handleRemoveAPIKey}>Remove API token</button>
        																	</div>
        																	)
        																	:
        																	(
        																	<div className="apiCodeRegenerateActionButtons">
        																		<button className="defaultButtonGreen" onClick={this.handleRegenerateAPIKey}>Generate new token</button>
        																	</div>
        																	) 
        						}
        					</div>
        				</div>
                        {this.props.userData.apiKey !== undefined ?

                            <div className="apiCodeRegenerateWrapperEndpoint">
                                <div>
                                    <h3>Webhook endpoint</h3>
                                </div>
                                <div className="apiCodeRegenerateActionEndpoint">
                                    {
                                        typeof this.props.userData.apiWebhook === 'undefined' ?
                                            <React.Fragment>
                                                {
                                                    this.state.webhookInputVisible ?
                                                    <React.Fragment>
                                                        <div className="apiCodeRegenerateActionEndpointInput">
                                                            <input
                                                                autoFocus
                                                                name="webhook"
                                                                type="text"
                                                                placeholder="Input endpoint url"
                                                                value={this.state.webhook}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="apiCodeRegenerateActionEndpointButton">
                                                                <button className="defaultButtonGreen" onClick={(event) => this.handleWebhookAddition(event)} >Add webhook</button>
                                                                <button className="cancelButton" onClick={()=>this.toggleWebhookInput()}>Cancel</button>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <div className="personalAccessTokenEndpoint">Webhook endpoint not defined</div>
                                                        <div className="apiCodeRegenerateActionEndpointButton">
                                                            <button className="defaultButtonGreen" onClick={()=>this.toggleWebhookInput()}>Add webhook endpoint</button>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        :
                                            <React.Fragment>
                                                <div className="personalAccessTokenEndpoint">{this.props.userData.apiWebhook}</div>
                                                <div className="apiCodeRegenerateActionEndpointButton">
                                                    <button className="defaultButtonRed" onClick={(event) => this.handleWebhookRemoval(event)}>Remove webhook endpoint</button>
                                                </div>
                                            </React.Fragment>
                                    }
                                    <div>
                                    </div>
                                </div>
                            </div>
                        :
                            <React.Fragment />
                        }

        			</div>
                :

                    <div className="apiCode">
                        <div><h1>API access</h1></div>
                        <div className="apiCodeRegenerateWrapper">
                            <div>
                                <h3>API usage is not available on your current plan.</h3>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
		)
	}
}

export default ApiCode;