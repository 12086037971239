import React, { Component } from "react";
import axios from 'axios';

import PayoutAddressRemoval from './payoutAddressRemoval'
import LoaderAnimation from '../../LoaderAnimation';

let icons = require("../../appComponents/icons");
let settings = require("../../settings");
let authentication = require("../../authentication");

class PayoutDetails extends Component {
	constructor(props) {
		super(props)

		this.state={
			payPalPaymentAddress: "",
			editModeActive: false,
			emailAddressRemoveIsVisible: false,
			processing: false,
		}

		this.handleInputChange = this.handleInputChange.bind(this)
		this.toggleEditMode = this.toggleEditMode.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.toggleEmailAddressRemove = this.toggleEmailAddressRemove.bind(this)
		this.handleEmailAddressDelete = this.handleEmailAddressDelete.bind(this)
	}

	toggleEmailAddressRemove() {
		this.setState({emailAddressRemoveIsVisible: !this.state.emailAddressRemoveIsVisible, processing: false})
	}

	handleEmailAddressDelete(event) {
		event.preventDefault()

		this.setState({processing: true})

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

        const headers = { Authorization: "Bearer " + tokenHolder.token };

        axios
            .delete(settings.API_HOST + "user/payoutaddress", { headers })
            .then(function (response) {
                if (response.status === 200) {
                    self.props.createNotification(
                        "success",
                        "Payout email address has been successfully removed.",
                        "Success",
                        7000
                    );
                    self.props.refreshUserData();
                    self.toggleEmailAddressRemove();
                } else {
                	self.toggleEmailAddressRemove();
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
            	self.toggleEmailAddressRemove();
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
	}

	handleSubmit(event) {
		event.preventDefault();

		if (this.state.payPalPaymentAddress === "") {
			this.props.createNotification(
				"error",
				"Please enter a valid email address.",
				"Error - Email Address",
				6000
			);
			return;
		}

		if (!/^[a-z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/.test(this.state.payPalPaymentAddress)) {
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }


		if (this.state.payPalPaymentAddress.toLowerCase() === this.props.userData.payPalPaymentAddress) {
			this.toggleEditMode();
			return;
		}

		var self = this;

		this.setState({processing: true})

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		const requestData = {
			email: this.state.payPalPaymentAddress,
		};

		axios
			.post(settings.API_HOST + "user/payoutaddress", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.toggleEditMode()
					self.setState({"processing": false})
					self.props.refreshUserData();
					self.props.createNotification(
						"success",
						"You have successfully assigned a PayPal payout email address",
						"Success - PayPal email address",
						7000
					);
				} else {
					self.setState({"processing": false})
					self.props.createNotification(
						"error",
						"An error occurred while trying to assign new PayPal payout email address.",
						"Error - PayPal email address",
						7000
					);
				}
			})
			.catch(function (error) {
				self.setState({"processing": false})
				self.props.createNotification(
					"error",
					"An error occurred while trying to assign new PayPal payout email address.",
					"Error - PayPal email address",
					7000
				);
			});
	}

	toggleEditMode() {
		var email = this.props.userData.payPalPaymentAddress

		this.setState({
			payPalPaymentAddress: email,
			editModeActive: !this.state.editModeActive,
			processing: false
		})
	}

	handleInputChange(event) {
		const target = event.target;
		var value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	render() {

		return (
			<React.Fragment>
				{this.state.emailAddressRemoveIsVisible ? <PayoutAddressRemoval email={this.state.payPalPaymentAddress}
																		 toggleEmailAddressRemove={this.toggleEmailAddressRemove}
																		 handleEmailAddressDelete={this.handleEmailAddressDelete}
																		/> : <React.Fragment />}
				{this.state.processing ? <LoaderAnimation /> : <React.Fragment />}
				<div className="payoutDetailsWrapper">
					<div>
						<h1>Payout account details</h1>
					</div>
					<div className="payoutDetails">
						<h4>
							Enter an email address associated with your PayPal payment account.
						</h4>
					</div>
					<form className="payPalAccountAddressWrapper" onSubmit={(event) => this.handleSubmit(event)}>
						<div>
							{this.state.editModeActive ? 
								<input autoFocus type="text" name="payPalPaymentAddress" value={this.state.payPalPaymentAddress} placeholder="PayPal email address" onChange={this.handleInputChange}/>
								:
								<React.Fragment />
							}
							{(typeof this.props.userData.payPalPaymentAddress === "undefined" && !this.state.editModeActive) ?
								<div className="payPalPaymentEmailAddress">PayPal email address not defined</div>
								:
								<React.Fragment />
							}
							{(typeof this.props.userData.payPalPaymentAddress !== "undefined" && !this.state.editModeActive) ?
								<div className="payPalPaymentEmailAddress">{this.props.userData.payPalPaymentAddress}</div>
								:
								<React.Fragment />
							}
						</div>
						<div
							className={
								(typeof this.props.userData.payPalPaymentAddress !== "undefined" && !this.state.editModeActive)
									? "editIcon"
									: "displayNone"
							}			
							onClick={this.toggleEmailAddressRemove}>
							{icons.trashCan()}
						</div>
						<div>
							{this.state.editModeActive ? <button type="submit" className="defaultButtonBlue">Update PayPal address</button> : <React.Fragment />}
							{(typeof this.props.userData.payPalPaymentAddress === "undefined" && !this.state.editModeActive) ? <button className="defaultButtonBlue" onClick={this.toggleEditMode}>Add PayPal address</button> : <React.Fragment />}
							{(typeof this.props.userData.payPalPaymentAddress !== "undefined" && !this.state.editModeActive) ? <button className="defaultButtonBlue" onClick={this.toggleEditMode}>Change PayPal address</button> : <React.Fragment />}
							{this.state.editModeActive ? <button className="cancelButton" onClick={this.toggleEditMode}>Cancel</button> : <React.Fragment />}
						</div>
					</form>
				</div>
			</React.Fragment>
		)
	}
}

export default PayoutDetails;