import React, { Component } from "react";
import axios from "axios";

let settings = require("../../settings");
let authentication = require("../../authentication");

class TagSelector extends Component {

	handleTagAssignment(id) {

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		if (typeof this.props.tagSelectedTaskId === 'undefined' || typeof this.props.tagSelectedEmail === 'undefined') {
			return;
		}

		const requestData = {
			id: id,
			taskId: this.props.tagSelectedTaskId,
			email: this.props.tagSelectedEmail
		};

		axios
			.post(settings.API_HOST + "tag/assign", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.props.hideTagSelector();
					self.props.refreshTaskData();
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while trying to assign a label",
						"Error - Tag Assign",
						7000
					);
				}
			})
			.catch(function (error) {
					self.props.createNotification(
						"error",
						"An error occurred while trying to assign a label",
						"Error - Tag Assign",
						6000
					);
			});

	}


	render() {

		let tags = this.props.activeTeamData === "unavailable" ? this.props.userTags : this.props.activeTeamData.tags
        let tag = tags.map((item, index) => {
        	if (typeof this.props.tagSelectedTaskLabel === 'undefined' || !this.props.tagSelectedTaskLabel.includes(item.id)) {
	            return (
	            	<div className="tagSelectorTag" key={index} onClick={() => this.handleTagAssignment(item.id)}>

	            		<div>
							<svg
								height="14"
								width="14"
							>
								<circle
									cx="7"
									cy="7"
									r="7"
									fill={"#" + item.color}
								/>
							</svg>
						</div>

	            		{item.label}
	            	</div>
	            )
	        } else {
	        	return (
	        		<React.Fragment key={index}/>
	        	)
	        }
        })

		return (
			<div className="tagSelector">
				<div>
					{tag}
				</div>
			</div>
		);
	}
}

export default TagSelector;