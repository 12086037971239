import React, { Component } from "react";
let settings = require('../settings')

let icons = require('./icons')

class CurrentAccountPlan extends Component {
	render() {
		// console.log("currentAccountPlan: ", this.props)
		let mantraLogo = settings.CDN_HOST + 'images/saasmantra-logo.png'

		var self = this
		const currentProductPlan = this.props.currentProduct.plans.find(function(activePlan) {
			return(activePlan.id === self.props.userData.plan)
		})
		// console.log("currentProductPlan: ", currentProductPlan)
		return (
			<div className="currentAccountPlanWrapper">
				<div className="currentAccountPlan">
					<h1>You are currently on<br />{this.props.currentProduct.name} plan</h1>
					<div className="currentAccountPlanFeatures">
						{this.props.userData.ltd === "mantra" ?
						<div className="providerImage"><img src={mantraLogo} alt="SaaS Mantra"/></div>
						:
						<div className="planPrice">{(currentProductPlan.currency === 'usd') ? '$' : currentProductPlan.currency}{(currentProductPlan.unitAmount/100).toLocaleString()}<span>/{currentProductPlan.recurring.interval}</span></div>
						}
						<div><hr /></div>
						<div className="creditsFeature"><div className="boldFeature">{(this.props.currentProduct.metadata.lookupCredits).toLocaleString()}</div><div>Lookup credits</div></div>
						<div className="creditsFeature"><div className="boldFeature">{(this.props.currentProduct.metadata.verificationCredits).toLocaleString()}</div><div>Verification credits</div></div>
						<div><hr /></div>
						<div className="imageFeature"><div>Teams</div><div>{this.props.currentProduct.metadata.teams ? icons.checkCircle() : icons.timesCircle()}</div></div>
						<div className="imageFeature"><div>Bulk usage</div><div>{this.props.currentProduct.metadata.bulk ? icons.checkCircle() : icons.timesCircle()}</div></div>
						<div className="imageFeature"><div>API usage</div><div>{this.props.currentProduct.metadata.api ? icons.checkCircle() : icons.timesCircle()}</div></div>
						<div className="imageFeature"><div>CSV export</div><div>{this.props.currentProduct.metadata.csv ? icons.checkCircle() : icons.timesCircle()}</div></div>
					</div>
				</div>
			</div>
		)
	}
}

export default CurrentAccountPlan;
