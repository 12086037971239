import React, { Component } from "react";
import {
	NotificationContainer,
	NotificationManager,
} from "react-notifications";

import AppRouter from "./AppRouter";
import LoaderAnimation from "./LoaderAnimation";

let authentication = require("./authentication");

class AppLoader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: null,
		};

		this.toggleAuthentication = this.toggleAuthentication.bind(this);
	}

	componentDidMount() {
		var self = this;

		authentication.isAuthenticated().then(function (response) {
			self.setState({
				isAuthenticated: response,
			});
		});
	}

	toggleAuthentication() {
		var self = this;

		if (this.state.isAuthenticated === null) {
			return;
		}

		if (this.state.isAuthenticated === true) {
			authentication.removeAccessToken();

			self.setState({
				isAuthenticated: false,
			});
		} else {
			self.setState({
				isAuthenticated: true,
			});
		}
	}

	createNotification = (type, message, title, timeout) => {
		switch (type) {
			case "info":
				NotificationManager.info(message, title, timeout);
				break;
			case "success":
				NotificationManager.success(message, title, timeout);
				break;
			case "warning":
				NotificationManager.warning(message, title, timeout);
				break;
			case "error":
				NotificationManager.error(message, title, timeout);
				break;
			default:
				break;
		}
	};

	render() {
		return (
			<React.Fragment>
				{this.state.processing ? <LoaderAnimation /> : <React.Fragment/>}
				<NotificationContainer />
				<AppRouter
					createNotification={this.createNotification}
					isAuthenticated={this.state.isAuthenticated}
					toggleAuthentication={this.toggleAuthentication}
				/>
			</React.Fragment>
		);
	}
}

export default AppLoader;
