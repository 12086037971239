import React, { Component } from "react";
import axios from 'axios';

let settings = require("../../settings");
let authentication = require("../../authentication");

class ResetPassword extends Component {

	constructor(props) {
		super(props)

		this.state=({
			currentPassword: '',
			newPassword: '',
			newPasswordConfirmation: '',
			inEditMode: false
		})

		this.toggleEditMode = this.toggleEditMode.bind(this);
		this.handlePasswordReset = this.handlePasswordReset.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	toggleEditMode() {

		if (this.state.inEditMode) {
			this.setState({
				currentPassword: '',
				newPassword: '',
				newPasswordConfirmation: ''
			})
		}

		this.setState({
			inEditMode: !this.state.inEditMode
		})

	}

	handleInputChange(event) {

        const target = event.target;
        var value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handlePasswordReset(event) {
    	event.preventDefault();

		 if (this.state.currentPassword === "" || this.state.newPassword === "" || this.state.newPasswordConfirmation === "") {
            this.props.createNotification('error', 'All fields are required.', 'Error - Password', 6000);
            return
        }

        if (!/^([a-zA-Z0-9@*#._$&!%-]{6,128})$/.test(this.state.currentPassword) || !/^([a-zA-Z0-9@*#._$&!%-]{6,128})$/.test(this.state.newPassword) || !/^([a-zA-Z0-9@*#._$&!%-]{6,128})$/.test(this.state.newPasswordConfirmation)) {
            this.props.createNotification('error', 'Please enter a valid password. Minimum length for the password is 6 characters. Valid characters are A-Z, a-z, 0-9 and @*#._$&!%-', 'Error - Password', 7000);
            return
        }

        if (this.state.newPassword !== this.state.newPasswordConfirmation) {
        	this.props.createNotification('error', "Confirmation password field doesn's match new password field", 'Error - Password Matching', 7000);
            return
        }

        if (this.state.currentPassword === this.state.newPassword) {
        	this.props.createNotification('error', "New password can't match the old one.", 'Error - Password Matching', 7000);
            return
        }

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {			
			return;
		}

        const requestCredentials = {
            "currentPassword": this.state.currentPassword,
            "newPassword": this.state.newPassword
        };

		axios
			.patch(settings.API_HOST + "user/password", requestCredentials, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.props.createNotification(
                        "success",
                        "User password has been changed.",
                        "User Profile Updated",
                        7000
                    );
					self.toggleEditMode();
					
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while updating user profile data. Please try again later.",
						"Error - User Profile Update",
						7000
					);
					
				}
			})
			.catch(function (error) {
					self.props.createNotification(
						"error",
						"An error occurred while updating user profile data. Please try again later.",
						"Error - User Profile Update",
						7000
					);
					
			});
    }

	render() {

		return (
			<div className="resetPassword">
				<div><h1>Password</h1></div>
				<div className="passwordChangeWrapper">
					<form onSubmit={this.handlePasswordReset}>
						<div>
							{this.state.inEditMode ? <input name="currentPassword" type="password" placeholder="Enter current password" value={this.state.currentPassword} onChange={this.handleInputChange} autoFocus/> : <React.Fragment /> }
							{!this.state.inEditMode ? <h2>*************</h2> : <React.Fragment />}
						</div>
						<div>
							{this.state.inEditMode ? <input name="newPassword" type="password" placeholder="Create new password" value={this.state.newPassword} onChange={this.handleInputChange}/> : <React.Fragment /> }
						</div>
						<div>
							{this.state.inEditMode ? <input name="newPasswordConfirmation" type="password" placeholder="Confirm new password" value={this.state.newPasswordConfirmation} onChange={this.handleInputChange}/> : <React.Fragment /> }
						</div>
						<div>
							{!this.state.inEditMode ? <button className="defaultButtonGreen" onClick={this.toggleEditMode}>Reset password</button> : <React.Fragment /> }
							{this.state.inEditMode ? <button className="defaultButtonGreen" type="submit">Update password</button> : <React.Fragment /> }
							{this.state.inEditMode ? <button className="cancelButton" onClick={this.toggleEditMode}>Cancel</button> : <React.Fragment /> }
						</div>
					</form>
				</div>
			</div>
		)
	}
}

export default ResetPassword;
