import React, { Component } from "react";

let settings = require("../settings");
let icons = require("../appComponents/icons");

class BulkActions extends Component {
	render() {
		let bulkUsageFiles = settings.CDN_HOST + "images/BulkUsageFiles.svg";
		let emailFinderTableSample =
			settings.CDN_HOST + "images/EmailFinderTableSample.svg";
		let searchAndVerifyTableSample =
			settings.CDN_HOST + "images/SearchAndVerifyTableSample.svg";
		let poirotBulkJsonSample =
			settings.CDN_HOST + "images/PoirotBulkJsonFileSample.svg";

		return (
			<div className="bulkActionsWrapper">
				<div className="bulkActionsStoryWrapper">
					<div className="bulkActionsStory">
						<div>
							<h1>
								How to prepare your data for
								<br />
								bulk search & verification of email addresses?
							</h1>
						</div>
						<div>
							<img
								src={bulkUsageFiles}
								alt="Poirot bulk usage files - https://poirot.app"
								width="460" height="340"
							/>
						</div>
						<div>
							<p>
								Poirot App can take .CSV, .XLSX and .JSON files
								to perform either email finding or verification
								of email addresses. You can perform bulk finding
								of email addresses by providing tables
								consisting of your prospects names and their
								respective company name or company's URL
								address. If you are searching for all emails
								publicly available related to a particular
								domain, you can provide a list containing either
								a domain name or target company name To further
								enhance the chance of finding just right email
								addresses, a company name can be enriched with
								geo-targeting data. When it comes to
								verification of email addresses, a list of bare
								email addresses with no added fields will be
								sufficient. It is of no significance to the
								system if the exporting table contains title and
								header row.
							</p>
							<h2>Email finder table requirements</h2>
							<p>
								A table consisting of two columns is required.
								The first column should contain your prospect's
								full name. Customarily, it's his/her first and
								last name. The legal full name of a person
								usually contains the first two or three names
								with the family name at the end which means that
								if a person has more than three names, all the
								names after the third one will be discarded in
								search attempt. For example, successful searches
								will be performed for names like Ernest
								Hemingway, Charles Dickens, or Herbert George
								Wells. On the other hand, if a search is
								performed using a name like John Ronald Reuel
								Tolkien, the search will be performed using only
								John Ronald Reuel and family name Tolkien will
								be dropped. The second column can take either a
								person's company URL address or company name.
								Company name can further be enhanced by its
								headquarters geo-location. If the company's URL
								address is provided, it doesn't matter if it's
								formated utilizing the prefixes: https or www or
								combination of both or it's just plain simple
								domain name.
							</p>
						</div>
						<div>
							<img
								src={emailFinderTableSample}
								alt="Poirot bulk usage email finder table sample - https://poirot.app"
							/>
						</div>
						<div>
							<h2>
								Domain search & Email verification table
								requirements
							</h2>
							<p>
								Only one column is required to perform a bulk
								search for email addresses associated with a
								target companies list. Likewise, the company
								field used for prospects email finding, domain
								search table row can contain either a domain
								name address or company name with the optional
								geo-location. Note that you need to have a
								sufficient amount of credits remaining for this
								type of search because a search task for each
								company can contain many email addresses. When
								verification of emails is on an errand, a
								single-column table with rows containing email
								addresses is required.
							</p>
						</div>
						<div>
							<img
								src={searchAndVerifyTableSample}
								alt="Poirot bulk usage domain finder & email verification table sample - https://poirot.app"
							/>
						</div>
						<div>
							<h2>Exporting tables to target file format</h2>
							<p>
								Accepted table file formats are .CSV, .XLSX and
								.JSON. Major spreadsheet software including
								Google Sheets, Apple Numbers, or Microsoft Excel
								can save or export to one or more of these file
								formats.
							</p>
							<h2>Using JSON file format</h2>
							<p>
								To use JSON file format, in case of email
								verification and domain search tasks, a single
								value is required whether it is an email address
								or data representing a company (URL address or
								name). The identifier name can be anything:
								email, address, company... For email finding
								purposes, the JSON structure must have two
								fields where the first one represents a person's
								name and the second one its company. Again, the
								naming of those fields is up to the user.
							</p>
						</div>
						<div>
							<img
								src={poirotBulkJsonSample}
								alt="Poirot bulk usage JSON file sample - https://poirot.app"
							/>
						</div>
						<div>
							<h2>Using found and verified email addresses</h2>
							<p>
								After the required file is selected or dropped
								on the marked target zone on screen, depending
								on its content, a task will be generated and
								added to "Email Finder", "Domain Search" or
								"Email Verification" history timeline. Based on
								the file size and the number of rows, a task
								will be updated as the results are coming in.
								You can open a particular task and check its
								data being updated even if the indicator is
								showing that the task is still in progress. Only
								after all of the rows of data are analyzed, a
								task will be marked as completed.
							</p>
						</div>
					</div>
				</div>
				<div className="bulkActionArea">
					<div className="dropZone">
						<div>{icons.cloudUpload()}</div>
						<div>
							<h2>
								Drag and drop .CSV, .XLSX or .JSON file to
								upload
							</h2>
							<h4>
								or <span>browse</span> to choose a file
							</h4>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default BulkActions;
