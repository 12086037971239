import React, { Component } from "react";
import axios from "axios";

let settings = require("../../settings");
let authentication = require("../../authentication");

class TagRemovalSelector extends Component {

	handleTagRemoval() {

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		if (typeof this.props.tagSelectedTaskId === 'undefined' || typeof this.props.tagSelectedEmail === 'undefined' || typeof this.props.tagSelectedTagId === 'undefined') {
			return;
		}

		const requestData = {
			id: this.props.tagSelectedTagId,
			taskId: this.props.tagSelectedTaskId,
			email: this.props.tagSelectedEmail
		};

		axios
			.post(settings.API_HOST + "tag/deassign", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.props.hideTagRemovalSelector();
					self.props.refreshTaskData();
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while trying to deassign a label",
						"Error - Tag Removal",
						7000
					);
				}
			})
			.catch(function (error) {
					self.props.createNotification(
						"error",
						"An error occurred while trying to deassign a label",
						"Error - Tag Removal",
						6000
					);
			});

	}


	render() {
		return (
			<div className="tagRemovalSelector" onClick={() => this.handleTagRemoval()}>
				<div>
					Remove label
				</div>
			</div>
		);
	}
}

export default TagRemovalSelector;