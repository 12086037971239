import React, { Component } from "react";

class BillingEvent extends Component {
	render() {
		return (
			<div className={this.props.index % 2 === 0 ? "billingEvent" : "billingEvent billingEventOdd"}>
				<div className="billingEventDate">
					{this.props.date}
				</div>
				<div className="billingEventNumber">
					{this.props.number}
				</div>
				<div className="billingEventDescription">
					{this.props.description}
				</div>
				<div className="billingEventAmount">
						{this.props.eventType === "debit" ?
							<span>-</span>
							:
							<span>+</span>
						}
						&nbsp;{this.props.amount}
				</div>
				<div className="billingEventAction">
					{(this.props.eventType === "debit" && parseInt(this.props.amount) > 0) ?
						<div><li onClick={() => this.props.fetchInvoicePdf(this.props.number)}>Download PDF</li></div>
						:
						<React.Fragment />
					}
				</div>
			</div>
		);
	}
}

export default BillingEvent;