import React, { Component } from "react";
import axios from 'axios';

import CurrentAccountPlan from '../../appComponents/currentAccountPlan'
import AvailablePlans from '../../appComponents/blocks/availablePlans'
import PurchaseCredits from '../../appComponents/blocks/purchaseCredits'

import PaymentForm from '../../appComponents/paymentForm'
import UpgradePaymentForm from '../../appComponents/upgradePaymentForm'
import DowngradeForm from '../../appComponents/downgradeForm'
import AccountCreditForm from '../../appComponents/accountCreditForm'

import LoaderAnimation from '../../LoaderAnimation';

let settings = require("../../settings");
let authentication = require("../../authentication");

//
// Set time interval to constantly fetch updated user data.
//
var refreshUserDataInterval;
var refreshUserCreditsDataInterval;

class AccountPlans extends Component {
	constructor(props) {
		super(props)

		var processing = false
		var loaderAnimationMessage = undefined

		if (this.props.payPalTokenPath === 'subscription' || this.props.payPalTokenPath === 'permanentpurchased' ) {
			processing = true;
			loaderAnimationMessage = 'Waiting for PayPal to finish processing your request. This may take up to a minute to complete.'
		}

		this.state={
			selectedPlan: null,
			availablePrePaidCredit: null,
			processing: processing,
			loaderAnimationMessage: loaderAnimationMessage,
			payPalTokenPath: this.props.payPalTokenPath,
		}

		this.togglePaymentProcessFormVisibility = this.togglePaymentProcessFormVisibility.bind(this);
		this.refreshUserAccountBalance = this.refreshUserAccountBalance.bind(this);
		this.checkIfUserIsUpdated = this.checkIfUserIsUpdated.bind(this);
		this.checkIfUserPermanentCreditsAreUpdated = this.checkIfUserPermanentCreditsAreUpdated.bind(this);
		this.toggleDowngradeForm = this.toggleDowngradeForm.bind(this);
		this.downgrade = this.downgrade.bind(this);
		this.toggleAccountCreditForm = this.toggleAccountCreditForm.bind(this);
		this.upgradeWithCredit = this.upgradeWithCredit.bind(this);

	}

	componentDidMount() {
		var self = this;
		if (this.props.payPalTokenPath === 'subscription') {
			var activePlan = this.props.userData.plan
			this.checkIfUserIsUpdated(activePlan)
            refreshUserDataInterval = setInterval(function () {
                    self.checkIfUserIsUpdated(activePlan);
                }, 2000);   
		} else if (this.props.payPalTokenPath === 'subscriptionfailed' || this.props.payPalTokenPath === 'permanentfailed') {
			this.props.createNotification(
                "error",
                "There was an error processing your payment. Please try again using different payment method.",
                "Error - Payment Failed",
                6000
            );
		} else if (this.props.payPalTokenPath === 'permanentpurchased') {
			var permanentCredits = this.props.userData.permanentCredits
			this.checkIfUserPermanentCreditsAreUpdated(permanentCredits)
			refreshUserCreditsDataInterval = setInterval(function () {
                    self.checkIfUserPermanentCreditsAreUpdated(permanentCredits);
                }, 2000);
		}
	}

	upgradeWithCredit(event) {
		event.preventDefault()

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {  
            return;
        }

        const requestCredentials = {
            priceId: this.state.selectedPlan.selected.id,
        }


        this.setState({processing: true})

        axios
            .post(settings.API_HOST + "user/upgradesubscription", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    switch (response.data.status) {

                        case "succeeded":
                            refreshUserDataInterval = setInterval(function () {
                                self.checkIfUserIsUpdated();
                            }, 2000);
                            break;

                        default:
                            self.setState({processing: false, selectedPlan: null})
                            self.props.createNotification(
                                "error",
                                "Internal server error.",
                                "Error",
                                6000
                            );
                            break;
                    }
                    
                } else {
                    self.setState({processing: false, selectedPlan: null})
                    self.props.createNotification(
                        "error",
                        "There was an error processing your payment. Please try again using different payment method.",
                        "Error - Payment Failed",
                        6000
                    );
                    return;
                }
            })
            .catch(function (error) {
                self.setState({processing: false})
                self.props.createNotification(
                    "error",
                    "There was an error processing your payment. Please try again using different payment method.",
                    "Error - Payment Failed",
                    6000
                );
                return;
            });
	}

	toggleAccountCreditForm(event) {
		event.preventDefault()

		this.setState({selectedPlan: null})
	}

	toggleDowngradeForm(event) {
		event.preventDefault()

		this.setState({selectedPlan: null})
	}

	downgrade(event) {
		event.preventDefault() 

		this.setState({processing: true})

		let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

        const requestData = {
            priceId: this.state.selectedPlan.selected.id
        };

        axios
			.post(settings.API_HOST + "user/downgradesubscription", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				self.setState({processing: false})
                if (response.status === 200) {
                    self.props.createNotification(
                        "success",
                        "Your account plan will be downgraded at the end of current billing period.",
                        "Account Plan Updated",
                        7000
                    );
                    self.setState({selectedPlan: null});
                    self.props.refreshUserData();
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
            	self.setState({processing: false})
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
	}


	checkIfUserIsUpdated(activePlan) {
        var self = this;
        authentication.getUserData().then(function (userData) {
            if (userData !== null || userData !== "unavailable") {

                if (activePlan !== userData.plan) {
                    self.props.createNotification(
                        "success",
                        "Your account plan has been upgraded. We'll send you an email with your order details.",
                        "Success - Payment Successful",
                        6000
                    )
                    self.setState({
                                    processing: false,
                                    loaderAnimationMessage: undefined,
                                    selectedPlan: null
                                })
                    clearInterval(refreshUserDataInterval);
                    self.props.refreshUserData()
                    self.props.setPayPalTokenPathToNull();
                }  
            }
        });
    }

	checkIfUserPermanentCreditsAreUpdated(permanentCredits) {
        var self = this;
        authentication.getUserData().then(function (userData) {
            if (userData !== null || userData !== "unavailable") {
                if (permanentCredits !== userData.permanentCredits) {
                    self.props.createNotification(
                        "success",
                        "You have successfully added permanent credits to your account.",
                        "Success - Payment Successful",
                        6000
                    )
                    self.setState({
                                    processing: false,
                                    loaderAnimationMessage: undefined,
                                    selectedPlan: null
                                })
                    clearInterval(refreshUserCreditsDataInterval);
                    self.props.refreshUserData()
                    self.props.setPayPalTokenPathToNull();
                }  
            }
        });
    }

    componentWillUnmount() {
        clearInterval(refreshUserDataInterval);
        clearInterval(refreshUserCreditsDataInterval);
    }

	togglePaymentProcessFormVisibility(selectedPlan) {
		if (this.state.selectedPlan === selectedPlan) {
			this.setState({selectedPlan: null, availablePrePaidCredit: null, processing: false, loaderAnimationMessage: undefined})
		} else {
			this.setState({processing: true})
			this.refreshUserAccountBalance(selectedPlan);
		}
	}

	refreshUserAccountBalance(selectedPlan) {
		var self = this;
		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {			
			return;
		}

		axios
			.get(settings.API_HOST + "user/accountbalance", {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				self.setState({processing: false, loaderAnimationMessage: undefined})
				if (response.status === 200) {
					self.setState({
						availablePrePaidCredit: response.data.amount,
						selectedPlan: selectedPlan,
					})					

				} else {
					self.props.createNotification(
						"error",
						"An error occurred while fetching current account balance.",
						"Error - User Account Balance",
						7000
					);
					
				}
			})
			.catch(function (error) {
				self.setState({processing: false, loaderAnimationMessage: undefined})
				self.props.createNotification(
					"error",
					"An error occurred while fetching current account balance.",
					"Error - User Account Balance",
					7000
				);	
			});
	}

	render() {
		var self = this

		///
		/// Determine current product pricing
		///
		const currentProductPlan = this.props.currentProduct.plans.find(function(activePlan) {
			return (activePlan.id === self.props.userData.plan)
		})

		///
		/// Check if user has valid payment methods
		///
		var date = new Date();
        var month = date.getUTCMonth() + 1;
        var year = date.getUTCFullYear();
		const hasValidPaymentMethod = (this.props.userData.paymentMethods.find(function(card) {
            return (!(card.expMonth < month && card.expYear <= year) && !(card.expYear < year)) 
            })) === undefined ? false : true;

		var content = () => {
			if (this.state.selectedPlan !== null && this.state.availablePrePaidCredit >= this.state.selectedPlan.selected.unitAmount / 100 && this.state.selectedPlan.selected.unitAmount > currentProductPlan.unitAmount ) {
				return <AccountCreditForm upgradeWithCredit={this.upgradeWithCredit}
										  toggleAccountCreditForm={this.toggleAccountCreditForm}
										  currentProductName={this.props.currentProduct.name}
										  newPlanName={this.state.selectedPlan.name}/>
			} else if (this.state.selectedPlan !== null && this.state.selectedPlan.metadata.lookupCredits < self.props.userData.defaultMonthlyLookupCredits) {
				return <DowngradeForm currentProductName={this.props.currentProduct.name}
									  newPlanName={this.state.selectedPlan.name}
									  toggleDowngradeForm={this.toggleDowngradeForm}
									  downgrade={this.downgrade}
									  />
			} else {

				if (this.state.selectedPlan !== null && !hasValidPaymentMethod) {
					return (
						<PaymentForm 	createNotification={this.props.createNotification}
										refreshUserData={this.props.refreshUserData}
										togglePaymentProcessFormVisibility={this.togglePaymentProcessFormVisibility}
										userData={this.props.userData}
										selectedPlan={this.state.selectedPlan}
										upgrade={this.state.selectedPlan.metadata.lookupCredits > self.props.userData.defaultMonthlyLookupCredits}/>
					)
				} else if (this.state.selectedPlan !== null && hasValidPaymentMethod) {
					return (
						<UpgradePaymentForm createNotification={this.props.createNotification}
											refreshUserData={this.props.refreshUserData}
											togglePaymentProcessFormVisibility={this.togglePaymentProcessFormVisibility}
											userData={this.props.userData}
											selectedPlan={this.state.selectedPlan}
											upgrade={this.state.selectedPlan.metadata.lookupCredits > self.props.userData.defaultMonthlyLookupCredits}/>
					) 
				}
			}
		}

		return (
			<React.Fragment>
				{this.state.processing ? <LoaderAnimation message={this.state.loaderAnimationMessage}/> : <React.Fragment />}
				{content()}
				<div className="accountPlansWrapper">
					<div className="accountPlans">
						{<CurrentAccountPlan currentProduct={this.props.currentProduct}
											userData={this.props.userData}/>}
						<div className="planSelectionWrapper">
							<div className="planSelection">
								<AvailablePlans currentProduct={this.props.currentProduct}
												plansData={this.props.plansData}
												togglePaymentProcessFormVisibility={this.togglePaymentProcessFormVisibility}/>
								<div><hr /></div>
								<PurchaseCredits createNotification={this.props.createNotification}
												 userData={this.props.userData} 
												 refreshUserData={this.props.refreshUserData}
												 permanentCreditsData={this.props.permanentCreditsData}/>
								<div><hr /></div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
			
	}
}

export default AccountPlans;
