import React, { Component } from "react";

import Gravatar from './gravatar.js';

let icons = require("./icons");

class ContactItem extends Component {
	
	render() {

		return (
			<div className="contactItemWrapper">
			
				<div className="contactItem">
					<div className="contactAvatar">
						<Gravatar userEmailAddress={this.props.item.email} defaultImage="https%3A%2F%2Fcdn.poirot.app%2Fimages%2Fperson-circle.png" imageSize="26"/>
					</div>
					<div className=""><a href={"mailto:" + this.props.item.email + "?&Content-Type=text/html&body=%0D%0A%0D%0ASent with help from www.poirot.app"}>{this.props.item.email}</a></div>
					{
						this.props.item.verified ?
						<div className="verificationStatusFail"><div>{icons.checkCircle()}</div></div> 
							: 
						<div className="verificationStatusPass"><div>{icons.timesCircle()}</div></div>
					}
					<div className="">{this.props.item.name}</div>
					<div className="">{this.props.item.position}</div>				
					
				</div>

				
			</div>
		)
	}
}

export default ContactItem;