import React, { Component } from "react";
import axios from "axios";

import LoaderAnimation from "../LoaderAnimation";

import HelperPanel from "../appComponents/helperPanel";
import TaskFilter from "../appComponents/taskFilter";
import Task from "../appComponents/task";

let settings = require("../settings");
let authentication = require("../authentication");

let refreshListInterval;

class DomainSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			tasks: [],
			loadingProcessActive: true,
			maxPage: undefined,
			initialPage: 1,
			filter: "",
		};

		this.fetchDomainSearchTasks = this.fetchDomainSearchTasks.bind(this);
		this.handleDomainSearch = this.handleDomainSearch.bind(this);
		// this.toggleTask = this.toggleTask.bind(this);
		this.setInitialPage = this.setInitialPage.bind(this);
		this.setFilter = this.setFilter.bind(this);

		this.input = React.createRef();
	}

	setFilter(value) {
		var self = this;
		this.setState({
			filter: value
		},() => {
			self.fetchDomainSearchTasks();
		})
	}

	setInitialPage(page) {
		var self = this;
		if (page !== this.state.initialPage) {
			this.setState({
				initialPage: page
			}, () => {
				self.fetchDomainSearchTasks();
			})
		}
	}

	componentDidMount() {
		this.fetchDomainSearchTasks();
	}

	componentWillUnmount() {
        clearInterval(refreshListInterval);
    }

	fetchDomainSearchTasks() {
		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		const requestData = {
			start: this.state.initialPage * this.props.resultsPerPage - this.props.resultsPerPage + 1,
			total: this.props.resultsPerPage,
		};

		if (this.state.filter.length > 2) {
			requestData.filter = this.state.filter
		}

		axios
			.post(settings.API_HOST + "domainlookups", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				self.setState({ loadingProcessActive: false });
				if (response.status === 200) {

					if (response.data.length > 0) {
						let maxPage = Math.ceil(response.data[0].totalTasks / self.props.resultsPerPage)
						self.setState({
							maxPage: maxPage
						})
					}

					if (self.state.tasks !== response.data) {
						self.props.refreshUserData();
						self.props.refreshActiveTeamData();
					}

					self.setState({
						tasks: response.data,
					}, () => {
						const hasProcessingValue = self.state.tasks.some(el => el.status === 'pending' || el.status === 'processing');
						if (hasProcessingValue) {
							clearInterval(refreshListInterval);

							refreshListInterval = setInterval(function () {
					            self.fetchDomainSearchTasks();
					        }, 3000);

						} else {
							clearInterval(refreshListInterval);
							
							refreshListInterval = setInterval(function () {
					            self.fetchDomainSearchTasks();
					        }, 60000);
						}
					});
				} else {
					clearInterval(refreshListInterval);
							
					refreshListInterval = setInterval(function () {
			            self.fetchDomainSearchTasks();
			        }, 60000);

					self.props.createNotification(
						"error",
						"An error occurred fetching previous domain lookups.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				clearInterval(refreshListInterval);
							
				refreshListInterval = setInterval(function () {
		            self.fetchDomainSearchTasks();
		        }, 60000);

				self.props.createNotification(
					"error",
					"An error occurred fetching previous domain lookups.",
					"Error",
					6000
				);
			});
	}

	handleDomainSearch(event, name) {
		event.preventDefault()

		if (this.input.current.value === "" && typeof name === 'undefined') {
			return;
		}

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		const requestData = {
			company: typeof name !== 'undefined' ? name : this.input.current.value,
		};

		axios
			.post(settings.API_HOST + "explore", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({name: ""});
					self.fetchDomainSearchTasks();
					self.props.refreshUserData();
					self.props.refreshActiveTeamData();
				} else {
					self.props.createNotification(
						"error",
						"An error occurred performing email lookup.",
						"Error",
						6000
					);
				} 
			})
			.catch(function (error) {
				self.props.createNotification(
					"error",
					"An error occurred performing email lookup.",
					"Error",
					6000
				);
			});

		this.input.current.value = '';
	}

	componentDidUpdate(prevProps) {

		if (prevProps.resultsPerPage !== this.props.resultsPerPage) {
			var self = this;
			this.setState({
				initialPage: 1
			}, () => {
				self.fetchDomainSearchTasks();
			})
		}
	}

	render() {

		let tasks = this.state.tasks.map((item, index) => {
			return (

					<Task
						key={item.id}
						index={index}
						item={item}
						handleDomainSearch={this.handleDomainSearch}
						activeTeamData={this.props.activeTeamData}
						userTags={this.props.userTags}
						displayTagSelector={this.props.displayTagSelector}
						displayTagRemovalSelector={this.props.displayTagRemovalSelector}
						handleTaskRemoval={this.props.handleTaskRemoval}
						refreshTaskData={this.fetchDomainSearchTasks}
						refreshUserData={this.props.refreshUserData}
						switchModeTo={this.props.switchModeTo}
						setDetailsHelperPanelSource={this.props.setDetailsHelperPanelSource}
					/>
			);
		});

		return (
			<div className="domainSearchWrapper">
				<div className="domainSearch">
					<div className="requestForm">
						<div>
							<h1>
								Enter domain or company name to search for email
								addresses.
							</h1>
						</div>
						<form onSubmit={this.handleDomainSearch}>
							<input
								autoFocus
								name="name"
								type="text"
								placeholder="Domain or company name"
								ref={this.input}
							/>
							<div>
								<button className="defaultButtonGreen" type="submit">
									Find email addresses
								</button>
							</div>
						</form>
					</div>

					{this.state.loadingProcessActive ? (
						<div className="singleComponent">
							<LoaderAnimation />
						</div>
					) : (
						<React.Fragment>
							{this.state.tasks.length > 0 || this.state.filter !== "" ? (
								<div className="appTasksWrapper">
									<TaskFilter resultsPerPage={this.props.resultsPerPage}
												setResultsPerPage={this.props.setResultsPerPage}
												maxPage={this.state.maxPage}
												initialPage={this.state.initialPage}
												setInitialPage={this.setInitialPage}
												setFilter={this.setFilter}/>
									<div className="tasksWrapper">
										<div>{tasks}</div>
									</div>
								</div>
							) : (
								<React.Fragment />
							)}
						</React.Fragment>
					)}
				</div>
				<HelperPanel
					activeTeamData={this.props.activeTeamData}
					refreshUserData={this.props.refreshUserData}
					refreshActiveTeamData={this.props.refreshActiveTeamData}
					createNotification={this.props.createNotification}
					helperPanelMode={this.props.helperPanelMode}
					switchHelperPanelModeTo={this.props.switchHelperPanelModeTo}
					userTags={this.props.userTags}
					refreshTaskData={this.fetchDomainSearchTasks}
					source={this.props.source}
				/>
			</div>
		);
	}
}

export default DomainSearch;
