import React, { Component, createRef } from "react";
import axios from 'axios';

let settings = require("../settings");
let authentication = require("../authentication");

class WorkspaceSelector extends Component {
	constructor(props) {
		super(props)

		this.state={listOfTeams: this.props.teamsWhereUserIsMaster}

		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.workspaceSelectorRef = createRef();
		this.selectWorkspace = this.selectWorkspace.bind(this);
		this.selectPersonalWorkspace = this.selectPersonalWorkspace.bind(this);
	}

	selectPersonalWorkspace() {
		let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

        axios
            .get(settings.API_HOST + "user/workspace", {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                	authentication.setAccessToken(response.data.token, tokenHolder.storage);
                	self.workspaceSelectorRef.current.style.opacity = 0;
                	self.props.displayWorkspaceChangeNotification();
                	self.props.refreshActiveTeamData();
                	self.props.switchModeTo("emailFinder")
                	self.props.toggleWorkspaceSelectionVisibility();
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "Workspace selection failed",
                    "Error - Workspace selection",
                    6000
                );
            });
	}

	selectWorkspace(itemId) {

		let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const requestCredentials = {
            id: itemId,
            
        };

        var self = this;

        axios
            .post(settings.API_HOST + "user/workspace", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                	authentication.setAccessToken(response.data.token, tokenHolder.storage);
                	self.workspaceSelectorRef.current.style.opacity = 0;
                	self.props.displayWorkspaceChangeNotification();
                	self.props.refreshActiveTeamData();
                	self.props.switchModeTo("emailFinder")
                	self.props.toggleWorkspaceSelectionVisibility();
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "Workspace selection failed",
                    "Error - Team selection",
                    6000
                );
            });
	}

	handleMouseLeave() {
		this.workspaceSelectorRef.current.style.opacity = 0;
		var self = this;
		setTimeout(function() { 
			self.props.toggleWorkspaceSelectionVisibility();
		}, 300);
	}

	componentDidMount() {

		var self = this;
 
            	self.workspaceSelectorRef.current.style.opacity = 1;
            	var listOfTeams = []

            	if (this.props.teamsWhereUserIsMaster !== "No Content") {
	            	if (this.props.teamsWhereUserIsMaster.length > 0) {
		            	this.props.teamsWhereUserIsMaster.forEach(function(item) {
		            		listOfTeams.push(item)
		            	})
		            }
		        }

		        if (typeof self.props.teams !== "undefined") {
	            	if (self.props.teams.length > 0) {
	            		self.props.teams.forEach(function(item) {
	            			listOfTeams.push(item)
	            		})
	            	}
	            }

                self.setState({
                    listOfTeams: listOfTeams
                });
     
	}

	handleSignOut() {
		authentication.removeAccessToken();
		window.location.href = settings.WEB_HOST
	}

	render() {
	let teamSelection;
	let defaultTeamAvatar = settings.CDN_HOST + "images/team-circle-whiteb.svg";

		if (this.state.listOfTeams === "No Content" || this.state.listOfTeams === []) {
			teamSelection = []
		} else {
			teamSelection = this.state.listOfTeams.map((item, index) => {
				return (
					<li key={index}>
						<div className="workspaceSelectorItem" onClick={() => this.selectWorkspace(item.id)}>
							<div>
								{(typeof item.avatar !== "undefined") ?
									<img className="teamAvatarImage"
										src={item.avatar}
										alt="Poirot Team Avatar - https://poirot.app"
										width="30"
										height="30"
									/>
									:
									<img
										src={defaultTeamAvatar}
										alt="Poirot Team Avatar - https://poirot.app"
										width="30"
										height="30"
									/>
								}
							</div>
							<div>
								{item.name}
							</div>
						</div>
					</li>
				)		
			})
		}
		

		return (
			<React.Fragment>
				<div className="workspaceSelectorWrapper" onMouseLeave={this.handleMouseLeave} ref={this.workspaceSelectorRef}>
					<div className="workspaceSelector">
						<h1>Select workspace</h1>
						<ul>
							{this.state.listOfTeams.length > 0 ?
								<React.Fragment>
									<hr />
									<li onClick={this.selectPersonalWorkspace}>
										Personal account
									</li>
									<hr />
									{teamSelection}
									<hr />
								</React.Fragment>
								:
								<React.Fragment>
									<hr />
										<div className="disabled">You are not a member of any team.</div>
									<hr />
								</React.Fragment>
							}
							<li onClick={this.handleSignOut}>
								Sign out
							</li>
							<hr />
						</ul>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default WorkspaceSelector;