import React, { Component } from "react";

import ProfileData from '../../appComponents/blocks/profileData'
import TwoFactorAuth from '../../appComponents/blocks/2fa'
import ResetPassword from '../../appComponents/blocks/resetPassword'
import TeamMembership from '../../appComponents/blocks/teamMembership'
import ApiCode from '../../appComponents/blocks/apiCode'
import EmailPreferences from '../../appComponents/blocks/emailPreferences'
import DeactivateAccount from '../../appComponents/blocks/deactivateAccount'

class Profile extends Component {

	render() {

		return (
			<div className="profileWrapper">
				<div className="profile">
					<div className="profileBlock">
						<ProfileData createNotification={this.props.createNotification}
									 refreshUserData={this.props.refreshUserData}
									 userData={this.props.userData}/>
					</div>
					<div className={this.props.currentProduct.metadata.tfa ? 'profileBlock' : 'profileBlock grayedOut'}>
						<TwoFactorAuth createNotification={this.props.createNotification}
										enabled={this.props.currentProduct.metadata.tfa}
									 refreshUserData={this.props.refreshUserData}
									 userData={this.props.userData}/>
					</div>
					<div className="profileBlock">
						<ResetPassword createNotification={this.props.createNotification}/>
					</div>
					<div className="profileBlock">
						<TeamMembership createNotification={this.props.createNotification}
										refreshUserData={this.props.refreshUserData}
										userData={this.props.userData}/>
					</div>
					<div  className={this.props.currentProduct.metadata.api ? 'profileBlock' : 'profileBlock grayedOut'}>
						<ApiCode createNotification={this.props.createNotification}
								 enabled={this.props.currentProduct.metadata.api}
								 refreshUserData={this.props.refreshUserData}
								 userData={this.props.userData}/>
					</div>
					<div className="profileBlock">
						<EmailPreferences createNotification={this.props.createNotification}
										  refreshUserData={this.props.refreshUserData}
										  userData={this.props.userData}/>
					</div>
					<div className="profileBlock">
						<DeactivateAccount createNotification={this.props.createNotification}/>
					</div>
				</div>
			</div>
		)
	}
}

export default Profile;
