export const API_HOST = (process.env.NODE_ENV !== 'development') ? 'https://api.poirot.app/' : 'http://localhost:8081/'
export const CDN_HOST = 'https://cdn.poirot.app/'
export const WEB_HOST = (process.env.NODE_ENV !== 'development') ? 'https://www.poirot.app/' : 'http://localhost:8000/'
export const STRIPE_PUBLISHABLE_KEY = (process.env.NODE_ENV !== 'development') ? 'pk_live_51IF1ALK2z90pQddTIw1YQXlKFsYCuf1Cvz1nJoe8InsgAKtvMwfv8Z9j94zVMxQF213TQjPLYuN0RJNveSncY02u00LTPOtSk8' : 'pk_test_rbQ9ru1D9pfXbGHYkh3pCUp300vgypPqKN'
export const PAYPAL_PREPAID_CREDITS_CANCEL_REDIRECT_URL = (process.env.NODE_ENV !== 'development') ? 'https://system.poirot.app/prepaidcredits' : 'http://localhost:3000/prepaidcredits'
export const PAYPAL_PREPAID_CREDITS_APPROVED_REDIRECT_URL = (process.env.NODE_ENV !== 'development') ? 'https://system.poirot.app/approvedprepaidcredits' : 'http://localhost:3000/approvedprepaidcredits'
export const PAYPAL_SUBSCRIBE_CANCEL_REDIRECT_URL = (process.env.NODE_ENV !== 'development') ? 'https://system.poirot.app/subscriptionfailed' : 'http://localhost:3000/subscriptionfailed'
export const PAYPAL_SUBSCRIBE_APPROVED_REDIRECT_URL = (process.env.NODE_ENV !== 'development') ? 'https://system.poirot.app/subscription' : 'http://localhost:3000/subscription'
export const PAYPAL_CREDITS_CANCEL_REDIRECT_URL = (process.env.NODE_ENV !== 'development') ? 'https://system.poirot.app/permanentfailed' : 'http://localhost:3000/permanentfailed'
export const PAYPAL_CREDITS_APPROVED_REDIRECT_URL = (process.env.NODE_ENV !== 'development') ? 'https://system.poirot.app/permanentpurchased' : 'http://localhost:3000/permanentpurchased'
export const GOOGLE_PEOPLE_CONNECTIONS_API_ENDPOINT = 'https://people.googleapis.com/v1/people/me/connections?personFields=emailAddresses'
export const REFEREE_PERMANENT_CREDITS = 25
export const REFERRAL_COMMISSION = 30