import React, { Component } from "react";
import axios from "axios";
import Resizer from 'react-image-file-resizer';

let settings = require("../../settings");
let authentication = require("../../authentication");
let icons = require("../../appComponents/icons");

class AddNewTeam extends Component {
	constructor(props) {
		super(props);

		this.state = {
			teamName: "",
			image: null,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputOnBlurEvent = this.handleInputOnBlurEvent.bind(this);
		this.handleTeamCreation = this.handleTeamCreation.bind(this);
		this.handleAvatarChange = this.handleAvatarChange.bind(this);
		this.resetImage = this.resetImage.bind(this);
	}

	handleAvatarChange(event) {
        if(event.target.files[0]) {

        	if (event.target.files[0].size > 2048000) {
        		this.props.createNotification(
					"error",
					"Team image file size exceeds the maximum of 2 megabytes",
					"Error - team image file size",
					7000
				);
				return;
        	}

        	var self = this;
        	
            Resizer.imageFileResizer(
                event.target.files[0],
                256,
                256,
                'PNG',
                100,
                0,
                uri => {
                	self.setState({image: uri})
                },
                'base64'
            );
        }
	}

	handleInputChange(event) {
		const target = event.target;
		var value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleInputOnBlurEvent(event) {
		event.preventDefault()

		this.setState({
			teamName: "",
			image: null
		})

		this.props.toggleNewTeamInputVisibility();
	}

	handleTeamCreation(event) {
		event.preventDefault();

		if (this.state.teamName === "") {
			this.props.createNotification(
				"error",
				"Please enter a valid team name.",
				"Error - team name",
				6000
			);
			return;
		}
		if (this.state.teamName.length < 2 || this.state.teamName.length > 32) {
			this.props.createNotification(
				"error",
				"Please enter a valid team name. Name should be between 2 and 32 characters long.",
				"Error - team name",
				7000
			);
			return;
		}

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			self.toggleNewTeamInputVisibility()
			return;
		}

		const requestData = {
			name: this.state.teamName,
		};

		if (this.state.image !== null) {
			requestData.avatar = this.state.image
		}

		axios
			.post(settings.API_HOST + "team", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({
						inputIsVisible: false,
						teamName: "",
						image: null
					});
					self.props.updateListOfTeams("setIndexToLast");
					self.props.toggleNewTeamInputVisibility();
					self.props.refreshUserData();
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while trying to create a new team. Please try again later.",
						"Error - Team Creation",
						7000
					);
				}
			})
			.catch(function (error) {
				if (error.response.status === 409) {
					self.props.createNotification(
						"error",
						"Team already exists.",
						"Error - Team Creation",
						6000
					);
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while trying to create a new team. Please try again later.",
						"Error - Team Creation",
						7000
					);
				}
			});
	}

	resetImage(event) {
		event.preventDefault()

		this.setState({
			image: null
		})
	}

	render() {
		let addTeamAvatar = settings.CDN_HOST + "images/team-addition.svg";

		let content = () => {
			if (this.props.newTeamInputIsVisible) {
				return (
					<form
						className="teamInputWrapper"
						onSubmit={this.handleTeamCreation}
					>
						<div>
							<label htmlFor="addAvatar">
								{this.state.image !== null ? 
									<React.Fragment> 
									<div className="teamAvatarImageWrapper">
										<img className="teamAvatarImage"
											src={this.state.image}
											alt="Poirot team avatar"
											width="38"
											height="38"
										/>
										<div className="removeTeamAvatarButton" onClick={this.resetImage}>{icons.timesCircle()}</div>
									</div>
									</React.Fragment>
								:
									<img className="addTeamAvatar"
										src={addTeamAvatar}
										alt="Poirot Team Addition - https://poirot.app"
										width="48"
										height="38"
									/>
								}
							</label>
							<input type="file" id="addAvatar" onChange={this.handleAvatarChange} accept="image/png, image/jpeg"/>
						</div>
						<div className="teamName">
							<input
								autoFocus
								name="teamName"
								type="text"
								placeholder="Enter a team name"
								value={this.state.teamName}
								onChange={this.handleInputChange}
							/>
						</div>
						<div>
							<button
								className="defaultButtonGreen"
								type="submit"
							>
								Create team
							</button>
						</div>
						<div>
							<button
								className="cancelButton"
								onClick={this.handleInputOnBlurEvent}
							>
								Cancel
							</button>
						</div>
					</form>
				);
			} else {
				return (
					<div>
						<button
							className="defaultButtonGreen"
							onClick={this.props.toggleNewTeamInputVisibility}
						>
							Add another team
						</button>
					</div>
				);
			}
		};

		return <div className="teamAddition">{content()}</div>;
	}
}

export default AddNewTeam;
