import React, { Component } from "react";
import axios from "axios";

import download from 'downloadjs';

import ContactItem from "../appComponents/contactItem";

let settings = require("../settings");
let authentication = require("../authentication");

class ContactLists extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tag: undefined,
            tasks: [],
            tagSelectorVisible: false,
        };

        this.fetchTasks = this.fetchTasks.bind(this);
        this.setTagSelectorToVisible = this.setTagSelectorToVisible.bind(this);
        this.closeTagSelector = this.closeTagSelector.bind(this);
        this.handleTagAssignment = this.handleTagAssignment.bind(this);
        this.exportCSV = this.exportCSV.bind(this)
    }

    handleTagAssignment(tag) {
        var self = this;
        this.setState({
            tag: tag
        },() => {
            self.fetchTasks();
        })
    }

    closeTagSelector() {
        this.setState({
            tagSelectorVisible: false,
        });
    }

    setTagSelectorToVisible() {
        this.setState({
            tagSelectorVisible: true,
        });
    }

    exportCSV() {
        if (typeof this.state.tag === "undefined") {
            return;
        }

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const requestData = {
            tag: this.state.tag.id,
        };

        axios
            .post(settings.API_HOST + "tag/export", requestData, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    const content = response.headers['content-type'];
                    let name = "Contacts " + self.state.tag.label + ".csv" 
                    name = name.replace(/\s/g, '_')
                    download(response.data, name, content)
                } else {
                    self.props.createNotification(
                        "error",
                        "An error occurred exporting CSV document.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "An error occurred exporting CSV document.",
                    "Error",
                    6000
                );
            });
    }

    fetchTasks() {
        if (typeof this.state.tag === "undefined") {
            return;
        }

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const requestData = {
            tag: this.state.tag.id,
        };

        axios
            .post(settings.API_HOST + "contacts", requestData, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.setState({ tasks: response.data });
                } else {
                    self.props.createNotification(
                        "error",
                        "An error occurred fetching contacts.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "An error occurred fetching contacts.",
                    "Error",
                    6000
                );
            });
    }

    componentDidMount() {
        if (
            typeof this.props.activeTeamData === "undefined" ||
            this.props.activeTeamData === "unavailable"
        ) {
            if (typeof this.props.userTags !== "undefined") {
                if (this.props.userTags.length > 0) {
                    this.setState(
                        {
                            tag: this.props.userTags[0],
                        },
                        () => {
                            this.fetchTasks();
                        }
                    );
                }
            }
        } else {
            if (this.props.activeTeamData.tags.length > 0) {
                this.setState(
                    {
                        tag: this.props.activeTeamData.tags[0],
                    },
                    () => {
                        this.fetchTasks();
                    }
                );
            }
        }
    }

    // componentDidUpdate(prevProps) {

    //     if (
    //         prevProps.activeTeamData !== this.props.activeTeamData ||
    //         prevProps.userTags !== this.props.userTags
    //     ) {

    //         if (
    //             typeof this.props.activeTeamData === "undefined" ||
    //             this.props.activeTeamData === "unavailable"
    //         ) {
    //             if (typeof this.props.userTags !== "undefined") {
    //                 if (this.props.userTags.length > 0) {
    //                     this.setState(
    //                         {
    //                             tag: this.props.userTags[0],
    //                         },
    //                         () => {
    //                             this.fetchTasks();
    //                         }
    //                     );
    //                 }
    //             }
    //         } else {
    //             if (this.props.activeTeamData.tags.length > 0) {
    //                 this.setState(
    //                     {
    //                         tag: this.props.activeTeamData.tags[0],
    //                     },
    //                     () => {
    //                         this.fetchTasks();
    //                     }
    //                 );
    //             }
    //         }
    //     }
    // }

    render() {
        let tasks = this.state.tasks.map((item, index) => {
            return (
                <ContactItem
                    key={item.email + index}
                    index={index}
                    item={item}
                />
            );
        });

        let tags =
            this.props.activeTeamData === "unavailable"
                ? this.props.userTags
                : this.props.activeTeamData.tags;
        let tag = tags.map((item, index) => {
            if (
                typeof this.props.tagSelectedTaskLabel === "undefined" ||
                !this.props.tagSelectedTaskLabel.includes(item.id)
            ) {
                return (
                    <div
                        className="tagSelectorTag"
                        key={index}
                        onClick={() => this.handleTagAssignment(item)}
                    >
                        <div>
                            <svg height="14" width="14">
                                <circle
                                    cx="7"
                                    cy="7"
                                    r="7"
                                    fill={"#" + item.color}
                                />
                            </svg>
                        </div>

                        {item.label}
                    </div>
                );
            } else {
                return <React.Fragment key={index} />;
            }
        });

        return typeof this.state.tag === "undefined" ? (
            <div className="contactListsWrapper">
                <div className="contactLists">
                    <React.Fragment />
                </div>
               
            </div>
        ) : (
            <div className="contactListsWrapper">
                <div className="contactLists">
                    <div className="contactListsFilterWrapper">
                        <div>
                                <div className="tagSelectorTag" onClick={() => this.setTagSelectorToVisible()}>
                                    <div>
                                        <svg height="14" width="14">
                                            <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                fill={
                                                    "#" + this.state.tag.color
                                                }
                                            />
                                        </svg>
                                    </div>

                                    {this.state.tag.label}
                                </div>
                            {this.state.tagSelectorVisible ? (
                                <div
                                    className="contactsTagSelectorWrapper"
                                    onMouseLeave={this.closeTagSelector}
                                >
                                    <div className="tagSelector">
                                        <div>{tag}</div>
                                    </div>
                                </div>
                            ) : (
                                <React.Fragment />
                            )}
                        </div>
                        <div>
                            <button className="defaultButtonGreen" onClick={() => this.exportCSV()}>
                                Export CSV
                            </button>
                        </div>
                    </div>
                    <div className="tasksWrapper">
                        <div>{tasks}</div>
                    </div>
                </div>
     
            </div>
        );
    }
}

export default ContactLists;
