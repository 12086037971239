import React, { Component } from "react";
import axios from 'axios';
import moment from 'moment';

import CancelDowngradeForm from '../../appComponents/cancelDowngradeForm';
import LoaderAnimation from '../../LoaderAnimation';

let settings = require("../../settings");
let authentication = require("../../authentication");
let icons = require("../icons");

class CreditsChart extends Component {
	constructor(props) {
		super(props);

		// Determine best plan from number of credits it provides
		var plansData = this.props.plansData.map((element) => ({
			credits: element.metadata.lookupCredits,
			name: element.name,
		}));
		var upperMostPlan =
			plansData[
				Object.keys(plansData).reduce((a, b) =>
					plansData[a].credits > plansData[b].credits ? a : b
				)
			];

		this.state = {
			upperMostPlan: upperMostPlan,
			cancelDowngradeFormVisible: false,
			processing: false,
		};

		this.toggleCancelDowngradeForm = this.toggleCancelDowngradeForm.bind(this);
		this.cancelDowngrade = this.cancelDowngrade.bind(this);
	}

	toggleCancelDowngradeForm(event) {
		event.preventDefault()

		this.setState({cancelDowngradeFormVisible: !this.state.cancelDowngradeFormVisible})
	}

	cancelDowngrade(event) {
		event.preventDefault()
		this.setState({processing: true})
		let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

		const headers = { Authorization: "Bearer " + tokenHolder.token };

        axios
            .delete(settings.API_HOST + "user/downgradesubscription", { headers })
            .then(function (response) {
            	self.setState({processing: false})
                if (response.status === 200) {
                    self.props.createNotification(
                        "success",
                        "Pending downgrade canceled.",
                        "Account Plan Updated",
                        7000
                    );
                    self.setState({cancelDowngradeFormVisible: false});
                    self.props.refreshUserData();
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
            	self.setState({processing: false})
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
	}

	render() {
			var monthlyLookupCredits
			var defaultMonthlyLookupCredits
			var monthlyVerificationCredits
			var defaultMonthlyVerificationCredits
			var remainingLookupPercent
			var lookupChartOffset
			var remainingVerificationPercent
			var verificationChartOffset
			var permanentCredits
			var kIdentificator

			var renewalDate = moment.unix(this.props.lastRenewal)
			renewalDate.add(1, 'months')
			const year = renewalDate.format('YYYY')
			const month = renewalDate.format('MMMM')
			const day = renewalDate.format('Do')

		if (this.props.activeTeamData === "unavailable") {
			monthlyLookupCredits = this.props.monthlyLookupCredits
			defaultMonthlyLookupCredits = this.props.defaultMonthlyLookupCredits
			remainingLookupPercent =
				(this.props.monthlyLookupCredits /
					this.props.defaultMonthlyLookupCredits) *
				100;
			if (remainingLookupPercent < 1 && remainingLookupPercent > 0) {
				remainingLookupPercent = 1;
			} else if (remainingLookupPercent >= 1) {
				remainingLookupPercent = Math.round(remainingLookupPercent);
			}

			lookupChartOffset = remainingLookupPercent + "%";

			monthlyVerificationCredits = this.props.monthlyVerificationCredits
			defaultMonthlyVerificationCredits = this.props.defaultMonthlyVerificationCredits
			remainingVerificationPercent = Math.round(
				(this.props.monthlyVerificationCredits /
					this.props.defaultMonthlyVerificationCredits) *
					100
			);
			if (
				remainingVerificationPercent < 1 &&
				remainingVerificationPercent > 0
			) {
				remainingVerificationPercent = 1;
			} else if (remainingVerificationPercent >= 1) {
				remainingVerificationPercent = Math.round(
					remainingVerificationPercent
				);
			}
			verificationChartOffset = remainingVerificationPercent + "%";

			if (this.props.permanentCredits > 9999) {
				permanentCredits = Math.floor(this.props.permanentCredits / 1000);
				kIdentificator = "K";
				if (this.props.permanentCredits > 999000) {
					permanentCredits = 1
					kIdentificator = "M"
				}
			} else {
				permanentCredits = this.props.permanentCredits;
			}

		} else {
			console.log(this.props.activeTeamData)
			monthlyLookupCredits = this.props.activeTeamData.monthlyLookupCredits
			defaultMonthlyLookupCredits = this.props.activeTeamData.defaultMonthlyLookupCredits
			remainingLookupPercent =
				(this.props.activeTeamData.monthlyLookupCredits /
					this.props.activeTeamData.defaultMonthlyLookupCredits) *
				100;
			if (remainingLookupPercent < 1 && remainingLookupPercent > 0) {
				remainingLookupPercent = 1;
			} else if (remainingLookupPercent >= 1) {
				remainingLookupPercent = Math.round(remainingLookupPercent);
			}

			lookupChartOffset = remainingLookupPercent + "%";

			monthlyVerificationCredits = this.props.activeTeamData.monthlyVerificationCredits
			defaultMonthlyVerificationCredits = this.props.activeTeamData.defaultMonthlyVerificationCredits
			remainingVerificationPercent = Math.round(
				(this.props.activeTeamData.monthlyVerificationCredits /
					this.props.activeTeamData.defaultMonthlyVerificationCredits) *
					100
			);
			if (
				remainingVerificationPercent < 1 &&
				remainingVerificationPercent > 0
			) {
				remainingVerificationPercent = 1;
			} else if (remainingVerificationPercent >= 1) {
				remainingVerificationPercent = Math.round(
					remainingVerificationPercent
				);
			}
			verificationChartOffset = remainingVerificationPercent + "%";

			if (this.props.activeTeamData.permanentCredits > 9999) {
				permanentCredits = Math.floor(this.props.activeTeamData.permanentCredits / 1000);
				kIdentificator = "K";
				if (this.props.activeTeamData.permanentCredits > 999000) {
					permanentCredits = 1
					kIdentificator = "M"
				}
			} else {
				permanentCredits = this.props.activeTeamData.permanentCredits;
			}
		}


		var crispUrl =
			"https://go.crisp.chat/chat/embed/?website_id=94a8b93d-0238-4d7e-bb11-549872910fb9&user_email=" +
			encodeURIComponent(this.props.userEmailAddress) +
			"&user_nickname="
			encodeURIComponent(this.props.userName);

		return (
			<React.Fragment>
				{this.state.processing ? <LoaderAnimation /> : <React.Fragment />}
				{this.state.cancelDowngradeFormVisible ? <CancelDowngradeForm currentProductName={this.props.currentProductName}
																			  toggleCancelDowngradeForm={this.toggleCancelDowngradeForm}
																			  cancelDowngrade={this.cancelDowngrade}/> : <React.Fragment/>}
				<div className="creditsChartBlock">
					<div className="creditsCharts">
						<div className="creditsChartsTitle">
							<h1>Remaining queries</h1>

							{(this.props.activeTeamData === "unavailable" || this.props.isActiveTeamTeamMaster) ?
								<React.Fragment>
									{this.props.downgradeActive ?
										<h6 className="downgradeAlert">Account plan pending downgrade on:</h6>
										:
										<h6>Account credits will be renewed on:</h6>
									}
									<div className="creditsChartDowngradeAlertCancelWrapper">
										<h6 className={this.props.downgradeActive ? "downgradeAlert" : ""}>
											{" "}
											{month} {day}, {year}.
										</h6>
										{this.props.downgradeActive ?
											<li onClick={this.toggleCancelDowngradeForm}>
												Cancel
											</li>
										:
											<React.Fragment />
										}
									</div>
								</React.Fragment>
								:
								<React.Fragment />
							}
						</div>
						<div className="lookupCreditsChartWrapper">
							<div className="chartStatus">
								{monthlyLookupCredits} of{" "}
								{defaultMonthlyLookupCredits >= monthlyLookupCredits ? defaultMonthlyLookupCredits : monthlyLookupCredits}
							</div>
							<div className="chartName">Lookups</div>
							<div>
								<svg
									className="lookupCreditsChart"
									height="30"
									viewBox="0 0 123 30"
									width="123"
									xmlns="http://www.w3.org/2000/svg"
								>
									<linearGradient
										x1="0"
										y1="0"
										x2="100%"
										y2="0%"
										id="lookupCreditsChartgradient"
									>
										<stop stopColor="#FECD35" offset="0" />
										<stop
											stopColor="#FECD35"
											offset={lookupChartOffset}
										/>
										<stop
											stopColor="#dedede"
											offset={lookupChartOffset}
										/>
										<stop stopColor="#dedede" offset="100%" />
									</linearGradient>
									<g fill="#fff">
										<path
											d="m0 0h123v30h-123z"
											fill="url(#lookupCreditsChartgradient)"
										/>
										<path d="m10.5 0h2v30h-2z" />
										<path d="m23 0h2v30h-2z" />
										<path d="m35.5 0h2v30h-2z" />
										<path d="m48 0h2v30h-2z" />
										<path d="m60.5 0h2v30h-2z" />
										<path d="m73 0h2v30h-2z" />
										<path d="m85.5 0h2v30h-2z" />
										<path d="m98 0h2v30h-2z" />
										<path d="m110.5 0h2v30h-2z" />
									</g>
								</svg>
							</div>
						</div>
						<div className="verificationCreditsChartWrapper">
							<div className="chartStatus">
								{monthlyVerificationCredits} of{" "}
								{defaultMonthlyVerificationCredits >= monthlyVerificationCredits ? defaultMonthlyVerificationCredits : monthlyVerificationCredits}
							</div>
							<div className="chartName">Verifications</div>
							<div>
								<svg
									className="verificationCreditsChart"
									height="30"
									viewBox="0 0 123 30"
									width="123"
									xmlns="http://www.w3.org/2000/svg"
								>
									<linearGradient
										x1="0"
										y1="0"
										x2="100%"
										y2="0%"
										id="verificationCreditsChartgradient"
									>
										<stop stopColor="#A4D037" offset="0" />
										<stop
											stopColor="#A4D037"
											offset={verificationChartOffset}
										/>
										<stop
											stopColor="#dedede"
											offset={verificationChartOffset}
										/>
										<stop stopColor="#dedede" offset="100%" />
									</linearGradient>
									<g fill="#fff">
										<path
											d="m0 0h123v30h-123z"
											fill="url(#verificationCreditsChartgradient)"
										/>
										<path d="m10.5 0h2v30h-2z" />
										<path d="m23 0h2v30h-2z" />
										<path d="m35.5 0h2v30h-2z" />
										<path d="m48 0h2v30h-2z" />
										<path d="m60.5 0h2v30h-2z" />
										<path d="m73 0h2v30h-2z" />
										<path d="m85.5 0h2v30h-2z" />
										<path d="m98 0h2v30h-2z" />
										<path d="m110.5 0h2v30h-2z" />
									</g>
								</svg>
							</div>
						</div>
						<div className="permanentCreditsChartWrapper">
							<div className="chartName">Permanent credits</div>
							<div className="permanentCreditsChartStatus">
								{permanentCredits}
								<span className="kIdentificator">
									{kIdentificator}
								</span>
							</div>
						</div>
						<div className="upgradeAccountButtonWrapper">
							<button className="defaultButtonBlue" onClick={() => this.props.switchModeTo('accountPlans')} disabled={(!this.props.isActiveTeamTeamMaster && this.props.activeTeamData !== "unavailable")}>
								{this.props.currentProductName ===
								this.state.upperMostPlan.name
									? "Add more credits"
									: "Upgrade account"}
							</button>
						</div>
						<div className="crispChatIconButtonWrapper">
							<a href={crispUrl} target="_blank" rel="noopener noreferrer">
								{icons.crispChatIcon()}
							</a>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default CreditsChart;
