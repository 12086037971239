import React, { Component } from "react";
import LoaderAnimation from "../LoaderAnimation";

let icons = require("./icons");

class DetailsHelperPanel extends Component {

    render() {
        let icon = "https://s2.googleusercontent.com/s2/favicons?domain=world"

        if (typeof this.props.domainData !== 'undefined') {
            icon = "https://s2.googleusercontent.com/s2/favicons?domain=" + this.props.domainData.fqdn
        }


        return typeof this.props.loadingProcessActive === "undefined" ?
            <div className="domainDataWrapper">
                <div className="detailsViewStamp">
                    <img src="https://cdn.poirot.app/images/PoirotStampGrayscale.svg" alt="Poirot Stamp Grayscale" />
                </div>
            </div>
            : this.props.loadingProcessActive ?
                <div className="domainDataWrapper">
                    <div className="detailsViewStamp singleComponent">
                        <LoaderAnimation />
                    </div>
                </div>
            :
            
            <div className="domainDataWrapper">
                
                <div className="domainDataLogo">
                    {
                        typeof this.props.domainData.icon === 'undefined' ?
                            <img src={icon} alt="companyIcon" />
                            :
                            <img src={this.props.domainData.icon} alt="companyLogo"/>
                    }
                </div>

                <div className="domainDataName">
                    {
                        typeof this.props.domainData.name === 'undefined' ?
                            <React.Fragment />
                            :
                            <h3>{this.props.domainData.name}</h3>
                    }
                </div>

                <div className="domainDataTitle">
                    {
                        typeof this.props.domainData.title === 'undefined' ?
                            <React.Fragment />
                            :
                            
                                typeof this.props.domainData.name === 'undefined' ?
                                <h4>{this.props.domainData.title}</h4>
                                :
                                
                                    this.props.domainData.name === this.props.domainData.title ?
                                    <React.Fragment />
                                    :
                                    <h4>{this.props.domainData.title}</h4>
                                
                            
                    }
                </div>

                <div className="domainDataURL">
                    {
                        this.props.domainData.websiteStatus === 'Valid' ?
                            <React.Fragment>
                                <div>
                                    <div><span>Website</span></div>
                                    <div><a href={"http://"+this.props.domainData.fqdn}>{this.props.domainData.fqdn}</a></div>
                                </div>
                                <div>
                                    <div><span>SSL</span></div>
                                    <div>{this.props.domainData.securedWithSSL ? "Enabled" : "Disabled"}</div>
                                </div>
                            </React.Fragment>
                            :
                            <div>Website unavailable</div>
                    }
                </div>

                <div className="domainDataMailServer">
                    <div>
                        <div>Mailserver</div>
                        <div><span>{this.props.domainData.mxRecord}</span></div>
                    </div>
                </div>

                <div className="domainDataMailboxProvider">
                    <div>
                        <div>Mailbox type</div>
                        <div><span>{this.props.domainData.target}</span></div>
                    </div>
                </div>

                <div className="domainDataMailboxCatchAll">
                    {
                        typeof this.props.domainData.catchAll !== 'undefined' ?
                            
                                
                                    this.props.domainData.catchAll ?
                                    <React.Fragment>
                                        <div>
                                            Emails verifiable
                                            <span className="verificationStatusFail">{icons.timesCircle()}</span>
                                        </div> 
                                    </React.Fragment>
                                    : 
                                    <React.Fragment>
                                        <div>
                                            Emails verifiable
                                            <span className="verificationStatusPass">{icons.checkCircle()}</span>
                                        </div>
                                    </React.Fragment>
                                    
                                

                            :
                            <React.Fragment />
                    }
                </div>

                <div className="domainDataRecordsNumber">
                    <div>
                        <div>Domain emails in database</div>
                        <div><span>{this.props.domainData.numberOfRecords}</span></div>
                    </div>
                </div>

            </div>
    }
}

export default DetailsHelperPanel;
