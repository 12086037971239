import React, { Component } from "react";
import axios from "axios";
import Resizer from 'react-image-file-resizer';

let settings = require("../../settings");
let authentication = require("../../authentication");
let icons = require("../../appComponents/icons");

class Team extends Component {
	constructor(props) {
		super(props)

		this.state=({
			teamName: this.props.item.name,
			image: this.props.item.avatar !== undefined ? this.props.item.avatar : null,
			inEditMode: false
		})

		this.performTeamSelection = this.performTeamSelection.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputOnBlurEvent = this.handleInputOnBlurEvent.bind(this);
		this.handleEditMode = this.handleEditMode.bind(this);
		this.handleTeamUpdate = this.handleTeamUpdate.bind(this);
		this.resetImage = this.resetImage.bind(this);
		this.handleAvatarChange = this.handleAvatarChange.bind(this);
	}

	handleEditMode(event) {
		event.preventDefault()

		this.setState({
			inEditMode: true,
		})

		this.props.toggleTeamUpdateProcess()
	}

	performTeamSelection(index) {
		if (this.props.newTeamInputIsVisible || this.props.teamUpdateInProcess) {
			return
		} else {
			this.props.selectTeam(index)
		}
	}

	handleInputChange(event) {
		const target = event.target;
		var value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleInputOnBlurEvent(event) {
		event.preventDefault()

		this.setState({
			teamName: this.props.item.name,
			image: this.props.item.avatar !== undefined ? this.props.item.avatar : null,
			inEditMode: false,
		})

		this.props.toggleTeamUpdateProcess();
	}

	handleTeamUpdate(event) {
		event.preventDefault();

		if (this.state.teamName === "") {
			this.props.createNotification(
				"error",
				"Please enter a valid team name.",
				"Error - team name",
				6000
			);
			return;
		}
		if (this.state.teamName.length < 2 || this.state.teamName.length > 32) {
			this.props.createNotification(
				"error",
				"Please enter a valid team name. Name should be between 2 and 32 characters long.",
				"Error - team name",
				7000
			);
			return;
		}

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			this.setState({
				teamName: this.props.item.name,
				image: this.props.item.avatar !== undefined ? this.props.item.avatar : null,
				inEditMode: false
			})
			
			this.props.toggleTeamUpdateProcess()
			return;
		}

		if (this.state.teamName === this.props.item.name && ((this.state.image === null && this.props.item.avatar === undefined) || (this.state.image === this.props.item.avatar)) ) {

			this.setState({
				teamName: this.props.item.name,
				image: this.props.item.avatar !== undefined ? this.props.item.avatar : null,
				inEditMode: false
			})
			
			this.props.toggleTeamUpdateProcess()
			return;
		}

		const requestData = {
			id: this.props.item.id,
		};

		if (this.state.teamName !== this.props.item.name) {
			requestData.name = this.state.teamName
		}

		if (this.state.image !== this.props.item.avatar) {
			requestData.avatar = this.state.image !== null ? this.state.image : "NULL"
		}

		axios
			.patch(settings.API_HOST + "team", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.props.createNotification(
                        "success",
                        "Team has been successfully updated.",
                        "Team Updated",
                        7000
                    );
					self.props.updateListOfTeams(self.props.index);
					self.props.toggleTeamUpdateProcess();
					self.setState({
						inEditMode: false,
					});
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while updating team data. Please try again later.",
						"Error - Team Update",
						7000
					);
				}
			})
			.catch(function (error) {
				if (error.response.status === 409) {
					self.props.createNotification(
						"error",
						"Team name already exists.",
						"Error - Team Update",
						6000
					);
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while updating team data. Please try again later.",
						"Error - Team Update",
						7000
					);
				}
			});
	}

	handleAvatarChange(event) {
        if(event.target.files[0]) {

        	if (event.target.files[0].size > 2048000) {
        		this.props.createNotification(
					"error",
					"Team image file size exceeds the maximum of 2 megabytes",
					"Error - team image file size",
					7000
				);
				return;
        	}

        	var self = this;
        	
            Resizer.imageFileResizer(
                event.target.files[0],
                256,
                256,
                'PNG',
                100,
                0,
                uri => {
                	self.setState({image: uri})
                },
                'base64'
            );
        }
	}

	resetImage(event) {
		event.preventDefault()

		this.setState({
			image: null
		})
	}

	render() {
		let defaultTeamAvatar = settings.CDN_HOST + "images/team-circle.svg";
		let addTeamAvatar = settings.CDN_HOST + "images/team-addition.svg";

		let activeMembersCount = () => {
			var activeMembers = this.props.item.teamMembers.filter(function(member) {
				return member.status === "Active"
			})
			return activeMembers.length
		}

		if (this.state.inEditMode) {
			return (
				<form
						className="teamInputWrapper"
						onSubmit={this.handleTeamUpdate}
					>
						<div>
							<label htmlFor="addAvatar">
								{this.state.image !== null ? 
									<React.Fragment> 
									<div className="teamAvatarImageWrapper">
										<img className="teamAvatarImage"
											src={this.state.image}
											alt="Poirot team avatar"
											width="38"
											height="38"
										/>
										<div className="removeTeamAvatarButton" onClick={this.resetImage}>{icons.timesCircle()}</div>
									</div>
									</React.Fragment>
								:
									<img className="addTeamAvatar"
										src={addTeamAvatar}
										alt="Poirot Team Addition - https://poirot.app"
										width="48"
										height="38"
									/>
								}
							</label>
							<input type="file" id="addAvatar" onChange={this.handleAvatarChange} accept="image/png, image/jpeg"/>
						</div>
						<div className="teamName">
							<input
								autoFocus
								name="teamName"
								type="text"
								placeholder="Enter a team name"
								value={this.state.teamName}
								onChange={this.handleInputChange}
							/>
						</div>
						<div>
							<button
								className="defaultButtonGreen"
								type="submit"
							>
								Update team
							</button>
						</div>
						<div>
							<button
								className="cancelButton"
								onClick={this.handleInputOnBlurEvent}
							>
								Cancel
							</button>
						</div>
					</form>
			)
		} else {
			return (
				<div
					className={
						this.props.selectedTeamIndex === this.props.index && !this.props.newTeamInputIsVisible
							? "teamWrapper inFocus"
							: this.props.newTeamInputIsVisible || this.props.teamUpdateInProcess ? "teamWrapper noHover" : "teamWrapper"
					}
					onClick={() => this.performTeamSelection(this.props.index)}
				>
					<div className="teamImage">
						{this.state.image !== null ?
							<img className="teamAvatarImage"
								src={this.state.image}
								alt="Poirot Team Avatar - https://poirot.app"
								width="38"
								height="38"
							/>
							:
							<img
								src={defaultTeamAvatar}
								alt="Poirot Team Avatar - https://poirot.app"
								width="38"
								height="38"
							/>
						}
					</div>
					<div className="teamName">{this.state.teamName}</div>
					<div className="teamEditIconsWrapper">
						<div
							className={
								this.props.selectedTeamIndex === this.props.index && !this.props.newTeamInputIsVisible
									? "editIcon"
									: "displayNone"
							}
							onClick={this.handleEditMode}>
							{icons.settings()}
						</div>
						<div
							className={
								this.props.selectedTeamIndex === this.props.index && !this.props.newTeamInputIsVisible
									? "editIcon"
									: "displayNone"
							}			
							onClick={this.props.toggleActiveTeamRemoval}>
							{icons.trashCan()}
						</div>
					</div>
					<div className="teamMembers">
						<div>Members</div>
						<div>{activeMembersCount()}</div>
					</div>
					<div className="teamStatus">
						<div>Status</div>
						<div>
							{this.props.item.active
								? icons.checkCircle()
								: icons.timesCircle()}
						</div>
					</div>
				</div>
			)	
		};
	}
}

export default Team;
