import React, { Component } from 'react';

class PaymentMethodRemoval extends Component {

    render() {
        return(
            <div className="paymentMethodRemovalWrapper">
                <div className="paymentMethodRemoval">
                    <h1>Remove payment card</h1>
                    <p>
                        Are you sure you would like to remove card<br/>
                        <span>
                            **** **** ****&nbsp;
                            {
                                this.props.last4
                            }
                        </span><br/>
                        from your list of payment methods?
                    </p>
                    <p>
                        You can always reassociate this card with your account.
                    </p>
                    <div className="paymentMethodRemovalButtonWrapper">
                        <button className="cancelButton"
                            onClick={this.props.toggleRemovalConfirmationVisibility}
                        >
                            Cancel
                        </button>
                        <button
                            className="defaultButtonRed"
                            onClick={(event) => this.props.removePaymentMethod(event)}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        )
    }
};

export default PaymentMethodRemoval;