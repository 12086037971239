import React, { Component } from "react";
import axios from 'axios';

let settings = require("../../settings");
let authentication = require("../../authentication");

class Limitations extends Component {
    constructor(props) {
        super(props)

        this.state=({
            lookupsLimit: this.props.listOfTeams[this.props.selectedTeamIndex].lookupsLimit,
            verificationsLimit: this.props.listOfTeams[this.props.selectedTeamIndex].verificationsLimit,
            permanentCreditsLimit: this.props.listOfTeams[this.props.selectedTeamIndex].permanentCreditsLimit
        })

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleTeamUpdate = this.handleTeamUpdate.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;

        if (name === "lookupsLimit" || name === "verificationsLimit" || name === "permanentCreditsLimit") {
            if (target.value >= 0 && !isNaN(target.value) && target.value <= 1000000) {
                value = Number(target.value)
            } else {
                return
            }
        }

        this.setState({
            [name]: value,
        });
    }

    handleTeamUpdate(event) {
        event.preventDefault();

        let tokenHolder = authentication.getToken();

        //
        // Check if update is required
        //
        if (
            this.props.listOfTeams[this.props.selectedTeamIndex].lookupsLimit === this.state.lookupsLimit &&
            this.props.listOfTeams[this.props.selectedTeamIndex].verificationsLimit === this.state.verificationsLimit &&
            this.props.listOfTeams[this.props.selectedTeamIndex].permanentCreditsLimit === this.state.permanentCreditsLimit
            ) {
            return
        }

        if (!tokenHolder) {
            return;
        }

        const requestCredentials = {
            id: this.props.listOfTeams[this.props.selectedTeamIndex].id,
            lookupsLimit: this.state.lookupsLimit,
            verificationsLimit: this.state.verificationsLimit,
            permanentCreditsLimit: this.state.permanentCreditsLimit
        };

        var self = this;

        axios
            .patch(settings.API_HOST + "team", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.props.createNotification(
                        "success",
                        "Team settings have successfully been updated.",
                        "Team updated",
                        7000
                    );
                    self.props.updateListOfTeams();
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
    }

    render() {
        return (
            <React.Fragment>
                <h2>Monthly team usage limitations</h2>
                <div className="teamLimitations">
                    <div className="creditsLimit">
                        <h6>Lookup credits</h6>
                        <input
                            name="lookupsLimit"
                            type="text"
                            value={this.state.lookupsLimit}
                            onChange={this.handleInputChange}
                            onBlur={this.handleTeamUpdate}
                        />
                    </div>
                    <div className="creditsLimit">
                        <h6>Verification credits</h6>
                        <input
                            name="verificationsLimit"
                            type="text"
                            value={this.state.verificationsLimit}
                            onChange={this.handleInputChange}
                            onBlur={this.handleTeamUpdate}
                        />
                    </div>
                    <div className="creditsLimit">
                        <h6>Permanent credits</h6>
                        <input
                            name="permanentCreditsLimit"
                            type="text"
                            placeholder="Credits"
                            value={this.state.permanentCreditsLimit}
                            onChange={this.handleInputChange}
                            onBlur={this.handleTeamUpdate}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Limitations;
