import React, { Component } from "react";

import Gravatar from './gravatar.js';
import Tags from './blocks/tags';

let icons = require("./icons");

class TaskVerifier extends Component {
	constructor(props) {
		super(props)

		this.state = ({
			isSelected: false
		})

		this.handleTaskLookupClick = this.handleTaskLookupClick.bind(this);
		this.handleRightClick = this.handleRightClick.bind(this);
	}

	handleTaskLookupClick() {
		this.props.setDetailsHelperPanelSource()
	}

	handleRightClick(event, taskID) {
		event.preventDefault()

		this.props.handleTaskRemoval(event, taskID, this.props.refreshTaskData)
	}
	
	render() {

		const processingBarRandomClass = Math.floor(
			Math.random() * Math.floor(5)
		);
		
		let email = typeof this.props.item.result === 'undefined' 
		? 
			"" 
		:
			this.props.item.result.payload.length === 0 
			?
				""
			: 
				this.props.item.result.payload[0].email


		let type = typeof this.props.item.result === 'undefined'
		?
			""
		:
			this.props.item.result.payload.length === 0 
			?
				""
			: 
				this.props.item.result.payload[0].type

		let target = typeof this.props.item.result === 'undefined'
		?
			""
		:
			this.props.item.result.payload.length === 0 
			?
				""
			: 
				this.props.item.result.payload[0].providerTarget

		let date = new Date(
			this.props.item.createdAt * 1000
		).toLocaleDateString();

		let status = () => {
			switch (this.props.item.status) {
				case "pending":
					return (
						<div>
							<div className="processingBarWrapper">
								<div
									className={
										"processingBar processingBar" +
										processingBarRandomClass
									}
								></div>
							</div>
						</div>
					);
				case "processing":
					return (
						<div>
							<div className="processingBarWrapper">
								<div
									className={
										"processingBar processingBar" +
										processingBarRandomClass
									}
								></div>
							</div>
						</div>
					);
				case "succeeded":
					if (typeof this.props.item.result !== 'undefined') {
						if (this.props.item.result.payload.length > 0) {
							if (this.props.item.result.payload[0].verified) {
								return <div>{icons.checkCircle()}</div>
							} else {
								return <div>{icons.timesCircle()}</div>
							}
						} else {
							return <div>{icons.timesCircle()}</div>
						}
					} else {
						return <React.Fragment />
					}
				case "failed":
					return <div>{icons.timesCircle()}</div>;
				default:
					return <React.Fragment />;
			}
		};

		return (
			<div
				className={
					this.state.isSelected
						? "taskWrapper inFocus"
						: "taskWrapper"
				}
			>
				<div className="taskLookup" onClick={() => this.handleTaskLookupClick()} onContextMenu={(event) => this.handleRightClick(event, this.props.item.id)}>
					<div className="contactAvatar">
						<Gravatar userEmailAddress={email} defaultImage="https%3A%2F%2Fcdn.poirot.app%2Fimages%2Fperson-circle.png" imageSize="24"/>
					</div>
					<div className="taskDomainName">
						{
							type === "prospect" ?
								<div className="verificationEmailType-prospect">Prospect</div>
								:
								<React.Fragment />
						}
						{
							type === "generic" ?
								<div className="verificationEmailType-generic">Generic</div>
								:
								<React.Fragment />
						}
						{
							type === "fraudulent" ?
								<div className="verificationEmailType-fraudulent">Fraudulent</div>
								:
								<React.Fragment />
						}
						{
							type === "unknown" ?
								<div className="verificationEmailType-unknown">Unknown</div>
								:
								<React.Fragment />
						}
					</div>
					<div className="taskDomainName">
						{
							target === "Regular" ?
								<div className="verificationEmailTarget-regular">Regular Email Provider</div>
								:
								<React.Fragment />
						}
						{
							target === "Free Provider" ?
								<div className="verificationEmailTarget-free">Free Email Provider</div>
								:
								<React.Fragment />
						}
						{
							target === "Disposable Provider" ?
								<div className="verificationEmailTarget-disposable">Disposable Email Provider</div>
								:
								<React.Fragment />
						}
					</div>
					<div className="taskEventTime">{date}</div>
					<div className="taskStatus">{status()}</div>
					<div className="foundEmailAddress">
						{
							typeof this.props.item.failedVerificationEmail !== 'undefined'
							?
							<div>{this.props.item.failedVerificationEmail}</div>
							:
							<React.Fragment />
						}
						{
							typeof this.props.item.result !== "undefined" && this.props.item.result.payload.length > 0 ?
							<a href={"mailto:" + email + "?&Content-Type=text/html&body=%0D%0A%0D%0ASent with help from www.poirot.app"}>{email}</a>
							:
							<React.Fragment />	
						}
					</div>

					<div className="contactTags">
					{
						typeof this.props.item.result !== "undefined" && this.props.item.result.payload.length > 0
						?
									<Tags activeTeamData={this.props.activeTeamData}
										  displayTagSelector={this.props.displayTagSelector}
										  displayTagRemovalSelector={this.props.displayTagRemovalSelector}
										  userTags={this.props.userTags}
										  taskId={this.props.item.id}
										  email={email}
										  label={typeof this.props.item.result.payload[0].label === 'undefined' ? undefined : this.props.item.result.payload[0].label}
										  refreshTaskData={this.props.refreshTaskData} />
										  :
										  <React.Fragment />
					}
					</div>
				
					
				</div>

				
			</div>
		);
	}
}

export default TaskVerifier;