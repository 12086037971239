import React, { Component } from 'react';
import axios from 'axios';

let settings = require("./settings");

//
// Set time interval to constantly fetch user team data.
//
var emailConfirmationInterval;

class EmailConfirmation extends Component {
    constructor(props) {
        super(props)

        this.resendConfirmationEmail = this.resendConfirmationEmail.bind(this);
    }

    resendConfirmationEmail(event) {
        event.preventDefault();

        var self = this;

        const requestCredentials = {
            "email": this.props.email,
        };

        axios.post(settings.API_HOST+'user/resendemailconfirmation', requestCredentials)
            .then(function (response) {
                if (response.status === 200) {
                    self.props.createNotification('success', 'Email sent.', 'Success', 6000);
                    return
                } else {
                    self.props.createNotification('error', 'Internal server error.', 'Error', 6000);
                    return
                }

        })
            .catch(function (error) {
                self.props.createNotification('error', 'API server error occurred. Please try again.', 'Error', 6000);
                return
        });
    }

    componentDidMount() {
        var self = this;
        emailConfirmationInterval = setInterval(function () {
            self.props.refreshUserData();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(emailConfirmationInterval);
    }

    render() {
        let emailConfirmationSplashImage = settings.CDN_HOST + "images/emailConfirmationSplash.svg";

        return(
            <div className="emailConfirmationWrapper">
                <div className="emailConfirmation">
                    <div>
                        <img
                                src={emailConfirmationSplashImage}
                                alt="Poirot Team Email Confirmation - https://poirot.app"
                                width="360"
                                height="480"
                            />
                    </div>
                    <div className="emailConfirmationActionAreaWrapper">
                        <div>
                            <h1>Confirm your email address</h1>
                        </div>
                        <div>  
                            <div><h3>We sent an email to<br /></h3></div>
                            <div><span>{this.props.email}</span></div>
                        </div>
                        <div>
                            <h4>Please confirm your email address by clicking the link we just sent to your inbox</h4>
                        </div>
                        <div>
                            <button className="defaultButtonGreen" onClick={this.resendConfirmationEmail}>Resend verification email</button>
                        </div>
                    </div>
                </div>
            </div>       
        )
    }
};

export default EmailConfirmation;