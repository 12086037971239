import React, { Component } from "react";

import axios from "axios";

let settings = require("./settings");

class SetNewPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			password: "",
			honeyPot: "",
			linkIsConfirmed: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handlePasswordSetRequest = this.handlePasswordSetRequest.bind(
			this
		);
	}

	componentDidMount() {
		//
		// Ckeck if unique link is still active
		//
		var self = this;

		axios
			.post(settings.API_HOST + "user/verifylink", {
				uniqueLink: self.props.match.params.link,
			})
			.then(function (response) {
				if (response.status === 200) {
					self.setState({ linkIsConfirmed: true });
				} else {
					window.location.href =
						settings.WEB_HOST + "passwordresetfailure";
				}
			})
			.catch(function (error) {
				window.location.href =
					settings.WEB_HOST + "passwordresetfailure";
			});
	}

	handleInputChange(event) {
		const target = event.target;
		var value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handlePasswordSetRequest(event) {
		event.preventDefault();

		const requestCredentials = {
			password: this.state.password,
			uniqueLink: this.props.match.params.link,
		};

		if (this.state.honeyPot !== "") {
			return;
		}

		if (!/^([a-zA-Z0-9@*#._$&!%-]{6,128})$/.test(this.state.password)) {
			this.props.createNotification(
				"error",
				"Please enter a valid password. Minimum length for the password is 6 characters. Valid characters are A-Z, a-z, 0-9 and @*#._$&!%-",
				"Error - password",
				7000
			);
			return;
		}

		var self = this;

		axios
			.post(settings.API_HOST + "user/setpassword", requestCredentials)
			.then(function (response) {
				if (response.status === 200) {
					window.location.href =
						settings.WEB_HOST + "passwordresetsuccess";
				} else {
					self.props.createNotification(
						"error",
						"Internal server error.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				self.props.createNotification(
					"error",
					"API server error occurred. Please try again.",
					"Error",
					6000
				);
			});
	}

	render() {
		let poirotLogo = settings.CDN_HOST + "images/PoirotLogoDark.svg";

		const content = () => {
			if (this.state.linkIsConfirmed) {
				return (
					<form
						className="authenticationForm"
						onSubmit={this.handlePasswordSetRequest}
					>
						<div>
							<h1>Set your new password</h1>
						</div>
						<div>
							<div>
								<h2>Enter new account password bellow.</h2>
							</div>
							<div>
								<input
									autoFocus
									name="password"
									type="password"
									placeholder="Your new password"
									value={this.state.password}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="honeyPot">
								<input
									name="honeyPot"
									type="text"
									placeholder="honeyPot"
									value={this.state.honeyPot}
									onChange={this.handleInputChange}
								/>
							</div>
						</div>
						<div>
							<button className="defaultButtonBlue">
								Submit
							</button>
						</div>
					</form>
				);
			}
		};

		return (
			<div className="fullScreenWrapper">
				<div className="authenticationSectionWrapper">
					<div className="authenticationSectionHeader">
						<div className="logoWrapper">
							<a href={settings.WEB_HOST}>
								<img
									src={poirotLogo}
									alt="Poirot Application Logo - https://poirot.app"
								/>
							</a>
						</div>
					</div>
					<div className="resetPasswordWrapper">
						{content()}
					</div>
					<div className="authenticationSectionFooter">
						<div>
							<a href="/signin">
								Old password remembered? Sign in.
							</a>
						</div>
						<div>|</div>
						<div>
							<a href="https://www.poirot.app/systemstatus">
								System status
							</a>
						</div>
						<div>|</div>
						<div>
							<p>
								Copyright &copy; {new Date().getFullYear()}{" "}
								Intuos llc. All rights reserved.
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SetNewPassword;
