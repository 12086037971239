import React from "react";
import ReactDOM from "react-dom";
import 'normalize.css';
import './css/style.css';
import './css/notification.css';
import './css/phoneInput.css'
import AppLoader from "./AppLoader";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
	<React.StrictMode>
		<AppLoader />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
