import React, { Component } from 'react';

import TeamStatusSet from './blocks/teamStatusSet';
import TeamMembers from './blocks/teamMembers';
import TeamMemberInvite from './blocks/teamMemberInvite';
import Limitations from './blocks/limitations';

class TeamPanel extends Component {

    render() {

    	let panelContent = () => {
    		if (this.props.newTeamInputIsVisible) {
    			return (
    				<div className="teamPanelTeamName">
    					<h1>New team creation in process</h1>
					</div>
    			)
    		} else {
    			return (
    				<React.Fragment>
						<div className="teamPanelTeamName">
	                    	<h1>{this.props.listOfTeams[this.props.selectedTeamIndex].name}</h1>
	                    </div>
	                    <div className="teamPanelActivitySwitch">
	                    	<TeamStatusSet  createNotification={this.props.createNotification}
	                    					listOfTeams={this.props.listOfTeams}
	                    					selectedTeamIndex={this.props.selectedTeamIndex}
	                    					updateListOfTeams={this.props.updateListOfTeams}/>
	                    </div>
	                    <div className="teamPanelTeamMembers">
	                    	<TeamMembers createNotification={this.props.createNotification}
                                         listOfTeams={this.props.listOfTeams}
                    					 selectedTeamIndex={this.props.selectedTeamIndex}
                    					 userEmailAddress={this.props.userEmailAddress}
                                         updateListOfTeams={this.props.updateListOfTeams}/>
	                    </div>
	                    <div className="teamPanelSendInvitation">
	                    	<TeamMemberInvite createNotification={this.props.createNotification}
                                              listOfTeams={this.props.listOfTeams}
                                              selectedTeamIndex={this.props.selectedTeamIndex}
                                              updateListOfTeams={this.props.updateListOfTeams}/>
	                    </div>
	                    <div className="teamPanelUsageLimitations">
	                    	<Limitations  key={this.props.listOfTeams[this.props.selectedTeamIndex].id}
					                      createNotification={this.props.createNotification}
	                    				  listOfTeams={this.props.listOfTeams}
	                    				  selectedTeamIndex={this.props.selectedTeamIndex}
	                    				  updateListOfTeams={this.props.updateListOfTeams}/>
                    	</div>
                    </React.Fragment>
    			)
    		}
    	}

        return(
            <div className="teamPanelWrapper">
                <div className="teamPanel">
                    {panelContent()}
                </div>
            </div>       
        )
    }
};

export default TeamPanel;