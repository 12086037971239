import axios from 'axios';

let settings = require('./settings');

export function setAccessToken(access_token, storage) {
    if (storage === "localStorage") {
        localStorage.setItem('TOKEN_KEY', access_token);
    } else {
        sessionStorage.setItem('TOKEN_KEY', access_token);
    }
}

export function removeAccessToken() {
    localStorage.removeItem('TOKEN_KEY');
    sessionStorage.removeItem('TOKEN_KEY');
}

export function getToken() {
    var token;
    var storage;

    if (localStorage.getItem('TOKEN_KEY') !== null) {
        token = localStorage.getItem('TOKEN_KEY');
        storage = "localStorage";
    } else if (sessionStorage.getItem('TOKEN_KEY') !== null) {
        token = sessionStorage.getItem('TOKEN_KEY');
        storage = "sessionStorage";
    } else {
        return false;
    }

    return {token:token, storage: storage};
}

export async function isAuthenticated() {
    let tokenHolder = getToken()

    if (!tokenHolder) { return false };

    var result = false;

    try {
        let timestamp = new Date().getTime() // Timestamp is required to prevent browser caching of requests.
        const response = await axios.get(settings.API_HOST+'user/validatetoken?nocache='+timestamp, { headers: {'Authorization': "Bearer " + tokenHolder.token} })
            if (response.status === 200) {
                result = true;
            }
        } catch (error) {
            console.log(error);
        }

    return result
}

export async function getUserData() {
    let tokenHolder = getToken()

    if (!tokenHolder) { return null }; 

    var result = null;

    try {
        let timestamp = new Date().getTime() // Timestamp is required to prevent browser caching of requests.
        const response = await axios.get(settings.API_HOST+'user/data?nocache='+timestamp, { timeout: 10000, headers: {'Authorization': "Bearer " + tokenHolder.token} })
            if (response.status === 200) {
                result = response.data;
            }
        } catch (error) {
            result = "unavailable"
        }

        return result
}

export async function getPlansData() {
    let tokenHolder = getToken()

    if (!tokenHolder) { return null }; 

    var result = null;

    try {
        let timestamp = new Date().getTime() // Timestamp is required to prevent browser caching of requests.
        const response = await axios.get(settings.API_HOST+'user/plans?nocache='+timestamp, { timeout: 10000, headers: {'Authorization': "Bearer " + tokenHolder.token} })
            if (response.status === 200) {
                result = response.data;
            }
        } catch (error) {
            result = "unavailable"
        }

        return result
}

export async function getPermanentCreditsProduct() {
    let tokenHolder = getToken()

    if (!tokenHolder) { return null }; 

    var result = null;

    try {
        let timestamp = new Date().getTime() // Timestamp is required to prevent browser caching of requests.
        const response = await axios.get(settings.API_HOST+'user/permanentcredits?nocache='+timestamp, { timeout: 10000, headers: {'Authorization': "Bearer " + tokenHolder.token} })
            if (response.status === 200) {
                result = response.data;
            }
        } catch (error) {
            result = "unavailable"
        }

        return result
}

export async function getListOfTeamsWhereUserIsMaster() {
    let tokenHolder = getToken()

    if (!tokenHolder) { return null }; 

    var result = null;

    try {
        let timestamp = new Date().getTime() // Timestamp is required to prevent browser caching of requests.
        const response = await axios.get(settings.API_HOST+'team/list?nocache='+timestamp, { timeout: 10000, headers: {'Authorization': "Bearer " + tokenHolder.token} })
            if (response.status === 200) {
                result = response.data;
            } else if (response.status === 204) {
                result = response.statusText
            }
        } catch (error) {
            result = "unavailable"
        }

        return result
}

export async function getActiveTeamData() {
    let tokenHolder = getToken()

    if (!tokenHolder) { return null }; 

    var result = null;

    try {
        let timestamp = new Date().getTime() // Timestamp is required to prevent browser caching of requests.
        const response = await axios.get(settings.API_HOST+'team/data?nocache='+timestamp, { timeout: 10000, headers: {'Authorization': "Bearer " + tokenHolder.token} })
            if (response.status === 200) {
                result = response.data;
            }
        } catch (error) {
            result = "unavailable"
        }

        return result
}