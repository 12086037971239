import React, { Component } from 'react';

import CreditCards from '../../appComponents/blocks/creditCards'
import PayPal from '../../appComponents/blocks/payPal'

class PaymentMethods extends Component {

    render() {

        return(
            <div className="paymentMethodsWrapper">
				<div className="paymentMethods">
					<div className="paymentMethodBlock">
		            	<CreditCards createNotification={this.props.createNotification}
		            				 refreshUserData={this.props.refreshUserData}
		            				 userData={this.props.userData}/>
	            	</div>
	            	<div className="paymentMethodBlock">
		            	<PayPal createNotification={this.props.createNotification}
		            			payPalTokenPath={this.props.payPalTokenPath}
								setPayPalTokenPathToNull={this.props.setPayPalTokenPathToNull}/>
	            	</div>
            	</div>
            </div>       
        )
    }
};

export default PaymentMethods;