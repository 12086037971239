import React, { Component } from "react";
import axios from "axios";

let settings = require("../../settings");
let authentication = require("../../authentication");

class tagLabel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			label: this.props.item.label,
		};

		this.labelInput = React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.focusTextInput = this.focusTextInput.bind(this);
		this.handleLabelChange = this.handleLabelChange.bind(this);
	}

	handleLabelChange(event) {
		event.preventDefault();

		if (this.props.item.label === this.state.label) {
			this.labelInput.current.blur();
			return;
		} else {
			let tokenHolder = authentication.getToken();

			if (!tokenHolder) {
				return;
			}

			const requestCredentials = {
				id: this.props.item.id,
				label: this.state.label,
			};

			var self = this;

			axios
				.patch(settings.API_HOST + "tag", requestCredentials, {
					headers: { Authorization: "Bearer " + tokenHolder.token },
				})
				.then(function (response) {
					if (response.status === 200) {
						self.props.refreshUserData();
						self.props.refreshActiveTeamData();
						self.labelInput.current.blur();
					} else {
						self.props.createNotification(
							"error",
							"An error occurred while trying to set new label. Please try again later.",
							"Error - Label change",
							7000
						);
					}
				})
				.catch(function (error) {
					self.props.createNotification(
						"error",
						"An error occurred while trying to set new label. Please try again later.",
						"Error - Label change",
						6000
					);
				});
		}
	}

	handleInputChange(event) {
		const target = event.target;
		var value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	// handleSingleClick(event) {
	// 	event.preventDefault();
	// 	event.stopPropagation();

	// 	this.props.selectTag()
	// }

	handleSingleClick(event) {
		event.preventDefault();
		event.stopPropagation();
		this.props.selectTag(this.props.index, this.props.item.id);
	}

	focusTextInput(event) {
		event.preventDefault();
		this.labelInput.current.focus();
		this.props.focusTag(this.props.index);
	}

	// static getDerivedStateFromProps(props, state) {

	//     if (props.focusedTagIndex !== state.focusedTagIndex) {
	//       return {
	//         focusedTagIndex: props.focusedTagIndex,
	//       };
	//     }
	//     return null;
	// }

	render() {
		let wrapperClass = () => {
			if (this.props.selectedTagIndex === this.props.index) {
				return "tagLabelWrapper labelMarked";
			}
			if (this.props.focusedTagIndex === this.props.index) {
				return "tagLabelWrapper labelWrapperInFocus";
			}
			return "tagLabelWrapper";
		};

		// let wrapperClass = () => {
		// 	if (typeof this.props.selectedTagIndex !== 'undefined') {
		// 		switch (this.props.selectedTagIndex) {
		// 			case (this.props.index):
		// 				if (this.state.focusedTagIndex === this.state.index) {
		// 					return "tagLabelWrapper labelWrapperInFocus";
		// 				} else {
		// 					return "tagLabelWrapper labelMarked"
		// 				}
		// 			default:
		// 				return "tagLabelWrapper";
		// 		}
		// 	} else {
		// 		return "tagLabelWrapper"
		// 	}
		// }

		return (
			<div
				className={wrapperClass()}
				onDoubleClick={(event) => this.focusTextInput(event)}
				onClick={(event) => this.handleSingleClick(event)}
			>
				<div className="tagLabelColor">
					<svg
						height="14"
						width="14"
						onClick={(event) =>
							this.props.selectColor(
								event,
								this.props.index,
								this.props.item.id
							)
						}
					>
						<circle
							cx="7"
							cy="7"
							r="7"
							fill={"#" + this.props.item.color}
						/>
					</svg>
				</div>
				<div className="tagLabel">
					<form onSubmit={(event) => this.handleLabelChange(event)}>
						<input
							readOnly={
								this.props.focusedTagIndex !== this.props.index
							}
							ref={this.labelInput}
							type="text"
							name={"label"}
							value={this.state.label}
							onChange={this.handleInputChange}
							onBlur={(event) => this.handleLabelChange(event)}
						/>
						</form>
				</div>
			</div>
		);
	}
}

export default tagLabel;
