import React, { Component } from "react";


//   const copyToClipboard = str => {
//   const el = document.createElement('textarea');
//   el.value = str;
//   document.body.appendChild(el);
//   el.select();
//   document.execCommand('copy');
//   document.body.removeChild(el);
// };

class ShareLink extends Component {

	copyToClipboard(str) {
		const el = document.createElement('textarea');
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);

		this.props.createNotification(
				"success",
				"Referral link copied to clipboard.",
				"Referral link",
				7000
			);
	}

	render() {
		var referralLink = "https://poirot.app/?ref=" + this.props.userData.referralLink
		return (
			<div className="shareLinkWrapper">
				<div><h1>Share your link</h1></div>
    				<div className="shareLink">
    					<h4>
    						Copy your personal referral link and share it with your friends and followers.
						</h4>
					</div>
					<div className="shareLinkAction">
						<div className="shareLinkToken" onClick={() => this.copyToClipboard(referralLink)}>
							<div className="tokenKey">{referralLink}</div>
						</div>
						<div>
							<a href={"http://twitter.com/intent/tweet?text=Find your prospects email address in seconds. Get 100 search credits from @PoirotApp using my link: " + referralLink} id="twitter-referral-share" target="_blank" title="Share on Twitter" rel="noopener noreferrer" className="defaultButtonBlue">Share via Twitter</a>
						</div>
        			</div>
			</div>
		)
	}
}

export default ShareLink;