import React, { Component, createRef } from "react";

import LoaderAnimation from "./LoaderAnimation";
import EmailConfirmation from "./emailConfirmation";
import Header from "./appComponents/header.js";
import Content from "./appComponents/content.js";
import Footer from "./appComponents/footer.js";

import TagSelector from './appComponents/blocks/tagSelector';
import TagRemovalSelector from './appComponents/blocks/tagRemovalSelector';
import TaskRemovalSelector from './appComponents/blocks/taskRemovalSelector';


let authentication = require("./authentication");

//
// Set time interval to constantly fetch user team data.
//
var refreshUserDataInterval;
var refreshIfUnavailableInterval;

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentProduct: null,
			mode: this.props.entryPoint,
			helperPanelMode: "details",
			plansData: null,
			userData: null,
			permanentCreditsData: null,
			payPalTokenPath: this.props.payPalTokenPath,
			googleContacts: null,
			activeTeamData: null,
			isActiveTeamTeamMaster: false,
			teamsWhereUserIsMaster: null,
			tagSelectorVisible: false,
			tagSelectedTagId: undefined,
			tagSelectedTaskLabel: undefined,
			tagSelectedTaskId: undefined,
			tagSelectedEmail: undefined,
			refreshTaskData: undefined,
			tagRemovalVisible: false,
			detailsHelperPanelSource: undefined,
			taskRemovalVisible: false,
			selectedTaskForRemoval: undefined,
			resultsPerPage: 100,
		};

		this.tagSelector = React.createRef();
		this.tagRemovalSelector = React.createRef();
		this.taskRemovalSelector = React.createRef();


		this.refreshUserData = this.refreshUserData.bind(this);
		this.refreshActiveTeamData = this.refreshActiveTeamData.bind(this);
		this.switchModeTo = this.switchModeTo.bind(this);
		this.switchHelperPanelModeTo = this.switchHelperPanelModeTo.bind(this);
		this.setPayPalTokenPathToNull = this.setPayPalTokenPathToNull.bind(this);
		this.displayWorkspaceChangeNotification = this.displayWorkspaceChangeNotification.bind(this);

		this.displayTagSelector = this.displayTagSelector.bind(this);
		this.hideTagSelector = this.hideTagSelector.bind(this);

		this.displayTagRemovalSelector = this.displayTagRemovalSelector.bind(this);
		this.hideTagRemovalSelector = this.hideTagRemovalSelector.bind(this);

		this.workspaceChangeNotificationRef = createRef();

		this.setDetailsHelperPanelSource = this.setDetailsHelperPanelSource.bind(this);

		this.handleTaskRemoval = this.handleTaskRemoval.bind(this)
		this.hideTaskRemovalSelector = this.hideTaskRemovalSelector.bind(this)

		this.setResultsPerPage = this.setResultsPerPage.bind(this);

		this.refreshIfUnavailable = this.refreshIfUnavailable.bind(this)
	}

	setResultsPerPage(amount) {
		this.setState({
			resultsPerPage: amount
		})
	}


	handleTaskRemoval(event, taskId, refreshTaskData) {
		this.setState({
			selectedTaskForRemoval: taskId,
			taskRemovalVisible: true,
			refreshTaskData: refreshTaskData
		})

		this.taskRemovalSelector.current.style.top = (event.clientY + 15).toString() + "px";
        this.taskRemovalSelector.current.style.left = (event.clientX - 60).toString() + "px";
        this.taskRemovalSelector.current.style.display = "block";
	}

	setDetailsHelperPanelSource(source) {
		this.setState({
			detailsHelperPanelSource: source
		})
	}

	displayTagSelector(event, label, tagSelectedTaskId, tagSelectedEmail, refreshTaskData) {

		if (typeof label !== 'undefined') {
			let tags = this.state.activeTeamData === "unavailable" ? this.state.userData.tags : this.state.activeTeamData.tags
			let availableTags = [];
			tags.forEach(function(item) { availableTags.push(item.id) });

	        if (availableTags.every(val => label.includes(val))) {
	        	return;
	        }
    	}

		this.setState({
			tagSelectorVisible: true,
			tagRemovalVisible: false,
			tagSelectedTaskLabel: label,
			tagSelectedTaskId: tagSelectedTaskId,
			tagSelectedEmail: tagSelectedEmail,
			refreshTaskData: refreshTaskData
		})

		this.tagSelector.current.style.top = (event.clientY - 50).toString() + "px";
        this.tagSelector.current.style.left = (event.clientX + 12).toString() + "px";
        this.tagSelector.current.style.display = "block";
	}

	hideTagSelector() {
		this.setState({
			tagSelectorVisible: false,
			tagRemovalVisible: false,
			tagSelectedTaskLabel: undefined,
			tagSelectedTaskId: undefined,
			tagSelectedEmail: undefined,
			refreshTaskData: undefined
		})
	}

	displayTagRemovalSelector(event, tagId, tagSelectedTaskId, tagSelectedEmail, refreshTaskData) {

		this.setState({
			tagRemovalVisible: true,
			tagSelectorVisible: false,
			tagSelectedTagId: tagId,
			tagSelectedTaskId: tagSelectedTaskId,
			tagSelectedEmail: tagSelectedEmail,
			refreshTaskData: refreshTaskData
		})

		this.tagRemovalSelector.current.style.top = (event.clientY + 15).toString() + "px";
        this.tagRemovalSelector.current.style.left = (event.clientX - 60).toString() + "px";
        this.tagRemovalSelector.current.style.display = "block";
	}

	hideTagRemovalSelector() {
		this.setState({
			tagRemovalVisible: false,
			tagSelectorVisible: false,
			tagSelectedTagId: undefined,
			tagSelectedTaskId: undefined,
			tagSelectedEmail: undefined,
			refreshTaskData: undefined
		})
	}

	hideTaskRemovalSelector() {
		this.setState({
			taskRemovalVisible: false,
			selectedTaskForRemoval: undefined,
			refreshTaskData: undefined
		})
	}

	displayWorkspaceChangeNotification() {
		var self = this;
    	this.workspaceChangeNotificationRef.current.style.display = "flex";
		this.workspaceChangeNotificationRef.current.style.opacity = 1;
		setTimeout(function() { 
			self.workspaceChangeNotificationRef.current.style.opacity = 0;
		}, 800);
		setTimeout(function() { 
			self.workspaceChangeNotificationRef.current.style.display = "none";
		}, 1100);
	}

	refreshActiveTeamData() {
		var self = this

		authentication.getActiveTeamData().then(function (teamData) {
		
			if (teamData !== null || teamData !== "unavailable") {
				authentication.getListOfTeamsWhereUserIsMaster().then(function (teamsWhereUserIsMaster) {
					var userIsTeamMaster = false
					if (teamsWhereUserIsMaster !== "unavailable" && teamsWhereUserIsMaster !== "No Content") {
						teamsWhereUserIsMaster.forEach((item) => {
							if (item.id === teamData.id) {
								userIsTeamMaster = true
							}
						})
					}
					self.setState({teamsWhereUserIsMaster: teamsWhereUserIsMaster, isActiveTeamTeamMaster: userIsTeamMaster})
				})
			} else {
				self.setState({isActiveTeamTeamMaster: false})
			}

			self.setState({activeTeamData: teamData})
		})
	}

	setPayPalTokenPathToNull() {
		this.setState({payPalTokenPath: null})
	}

	refreshUserData() {
		var self = this;

		authentication.getUserData().then(function (userData) {

			if (userData !== null && userData !== "unavailable") {

				var currentProduct = self.state.plansData.find(element => (element.name === userData.currentProductName));

				self.setState({
					userData: userData,
					currentProduct: currentProduct
				});
			}
		});
	}

	switchModeTo(newMode) {
		this.setState({
			mode: newMode,
		});
	}

	switchHelperPanelModeTo(newMode) {
		this.setState({
			helperPanelMode: newMode,
		});
	}

	componentDidMount() {
		var self = this;
		//
		// Fetch user data
		//
		authentication.getUserData().then(function (userData) {
			//
			// Fetch all available subscription plans data
			//
			authentication.getPlansData().then(function (plansData) {
				//
				// Fetch permanent credits data
				//
				authentication.getPermanentCreditsProduct().then(function (permanentCreditsData) {
					//
					// Fetch active team data
					//
					authentication.getActiveTeamData().then(function (teamData) {


						authentication.getListOfTeamsWhereUserIsMaster().then(function (teamsWhereUserIsMaster) {
							var userIsTeamMaster = false
							if (teamData !== "unavailable") {
								if (teamsWhereUserIsMaster !== "unavailable" || teamsWhereUserIsMaster !== "No Content") {
										teamsWhereUserIsMaster.forEach((item) => {
											if (item.id === teamData.id) {
												
												userIsTeamMaster = true
											}
										})
									}
								} 
												
								//
								// Fetch plans metadata by locating current plan
								//
								var currentProduct = plansData.find(element => (element.name === userData.currentProductName));

								self.setState({
									currentProduct: currentProduct,
									plansData: plansData,
									userData: userData,
									activeTeamData: teamData,
									permanentCreditsData: permanentCreditsData,
									teamsWhereUserIsMaster: teamsWhereUserIsMaster,
									isActiveTeamTeamMaster: userIsTeamMaster
								})
						})
					
					});
				});
			});
		});

		// Performs team data refreshes on every 30/60 seconds
		refreshUserDataInterval = setInterval(function () {
			self.refreshUserData();
			self.refreshActiveTeamData();
		}, 60000);
	}

	refreshIfUnavailable() {
				var self = this;
		//
		// Fetch user data
		//
		authentication.getUserData().then(function (userData) {
			//
			// Fetch all available subscription plans data
			//
			authentication.getPlansData().then(function (plansData) {
				//
				// Fetch permanent credits data
				//
				authentication.getPermanentCreditsProduct().then(function (permanentCreditsData) {
					//
					// Fetch active team data
					//
					authentication.getActiveTeamData().then(function (teamData) {


						authentication.getListOfTeamsWhereUserIsMaster().then(function (teamsWhereUserIsMaster) {
							var userIsTeamMaster = false
							if (teamData !== "unavailable") {
								if (teamsWhereUserIsMaster !== "unavailable" || teamsWhereUserIsMaster !== "No Content") {
										teamsWhereUserIsMaster.forEach((item) => {
											if (item.id === teamData.id) {
												
												userIsTeamMaster = true
											}
										})
									}
								} 
												
								//
								// Fetch plans metadata by locating current plan
								//
								var currentProduct = plansData.find(element => (element.name === userData.currentProductName));

								self.setState({
									currentProduct: currentProduct,
									plansData: plansData,
									userData: userData,
									activeTeamData: teamData,
									permanentCreditsData: permanentCreditsData,
									teamsWhereUserIsMaster: teamsWhereUserIsMaster,
									isActiveTeamTeamMaster: userIsTeamMaster
								})
						})
					
					});
				});
			});
		});

		// Performs team data refreshes on every 30/60 seconds
		refreshIfUnavailableInterval = setInterval(function () {
			self.refreshIfUnavailable();
		}, 5000);
	}
	

	componentWillUnmount() {
		clearInterval(refreshUserDataInterval);
		clearInterval(refreshIfUnavailableInterval);
	}



	render() {

		if (this.state.userData === null || this.state.plansData === null || this.state.currentProduct === null || this.state.permanentCreditsData === null || this.state.activeTeamData === null || this.state.teamsWhereUserIsMaster === null) {
			return (
					<LoaderAnimation />
			)
		} else if (
			this.state.userData === "unavailable" ||
			this.state.plansData === "unavailable" ||
			this.state.currentProduct === "unavailable" ||
			this.state.permanentCreditsData === "unavailable"
		) {
			clearInterval(refreshIfUnavailableInterval);
			return (
				<LoaderAnimation />
			)
		} else {
			return (
				<React.Fragment>
				{!this.state.userData.emailIsVerified ? <EmailConfirmation  createNotification={this.props.createNotification}
																			refreshUserData={this.refreshUserData}
																			email={this.state.userData.email}/> 
																			: 
																			<React.Fragment/>}
				<div className="App noselect">
					<div className="WorkspaceChangeNotification" ref={this.workspaceChangeNotificationRef}>
						<h1>Workspace changed</h1>
					</div>
					<Header
						createNotification={this.props.createNotification}
						currentProduct={this.state.currentProduct}
						mode={this.state.mode}
						switchModeTo={this.switchModeTo}
						userEmailAddress={this.state.userData.email}
						userName={this.state.userData.name}
						teams={this.state.userData.teams}
						refreshUserData={this.refreshUserData}
						refreshActiveTeamData={this.refreshActiveTeamData}
						activeTeamData={this.state.activeTeamData}
						isActiveTeamTeamMaster={this.state.isActiveTeamTeamMaster}
						displayWorkspaceChangeNotification={this.displayWorkspaceChangeNotification}
						teamsWhereUserIsMaster={this.state.teamsWhereUserIsMaster}
					/>
					<Content
						activeTeamData={this.state.activeTeamData}
						createNotification={this.props.createNotification}
						currentProduct={this.state.currentProduct}
						displayTagSelector={this.displayTagSelector}
						displayTagRemovalSelector={this.displayTagRemovalSelector}
						handleTaskRemoval={this.handleTaskRemoval}
						plansData={this.state.plansData}
						mode={this.state.mode}
						helperPanelMode={this.state.helperPanelMode}
						refreshUserData={this.refreshUserData}
						refreshActiveTeamData={this.refreshActiveTeamData}
						switchModeTo={this.switchModeTo}
						switchHelperPanelModeTo={this.switchHelperPanelModeTo}
						userData={this.state.userData}
						userEmailAddress={this.state.userData.email}
						payPalTokenPath={this.state.payPalTokenPath}
						permanentCreditsData={this.state.permanentCreditsData}
						setPayPalTokenPathToNull={this.setPayPalTokenPathToNull}
						googleTokenPath={this.props.googleTokenPath}
						setDetailsHelperPanelSource={this.setDetailsHelperPanelSource}
						source={this.state.detailsHelperPanelSource}
						resultsPerPage={this.state.resultsPerPage}
						setResultsPerPage={this.setResultsPerPage}
					/>
					<Footer
						createNotification={this.props.createNotification}
						currentProductName={
							this.state.userData.currentProductName
						}
						defaultMonthlyLookupCredits={
							this.state.userData.defaultMonthlyLookupCredits
						}
						defaultMonthlyVerificationCredits={
							this.state.userData
								.defaultMonthlyVerificationCredits
						}
						lastRenewal={this.state.userData.lastRenewal}
						monthlyLookupCredits={
							this.state.userData.monthlyLookupCredits
						}
						monthlyVerificationCredits={
							this.state.userData.monthlyVerificationCredits
						}
						permanentCredits={this.state.userData.permanentCredits}
						plansData={this.state.plansData}
						refreshUserData={this.refreshUserData}
						switchModeTo={this.switchModeTo}
						userEmailAddress={this.state.userData.email}
						userName={this.state.userData.name}
						downgradeActive={this.state.userData.downgradeActive}
						activeTeamData={this.state.activeTeamData}
						isActiveTeamTeamMaster={this.state.isActiveTeamTeamMaster}
					/>
				</div>
				<div ref={this.tagSelector} className="tagSelectorWrapper" onMouseLeave={this.hideTagSelector}>
					{this.state.tagSelectorVisible ? 
						<TagSelector createNotification={this.props.createNotification}
									 activeTeamData={this.state.activeTeamData}
									 userTags={this.state.userData.tags}
									 tagSelectedTaskLabel={this.state.tagSelectedTaskLabel}
									 tagSelectedTaskId={this.state.tagSelectedTaskId}
									 tagSelectedEmail={this.state.tagSelectedEmail}
									 hideTagSelector={this.hideTagSelector}
									 refreshTaskData={this.state.refreshTaskData}
									 /> 
						: 
						<React.Fragment />}
				</div>
				<div ref={this.tagRemovalSelector} className="tagRemovalSelectorWrapper" onMouseLeave={this.hideTagRemovalSelector}>
					{this.state.tagRemovalVisible ? 
						<TagRemovalSelector createNotification={this.props.createNotification}
									 tagSelectedTagId={this.state.tagSelectedTagId}
									 tagSelectedTaskId={this.state.tagSelectedTaskId}
									 tagSelectedEmail={this.state.tagSelectedEmail}
									 hideTagRemovalSelector={this.hideTagRemovalSelector}
									 refreshTaskData={this.state.refreshTaskData}
									 /> 
						: 
						<React.Fragment />}
				</div>
				<div ref={this.taskRemovalSelector} className="taskRemovalSelectorWrapper" onMouseLeave={this.hideTaskRemovalSelector}>
					{this.state.taskRemovalVisible ? 
						<TaskRemovalSelector createNotification={this.props.createNotification}
									 selectedTaskForRemoval={this.state.selectedTaskForRemoval}
									 hideTaskRemovalSelector={this.hideTaskRemovalSelector}
									 refreshTaskData={this.state.refreshTaskData}
									 /> 
						: 
						<React.Fragment />}
				</div>
				</React.Fragment>
			);
		}
	}
}

export default App;
