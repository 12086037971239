import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';

import PurchaseCreditsForm from '../../appComponents/purchaseCreditsForm'
import PurchaseCreditsFormNewCard from '../../appComponents/purchaseCreditsFormNewCard'

class PurchaseCredits extends Component {
    constructor(props) {
        super(props)

        this.state=({
            volume: 10000,
            purchaseCredits: false,
        })

        this.handleValueChange = this.handleValueChange.bind(this)
        this.togglePurchaseCreditsVisibility = this.togglePurchaseCreditsVisibility.bind(this)
    }

    togglePurchaseCreditsVisibility() {

        this.setState({purchaseCredits: !this.state.purchaseCredits})
    }

    handleValueChange(event, value) {
        if (this.state.volume !== value) {
            //
            // Due to linear length of slide line, it's better to swap value 30000 on line for 50000 in real credits.
            //
            if (value === 500) { value = 250 }
            this.setState({
                volume: value
            })

            if (value === 30000) { value = 60000 }
            this.setState({
                volume: value
            })
        }
    }

    render() {
        //
        // Setting the slider
        // 
        const marks = [{value: 500,},{value: 1000,},{value: 5000,},{value: 10000,},{value: 20000,},{value: 30000,}];

        var kIdentificatorCredits
        var volume = this.state.volume

        var unitPrice
        switch (volume) {
            case 60000:
                unitPrice = 0.03
                break
            case 20000:
                unitPrice = 0.04
                break
            case 10000:
                unitPrice = 0.05
                break
            case 5000:
                unitPrice = 0.06
                break
            case 1000:
                unitPrice = 0.15
                break
            default:
                unitPrice = 0.2
        }

        if (volume > 9999) {
            volume = Math.floor(volume / 1000)
            kIdentificatorCredits = "K"
        }

        ///
        /// Check if user has valid payment methods
        ///
        var date = new Date();
        var month = date.getUTCMonth() + 1;
        var year = date.getUTCFullYear();
        const hasValidPaymentMethod = (this.props.userData.paymentMethods.find(function(card) {
            return (!(card.expMonth < month && card.expYear <= year) && !(card.expYear < year)) 
            })) === undefined ? false : true;

        var content = () => {
            if (this.state.purchaseCredits && hasValidPaymentMethod) {
                return (
                    <PurchaseCreditsForm createNotification={this.props.createNotification}
                                         refreshUserData={this.props.refreshUserData}
                                         togglePurchaseCreditsVisibility={this.togglePurchaseCreditsVisibility}
                                         userData={this.props.userData}
                                         volume={this.state.volume}
                                         unitPrice={unitPrice}/>
                )
            } else if (this.state.purchaseCredits && !hasValidPaymentMethod) {
                return (
                    <PurchaseCreditsFormNewCard createNotification={this.props.createNotification}
                                                refreshUserData={this.props.refreshUserData}
                                                togglePurchaseCreditsVisibility={this.togglePurchaseCreditsVisibility}
                                                userData={this.props.userData}
                                                volume={this.state.volume}
                                                unitPrice={unitPrice}/>
                )
            }
        }
        return (
            <React.Fragment>
                {content()}
                <div className="purchaseCreditsBlock">
                    <h1>Purchase permanent credits</h1>
                	<div className="purchaseCreditsWrapper">

                        <div className="purchaseCreditsSliderWrapper">
                            <div className="chartName">Select desired volume</div>
                            <div>
                                <Slider classes={
                                                { root: 'sliderRoot', mark: 'sliderMark', rail: 'sliderRail', track: 'sliderTrack', thumb: 'sliderThumb', active: 'sliderThumbActive' }
                                            }   min={500} max={30000} value={this.state.volume} marks={marks} step={null} 
                                                onChange={this.handleValueChange}/>
                            </div>
                        </div>

                        <div className="purchaseCreditsChartWrapper">
                            <div className="chartName">Permanent credits</div>
                            <div className="purchaseCreditsChartStatus">{volume}<span className="kIdentificator">{kIdentificatorCredits}</span></div> 
                        </div>

                        <div className="purchaseCreditsPricingWrapper">
                            <div className="chartName">Pricing</div>
                            <div className="purchaseCreditsPricing">${unitPrice}<span>/credit</span></div> 
                        </div>

                        <div className="purchaseCreditsPricingWrapper">
                            <div className="chartName">Total price</div>
                            <div className="purchaseCreditsPricing">${Math.floor(this.state.volume * unitPrice)}</div> 
                        </div>

                        <div className="purchaseCreditsButton"><button onClick={(event) => this.togglePurchaseCreditsVisibility(event, this.state.volume)} className="defaultButtonGreen">Purchase {(this.state.volume).toLocaleString()} credits</button></div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};

export default PurchaseCredits;