import React, { Component } from "react";
import axios from 'axios';


let settings = require("../../settings");
let authentication = require("../../authentication");

class TeamMemberInvite extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInvite = this.handleInvite.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    handleInvite(event) {
        event.preventDefault();

        const requestCredentials = {
            "memberEmail": this.state.email,
            "teamId": this.props.listOfTeams[this.props.selectedTeamIndex].id
        };

        if (this.state.email === "") {
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

        if (!/^[a-z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/.test(this.state.email)) {
            this.props.createNotification('error', 'Please enter a valid email address.', 'Error - email', 6000);
            return
        }

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            this.setState({
                email: ""
            })
            return;
        }

        axios.post(settings.API_HOST+'team/user/invite', requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.props.createNotification('success', 'User has been invited to the team.', 'Success - Invitatation Sent', 6000);
                    self.setState({email: ""})
                    self.props.updateListOfTeams();
                    return
                } else {
                    self.props.createNotification('error', 'Internal server error.', 'Error', 6000);
                }

        })
            .catch(function (error) {
                if (error.response.status === 409) {
                    self.props.createNotification(
                        "error",
                        "Member already added.",
                        "Error - Member Invite",
                        6000
                    );
                    self.setState({
                        email: ""
                    })
                } else {
                self.props.createNotification('error', 'API server error occurred. Please try again.', 'Error', 6000);
            }
        });
    }

    render() {
        return (
            <div className="teamMemberInvite">
                <h2>Welcome your coworkers</h2>
                <form onSubmit={this.handleInvite}>
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter an email address to invite new member"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                    />
                    <button type="submit" className="defaultButtonBlue">Send invitation</button>
                </form>
            </div>
        );
    }
}

export default TeamMemberInvite;
