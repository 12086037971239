import React, { Component } from "react";
import LoaderAnimation from "../LoaderAnimation";
import axios from "axios";

import TeamsList from "../appComponents/teamsList";
import TeamPanel from "../appComponents/teamPanel";
import TeamRemoval from "../appComponents/blocks/teamRemoval";

let settings = require("../settings");
let authentication = require("../authentication");

var refreshListOfTeamsInterval;

class Teamwork extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listOfTeams: null,
            newTeamName: "",
            newTeamInputIsVisible: "",
            teamUpdateInProcess: false,
            selectedTeamIndex: 0,
            visibleRemovalConfirmation: false,
        };

        this.toggleActiveTeamRemoval = this.toggleActiveTeamRemoval.bind(this);
        this.toggleNewTeamInputVisibility = this.toggleNewTeamInputVisibility.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleNewTeamCreation = this.handleNewTeamCreation.bind(this);
        this.permanentlyRemoveTeam = this.permanentlyRemoveTeam.bind(this);
        this.selectTeam = this.selectTeam.bind(this);
        this.updateListOfTeams = this.updateListOfTeams.bind(this);
        this.toggleTeamUpdateProcess = this.toggleTeamUpdateProcess.bind(this);
    }

    componentDidMount() {
        var self = this;
        this.updateListOfTeams();

        // Performs team data refreshes on every 30/60 seconds
        refreshListOfTeamsInterval = setInterval(function () {
            self.updateListOfTeams();
        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(refreshListOfTeamsInterval);
    }

    toggleActiveTeamRemoval() {
        this.setState({
            visibleRemovalConfirmation: !this.state.visibleRemovalConfirmation,
        });
    }

    toggleTeamUpdateProcess() {
        this.setState({
            teamUpdateInProcess: !this.state.teamUpdateInProcess,
        });
    }

    toggleNewTeamInputVisibility() {
        this.setState({
            newTeamInputIsVisible: !this.state.newTeamInputIsVisible,
        })
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    handleNewTeamCreation(event) {
        event.preventDefault();

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const requestCredentials = {
            name: this.state.newTeamName,
        };

        if (this.state.newTeamName === "") {
            this.props.createNotification(
                "error",
                "Please enter a valid team name.",
                "Error - team name",
                6000
            );
            return;
        }
        if (
            this.state.newTeamName.length < 2 ||
            this.state.newTeamName.length > 32
        ) {
            this.props.createNotification(
                "error",
                "Please enter a valid team name. Name should be between 2 and 32 characters long.",
                "Error - team name",
                7000
            );
            return;
        }

        var self = this;

        axios
            .post(settings.API_HOST + "team", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    authentication
                        .getListOfTeamsWhereUserIsMaster()
                        .then(function (listOfTeams) {
                            self.setState({
                                listOfTeams: listOfTeams,
                                newTeamName: "",
                            });
                            self.props.refreshUserData();
                        });
                    return;
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
    }

    permanentlyRemoveTeam(event) {
        event.preventDefault();

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            self.toggleActiveTeamRemoval();
            return;
        }

        const headers = { Authorization: "Bearer " + tokenHolder.token };

        const data = {
            id: this.state.listOfTeams[this.state.selectedTeamIndex].id,
        };

        axios
            .delete(settings.API_HOST + "team", { headers, data })
            .then(function (response) {
                if (response.status === 200) {
                    self.setState({
                        selectedTeamIndex: 0,
                    });
                    self.props.createNotification(
                        "success",
                        "Team has been successfully removed.",
                        "Success",
                        6000
                    );
                    self.updateListOfTeams();
                    self.props.refreshUserData();
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "An error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
        self.toggleActiveTeamRemoval();
    }

    selectTeam(index) {
        this.setState({
            selectedTeamIndex: index,
        });
    }

    updateListOfTeams(setIndexTo) {
        var self = this;

        authentication
            .getListOfTeamsWhereUserIsMaster()
            .then(function (listOfTeams) {
                self.setState({
                    listOfTeams: listOfTeams,
                });
                if (setIndexTo !== undefined) {
                    setIndexTo === "setIndexToLast"
                        ? self.setState({
                              selectedTeamIndex: listOfTeams.length - 1,
                          })
                        : self.setState({ selectedTeamIndex: setIndexTo });
                }
            });
    }

    render() {
        var content = () => {
            if (this.state.listOfTeams === "No Content") {
                return (
                    <div className="newTeamCreationWrapper">
                        <h1>There are no previously defined teams.</h1>
                        <h3>
                            Enter team name and click on the button below to
                            create your first team.
                            <br />
                            After a team is created, you'll be able to invite
                            team members.
                        </h3>
                        <form
                            className="initialTeamCreationForm"
                            onSubmit={this.handleNewTeamCreation}
                        >
                            <input
                                autoFocus
                                name="newTeamName"
                                type="text"
                                placeholder="Team name"
                                value={this.state.newTeamName}
                                onChange={this.handleInputChange}
                            />
                            <button className="defaultButtonGreen">
                                Create a new team
                            </button>
                        </form>
                    </div>
                );
            } else if (this.state.listOfTeams === null) {
                return (
                        <div className="singleComponent"><LoaderAnimation /></div>
                );
            } else {
                return (
                    <React.Fragment>
                        <TeamsList
                            createNotification={this.props.createNotification}
                            listOfTeams={this.state.listOfTeams}
                            newTeamInputIsVisible={this.state.newTeamInputIsVisible}
                            refreshUserData={this.props.refreshUserData}
                            selectedTeamIndex={this.state.selectedTeamIndex}
                            selectTeam={this.selectTeam}
                            teamUpdateInProcess={this.state.teamUpdateInProcess}
                            toggleTeamUpdateProcess={this.toggleTeamUpdateProcess}
                            toggleActiveTeamRemoval={
                                this.toggleActiveTeamRemoval
                            }
                            toggleNewTeamInputVisibility={this.toggleNewTeamInputVisibility}
                            updateListOfTeams={this.updateListOfTeams}
                            userEmailAddress={this.props.userEmailAddress}
                        />
                        <TeamPanel createNotification={this.props.createNotification}
                                   listOfTeams={this.state.listOfTeams}
                                   newTeamInputIsVisible={this.state.newTeamInputIsVisible}
                                   selectedTeamIndex={this.state.selectedTeamIndex}
                                   updateListOfTeams={this.updateListOfTeams}
                                   userEmailAddress={this.props.userEmailAddress}/>
                    </React.Fragment>
                );
            }
        };

        return (
            <React.Fragment>
                {this.state.visibleRemovalConfirmation ? (
                    <TeamRemoval
                        listOfTeams={this.state.listOfTeams}
                        permanentlyRemoveTeam={this.permanentlyRemoveTeam}
                        selectedTeamIndex={this.state.selectedTeamIndex}
                        toggleActiveTeamRemoval={this.toggleActiveTeamRemoval}
                    />
                ) : (
                    <React.Fragment />
                )}
                <div className="teamworkWrapper">
                    <div className="teamwork">{content()}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default Teamwork;
