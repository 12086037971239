import React, { Component } from "react";

class TeamRemoval extends Component {
	render() {
		return (
			<div className="teamRemovalWrapper">
				<div className="teamRemoval">
					<h1>Remove team</h1>
					<p>
						Are you sure you would like to permanently delete{" "}
						<span>
							{
								this.props.listOfTeams[
									this.props.selectedTeamIndex
								].name
							}
						</span>{" "}
						team?
					</p>
					<p>
						All email address searches and verifications associated
						with this team will also be removed.
					</p>
					<h4>THIS ACTION IS IRREVERSIBLE.</h4>
					<div className="teamRemovalButtonWrapper">
						<button className="cancelButton"
							onClick={this.props.toggleActiveTeamRemoval}
						>
							Cancel
						</button>
						<button
							className="defaultButtonRed"
							onClick={this.props.permanentlyRemoveTeam}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default TeamRemoval;
