import React, { Component } from "react";

class SecondaryNavigation extends Component {

	render() {
		return(
			<div className="secondaryNavigationWrapper">
				<div className="secondaryNavigation">
					<ul>
						<li className={this.props.mode === 'accountPlans' ? 'secondaryNavigationItem active' : 'secondaryNavigationItem'} onClick={() => this.props.switchModeTo('accountPlans')}>Account Plans</li>
						<li className={this.props.mode === 'profile' ? 'secondaryNavigationItem active' : 'secondaryNavigationItem'} onClick={() => this.props.switchModeTo('profile')}>Profile</li>
						<li className={this.props.mode === 'referrals' ? 'secondaryNavigationItem active' : 'secondaryNavigationItem'} onClick={() => this.props.switchModeTo('referrals')}>Referrals</li>
						<li className={this.props.mode === 'paymentMethods' ? 'secondaryNavigationItem active' : 'secondaryNavigationItem'} onClick={() => this.props.switchModeTo('paymentMethods')}>Payment Methods</li>
						<li className={this.props.mode === 'billingSettings' ? 'secondaryNavigationItem active' : 'secondaryNavigationItem'} onClick={() => this.props.switchModeTo('billingSettings')}>Billing Settings</li>
						<li className={this.props.mode === 'billingHistory' ? 'secondaryNavigationItem active' : 'secondaryNavigationItem'} onClick={() => this.props.switchModeTo('billingHistory')}>Billing History</li>
					</ul>
				</div>
			</div>
		)
	}
}

export default SecondaryNavigation;
