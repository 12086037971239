import React, { Component } from 'react'

import ModeSelector from './modeSelector.js'
import SecondaryNavigation from './secondaryNavigation'
import Gravatar from './gravatar.js'
import WorkspaceSelector from './workspaceSelector'

let settings = require('../settings')
let icons = require('./icons')

class Header extends Component {
	constructor(props) {
		super(props)

		this.state={workspaceSelectionVisible: false}

		this.toggleWorkspaceSelectionVisibility = this.toggleWorkspaceSelectionVisibility.bind(this);
	}

	toggleWorkspaceSelectionVisibility() {
		this.setState({workspaceSelectionVisible: !this.state.workspaceSelectionVisible})
	}

	render() {
		let poirotLogo = settings.CDN_HOST + 'images/PoirotLogoDark.svg'
		let defaultTeamAvatar = settings.CDN_HOST + "images/team-circle-whiteb.svg";

		var breadcrumbs = () => {
			return (
				<div className="breadcrumbsHeader">
					<div className="breadcrumbsContent">
						<div className="breadcrumbs">
							{this.props.activeTeamData === "unavailable" ?
								<div>
									{this.props.userName}'s Personal Account
								</div>
								:
								<div>
									{
									(typeof this.props.activeTeamData.avatar !== "undefined") ?
										<img className="breadcrumbsTeamAvatarImage"
											src={this.props.activeTeamData.avatar}
											alt="Poirot Team Avatar - https://poirot.app"
											width="25"
											height="25"
										/>
									:
										<img
											src={defaultTeamAvatar}
											alt="Poirot Team Avatar - https://poirot.app"
											width="25"
											height="25"
										/>
									}
									Team - {this.props.activeTeamData.name}
								</div>
							}
							<div>{icons.chevronRight()}</div>
							<div>
								{this.props.mode === 'emailFinder' ? <div>Find Email Address</div> : <React.Fragment />}
								{this.props.mode === 'domainSearch' ? <div>Search Target Domain for Email Addresses</div> : <React.Fragment />}
								{this.props.mode === 'emailVerification' ? <div>Verify Email Address</div> : <React.Fragment />}
								{this.props.mode === 'contactLists' ? <div>Manage Contact Lists</div> : <React.Fragment />}
								{this.props.mode === 'bulkActions' ? <div>Bulk Search & Verification of Email Addresses</div> : <React.Fragment />}
								{this.props.mode === 'teamwork' ? <div>Manage Teams</div> : <React.Fragment />}
								{this.props.mode === 'accountPlans' ? <React.Fragment><div className="breadCrumbSelectable">Configure Account Settings</div><div>{icons.chevronRight()}</div><div>Account Plans</div></React.Fragment> : <React.Fragment />}
								{this.props.mode === 'profile' ? <React.Fragment><div className="breadCrumbSelectable">Configure Account Settings</div><div>{icons.chevronRight()}</div><div>User Profile</div></React.Fragment> : <React.Fragment />}
								{this.props.mode === 'referrals' ? <React.Fragment><div className="breadCrumbSelectable">Configure Account Settings</div><div>{icons.chevronRight()}</div><div>Referrals</div></React.Fragment> : <React.Fragment />}
								{this.props.mode === 'paymentMethods' ? <React.Fragment><div className="breadCrumbSelectable">Configure Account Settings</div><div>{icons.chevronRight()}</div><div>Payment Methods</div></React.Fragment> : <React.Fragment />}
								{this.props.mode === 'billingSettings' ? <React.Fragment><div className="breadCrumbSelectable">Configure Account Settings</div><div>{icons.chevronRight()}</div><div>Billing Settings</div></React.Fragment> : <React.Fragment />}
								{this.props.mode === 'billingHistory' ? <React.Fragment><div className="breadCrumbSelectable">Configure Account Settings</div><div>{icons.chevronRight()}</div><div>Billing History</div></React.Fragment> : <React.Fragment />}
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<div className="header">
				<div className="logoHeader">
					<div className="logoHeaderContent">
						<div className="logoWrapper">
							<a href={settings.WEB_HOST}><img src={poirotLogo} alt="Poirot Application Logo - https://poirot.app"/></a>
						</div>
						<div className="modeSelectorWrapper">
							<ModeSelector 
										  isActiveTeamTeamMaster={this.props.isActiveTeamTeamMaster}
										  activeTeamData={this.props.activeTeamData}
										  currentProduct={this.props.currentProduct}
										  mode={this.props.mode}
										  switchModeTo={this.props.switchModeTo}/>
						</div>
						<div className="gravatarWrapper">
							<Gravatar userEmailAddress={this.props.userEmailAddress}/>
							<div onMouseOver={this.toggleWorkspaceSelectionVisibility} className={this.state.workspaceSelectionVisible ? "workspaceSelectionVisible" : ""}>
								{this.props.userName}
								<span>
                                	<svg className="caretDown" width="10px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g transform="translate(-822.000000, -4797.000000)" stroke="#343f44"><g transform="translate(713.000000, 4791.000000)"><polyline transform="translate(112.574990, 11.000000) rotate(-90.000000) translate(-112.574990, -11.000000) " points="107.57499 8.72497026 112.42501 13.5749901 117.57499 8.42500991"></polyline></g></g></g></svg>
                        	    </span>
							</div>
						</div>
					</div>
				</div>
				{this.state.workspaceSelectionVisible ? <WorkspaceSelector teamsWhereUserIsMaster={this.props.teamsWhereUserIsMaster}
																		   displayWorkspaceChangeNotification={this.props.displayWorkspaceChangeNotification}
																		   switchModeTo={this.props.switchModeTo}
																		   createNotification={this.props.createNotification}
																		   teams={this.props.teams} 
																		   refreshUserData={this.props.refreshUserData}
																		   refreshActiveTeamData={this.props.refreshActiveTeamData}
																		   toggleWorkspaceSelectionVisibility={this.toggleWorkspaceSelectionVisibility}/> : <React.Fragment />}
				{breadcrumbs()}
				{ this.props.mode === 'accountPlans' ||
					this.props.mode === 'profile' ||
					this.props.mode === 'referrals' ||
					this.props.mode === 'paymentMethods' ||
					this.props.mode === 'billingSettings' ||
					this.props.mode === 'billingHistory' ? (
					<SecondaryNavigation mode={this.props.mode}
										 switchModeTo={this.props.switchModeTo}/>
					 ) 
					:
					(<React.Fragment />)
				}
			</div>
		)
	}
}

export default Header