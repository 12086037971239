import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

let icons = require("../../appComponents/icons");

class Tags extends Component {
	fetchTagColor(tagId) {
		let tags =
			this.props.activeTeamData === "unavailable"
				? this.props.userTags
				: this.props.activeTeamData.tags;


		return tags.find((tag) => tag.id === tagId).color;
	}

	fetchTagLabel(tagId) {
		let tags =
			this.props.activeTeamData === "unavailable"
				? this.props.userTags
				: this.props.activeTeamData.tags;

		return tags.find((tag) => tag.id === tagId).label;
	}

	render() {
		var self = this;

		const tags = () => {
			if (typeof this.props.label === "undefined") {
				return <React.Fragment />;
			} else {
				return this.props.label.map(function (tag, index) {
					let tgs =
							self.props.activeTeamData === "unavailable"
							? self.props.userTags
							: self.props.activeTeamData.tags;
					if (typeof tgs.find((tg) => tg.id === tag) === 'undefined') {
						return <React.Fragment key={tag + index}/>
					} else {
						let color = self.fetchTagColor(tag);
						return (
							<div key={tag + index}
							onClick={(event) =>
								self.props.displayTagRemovalSelector(
									event,
									tag,
									self.props.taskId,
									self.props.email,
									self.props.refreshTaskData
								)
							}
							>
								<ReactTooltip
									className={"reactTooltip"}
									id={"tags" + tag}
									delayShow={100}
								>
									<div>{self.fetchTagLabel(tag)}</div>
								</ReactTooltip>
								<svg
									data-tip
									data-for={"tags" + tag}
									height="14"
									width="14"
								>
									<circle
										cx="7"
										cy="7"
										r="7"
										fill={"#" + color}
									/>
								</svg>
							</div>
						);
					}
				});
			}
		};

		const emptyTags = () => {
			var iterations = 0;

			if (typeof this.props.label === "undefined") {
				iterations = 5;
			} else {
				iterations = 5 - this.props.label.length;
			}

			if (iterations <= 1) {
				iterations = 0;
			}

			return [...Array(iterations)].map((e, i) => (
				<div key={i}>{icons.ring()}</div>
			));
		};

		return (
			<div className="tags">
				{tags()}
				{typeof this.props.label === "undefined" ? (
					<div
						onClick={(event) =>
							this.props.displayTagSelector(
								event,
								this.props.label,
								this.props.taskId,
								this.props.email,
								this.props.refreshTaskData
							)
						}
					>
						{icons.assign()}
					</div>
				) : this.props.label.length < 6 ? (
					<div
						onClick={(event) =>
							this.props.displayTagSelector(
								event,
								this.props.label,
								this.props.taskId,
								this.props.email,
								this.props.refreshTaskData
							)
						}
					>
						{icons.assign()}
					</div>
				) : (
					<React.Fragment />
				)}
				{emptyTags()}
			</div>
		);
	}
}

export default Tags;
