import React, { Component } from "react";

class CancelDowngradeForm extends Component {
	render() {
		return (
			<div className="downgradeFormWrapper">
				<div className="downgradeForm">
					<h1>Cancel pending downgrade</h1>
					<p>
						Are you sure you would like to cancel pending downgrade from{" "}
						<span>
							{
								this.props.currentProductName
							}
						</span>{" "}
						plan?
					</p>
					<h4>You can always perform downgrade of your current plan if required.</h4>
					<div className="downgradeFormButtonWrapper">
						<button className="cancelButton"
							onClick={this.props.toggleCancelDowngradeForm}
						>
							Cancel
						</button>
						<button
							className="defaultButtonRed"
							onClick={this.props.cancelDowngrade}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default CancelDowngradeForm;
