import React, { Component } from 'react';
import axios from 'axios';

import Select from 'react-select'

import LoaderAnimation from '../LoaderAnimation';

import {loadStripe} from '@stripe/stripe-js';

let settings = require("../settings");
let authentication = require("../authentication");

//
// Set time interval to constantly fetch updated user data.
//
var refreshUserDataInterval;

class PurchaseCreditsForm extends Component {
    constructor(props) {
        super(props)

        this.state={
            method: 'card',
            cardType: null,
            last4: '',
            validThru: '',
            processing: false,
            cards: '',
            selectedCard: 0,
            loaderAnimationMessage: undefined,
        }

        this.selectPaymentMethod = this.selectPaymentMethod.bind(this)

        this.handlePurchase = this.handlePurchase.bind(this)
        this.checkIfUserIsUpdated = this.checkIfUserIsUpdated.bind(this);

        this.handlePaymentFailure = this.handlePaymentFailure.bind(this);

        this.handleCardSelection = this.handleCardSelection.bind(this);

        this.initiatePayPalPayment = this.initiatePayPalPayment.bind(this);
    }

    componentDidMount() {
        var self = this;

        var cards = []
        var defaultCard = 0

        var date = new Date();
        var month = date.getUTCMonth() + 1;
        var year = date.getUTCFullYear();

        var months = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December"]
        this.props.userData.paymentMethods.map((item, index) => {
            var card = {value: item.id, label: item.brand.toUpperCase() + ' **** **** **** ' + item.last4 + ', valid thru ' + months[item.expMonth - 1] + ' ' + item.expYear, index: index}
            if (!(item.expMonth < month && item.expYear <= year)) {
                cards.push(card)
            }
            if (item.default) {
                defaultCard = index
                self.setState({
                    cardType: item.brand, 
                    last4: item.last4, 
                    validThru: (item.expMonth < 10 ? '0' + item.expMonth : item.expMonth) + '/' + (item.expYear - 2000)
                })
            }
            return null
        });

        this.setState({cards: cards, 
                        selectedCard: defaultCard})
    }

    checkIfUserIsUpdated() {
        var self = this;
        authentication.getUserData().then(function (userData) {
            if (userData !== null || userData !== "unavailable") {

                if (self.props.userData.permanentCredits + self.props.volume === userData.permanentCredits) {
                    self.props.createNotification(
                        "success",
                        "You have successfully added permanent credits to your account.",
                        "Success - Payment Successful",
                        6000
                    )                
                    self.setState({
                        processing: false,
                        cardType: null,
                        last4: '',
                        validThru: '',
                        loaderAnimationMessage: undefined,
                    })
                    self.props.refreshUserData()
                    self.props.togglePurchaseCreditsVisibility();
                }  
            }
        });
    }

    handlePaymentFailure() {
        this.setState({
            processing: false
        })
        this.props.createNotification(
            "error",
            "There was an error processing your payment. Please try again using different payment method.",
            "Error - Payment Failed",
            6000
        );
    }


    handlePurchase(event) {
        event.preventDefault()

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {  
            return;
        }

        const requestCredentials = {
            volume: this.props.volume,
            paymentMethod: this.state.cards[this.state.selectedCard].value
        }


        this.setState({processing: true})

        axios
            .post(settings.API_HOST + "user/purchasecredits", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    switch (response.data.status) {

                        case "requires_action":
                            loadStripe(settings.STRIPE_PUBLISHABLE_KEY).then(function(stripe) {

                            stripe.confirmCardPayment(response.data.clientSecret, {
                                payment_method: response.data.paymentMethodId,
                                })
                                .then(function (result) {
                                    if (result.error) {
                                        self.handlePaymentFailure()
                                        return;
                                    } else {
                                        if (result.paymentIntent.status === 'succeeded') {
                                            refreshUserDataInterval = setInterval(function () {
                                                self.checkIfUserIsUpdated();
                                            }, 2000);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    self.handlePaymentFailure()
                                    return;
                                });
                            });
                            break;

                        case "requires_payment_method":
                            //
                            // If the payment attempt fails for example due to a decline,
                            // current payment method should be detached, and
                            // payment process restarted.
                            //
                            self.handlePaymentFailure();
                            break;

                        case "requires_confirmation":
                            //
                            // Should never execute.
                            //
                            break;

                        case "requires_capture":
                            break;

                        case "processing":
                            //
                            // Wait till processing is over. Notification 
                            // email should be sent after operation succeeds. 
                            // 
                            break;

                        case "canceled":
                            //
                            // This should never execute
                            //
                            break;

                        case "succeeded":
                            refreshUserDataInterval = setInterval(function () {
                                self.checkIfUserIsUpdated();
                            }, 2000);
                            break;

                        default:
                            self.setState({processing: false})
                            self.props.createNotification(
                                "error",
                                "Internal server error.",
                                "Error",
                                6000
                            );
                            break;
                    }
                    
                } else {
                    self.setState({processing: false})
                    self.props.createNotification(
                        "error",
                        "There was an error processing your payment. Please try again using different payment method.",
                        "Error - Payment Failed",
                        6000
                    );
                    return;
                }
            })
            .catch(function (error) {
                self.setState({processing: false})
                self.props.createNotification(
                    "error",
                    "There was an error processing your payment. Please try again using different payment method.",
                    "Error - Payment Failed",
                    6000
                );
                return;
            });
    }

    componentWillUnmount() {
        clearInterval(refreshUserDataInterval);
    }

    selectPaymentMethod(method) {
            this.setState({method: method})
    }

    handleCardSelection(card) {
        let id = card.value

        this.props.userData.paymentMethods.map((item, index) => {
            if (item.id === id) {
                this.setState({
                    cardType: item.brand, 
                    last4: item.last4, 
                    validThru: (item.expMonth < 10 ? '0' + item.expMonth : item.expMonth) + '/' + (item.expYear - 2000)
                })
            }
            return null
        })

        this.setState({selectedCard: card.index})
    }

    initiatePayPalPayment(event) {
        event.preventDefault()

        this.setState({processing: true})

        var self = this;

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {         
            return;
        }

        const requestData = {
            "amount": this.props.volume,
            "type": "permanentCredits",
            "approveRedirectUrl": settings.PAYPAL_CREDITS_APPROVED_REDIRECT_URL,
            "cancelRedirectUrl": settings.PAYPAL_CREDITS_CANCEL_REDIRECT_URL,
        }

        axios
            .post(settings.API_HOST + "user/paypal", requestData, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                            self.setState({loaderAnimationMessage: 'Waiting for PayPal to finish processing your request. This may take up to a minute to complete.'})
                            window.location.href = response.data.url;
                            setTimeout(function(){ self.setState({loaderAnimationMessage: undefined, processing: false}); }, 3000);
                } else {
                    self.setState({processing: false})
                    self.props.createNotification(
                        "error",
                        "An error occurred processing your request.",
                        "Error - PayPal Processing",
                        7000
                    );
                    
                }
            })
            .catch(function (error) {
                self.setState({processing: false})
                self.props.createNotification(
                    "error",
                    "An error occurred processing your request.",
                    "Error - PayPal Processing",
                    7000
                );  
            });
    }

    render() {
        let creditCard = settings.CDN_HOST + 'images/cards/CreditCardEmpty.svg'
        let payPalLogo = settings.CDN_HOST + 'images/PayPalLogo.svg'
        let visa = settings.CDN_HOST + 'images/cards/visa.svg'
        let mastercard = settings.CDN_HOST + 'images/cards/mastercard.svg'
        let amex = settings.CDN_HOST + 'images/cards/amex.svg'
        let discover = settings.CDN_HOST + 'images/cards/discover.svg'
        let diners = settings.CDN_HOST + 'images/cards/diners.svg'
        let jcb = settings.CDN_HOST + 'images/cards/jcb.svg'
        let unionpay = settings.CDN_HOST + 'images/cards/unionpay.svg'
        let maestro = settings.CDN_HOST + 'images/cards/maestro.svg'
        let elo = settings.CDN_HOST + 'images/cards/elo.svg'
        let hipercard = settings.CDN_HOST + 'images/cards/hipercard.svg'
        let laser = settings.CDN_HOST + 'images/cards/laser.svg'


        return(
            <React.Fragment>
            {this.state.processing ? <LoaderAnimation message={this.state.loaderAnimationMessage}/> : <React.Fragment />}
                <div className="paymentFormWrapper">
                    <div className="paymentForm">
                            <div className="paymentMethodWrapper">
                                <div className="paymentMethodSelector">
                                    <div>
                                        <h3>Selected payment method</h3>
                                    </div>
                                    <div>
                                        <ul>
                                            <li className={this.state.method === 'card' ? 'paymentMethodSelectorItem active' : 'paymentMethodSelectorItem'} onClick={() => this.selectPaymentMethod('card')}>Credit / Debit Card</li>
                                            <li className={this.state.method === 'payPal' ? 'paymentMethodSelectorItem active' : 'paymentMethodSelectorItem'} onClick={() => this.selectPaymentMethod('payPal')}>PayPal</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="paymentPlanAmount">
                                    <div>
                                        <h3>Purchase {this.props.volume.toLocaleString()} permanent credits</h3>
                                        <h3>for one time payment of ${parseInt(this.props.unitPrice * this.props.volume)}</h3>
                                    </div>
                                </div>

                                {this.state.method === 'card' ?
                                    <div className="upgradeCard">
                                        <div>
                                            <img src={creditCard} alt="Poirot Application Credit Card - https://poirot.app" height="220" width="337"/>
                                            <div className={this.state.method === 'card' ? "cardValidity" : "displayNone"}>{this.state.validThru}</div>
                                            <div className={this.state.method === 'card' ? "cardNumber" : "displayNone"}>**** **** **** {this.state.last4}</div>
                                            {this.state.cardType === 'visa' ? <img className="cardType" src={visa} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'mastercard' ? <img className="cardType" src={mastercard} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'amex' ? <img className="cardType" src={amex} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'discover' ? <img className="cardType" src={discover} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'diners' ? <img className="cardType" src={diners} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'jcb' ? <img className="cardType" src={jcb} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'unionpay' ? <img className="cardType" src={unionpay} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'maestro' ? <img className="cardType" src={maestro} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'elo' ? <img className="cardType" src={elo} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'hipercard' ? <img className="cardType" src={hipercard} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                            {this.state.cardType === 'laser' ? <img className="cardType" src={laser} width="128" height="80" alt="Poirot Card"/> : <React.Fragment />}
                                        </div>
                                    </div>
                                    :
                                    <div className="payPalLogoWrapper">
                                        <div>
                                            <img src={payPalLogo} alt="PayPal Logo - https://poirot.app"/>
                                        </div>
                                    </div>
                                }

                            </div>

                            {this.state.method === 'card' ?
                                <form className="paymentDetailsWrapper upgradePaymentDetails" onSubmit={this.handlePurchase} id="subscribeForm">
                                    <h1>Payment details</h1>
                                    <div>
                                        <h4>Credit / Debit Card</h4>
                                        <Select ref={(input) => { this.nameInput = input; }} className="reactSelectComponent" classNamePrefix="react-select" options={this.state.cards} autoFocus blurInputOnSelect="true" onChange={this.handleCardSelection} value={this.state.cards[this.state.selectedCard]}/>
                                    </div>
                                    
                                    <div className="paymentActionWrapper">
                                        <div><button className="cancelButton" type="button" onClick={(event) => this.props.togglePurchaseCreditsVisibility(event)}>Cancel</button></div>
                                        <div><button className="defaultButtonBlue" type="submit" form="subscribeForm">Purchase</button></div>
                                    </div>
                                </form>
                            :
                                <div className="paymentDetailsWrapper">
                                   <div className="paymentActionWrapper creditsPurchase">
                                        <div><button className="cancelButton" onClick={(event) => this.props.togglePurchaseCreditsVisibility(event)}>Cancel</button></div>
                                        <div><button className="defaultButtonBlue" onClick={this.initiatePayPalPayment}>Continue to PayPal</button></div>
                                    </div>
                                </div>
                            }
                    </div>
                </div>       
            </React.Fragment>
        )
    }
};

export default PurchaseCreditsForm;