import React, { Component } from "react";
import axios from 'axios';

let settings = require("../../settings");
let authentication = require("../../authentication");

class TeamMemberRemoval extends Component {
	constructor(props) {
		super(props)

		this.removeUserFromTeam = this.removeUserFromTeam.bind(this);
	}

	removeUserFromTeam(event) {
        event.preventDefault()

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        var self = this;

        const headers = { Authorization: "Bearer " + tokenHolder.token };

        const data = {
            teamId: this.props.teamId,
            memberEmail: this.props.email
        };

        axios
            .delete(settings.API_HOST + "team/user", { headers, data })
            .then(function (response) {
                if (response.status === 200) {
                    self.props.createNotification(
                        "success",
                        "Team member has been successfully removed.",
                        "Team Updated",
                        7000
                    );
                    self.props.updateListOfTeams();
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                console.log("ERROR: ", error.response)
                self.props.createNotification(
                    "error",
                    "API server error occurred. Please try again.",
                    "Error",
                    6000
                );
            });
        self.props.toggleTeamMemberRemoval();
    }

	render() {
		return (
			<React.Fragment>
			{this.props.memberStatus === "Active" ? (
			<div className="teamMemberRemovalWrapper">
				<div className="teamMemberRemoval">
					<h1>Remove team member</h1>
					<p>
						Are you sure you would like to remove{" "}
						<span>
							{
								this.props.memberName
							}
						</span>{" "}
						from{" "}
						<span>
							{
								this.props.teamName
							}
						</span>{" "}
						team?
					</p>
					<p>
						All email address searches and verifications associated
						with this user will still be accessible to other team members.
					</p>
					<p>
						You can always reinvite {this.props.memberName} to this team.
					</p>
					<div className="teamMemberRemovalButtonWrapper">
						<button className="cancelButton"
							onClick={this.props.toggleTeamMemberRemoval}
						>
							Cancel
						</button>
						<button
							className="defaultButtonRed"
							onClick={this.removeUserFromTeam}
						>
							Confirm
						</button>
					</div>
				</div>
			</div> )
			: (
			<div className="teamMemberRemovalWrapper">
				<div className="teamMemberRemoval">
					<h1>Remove invitation</h1>
					<p>
						Are you sure you would like to remove invitation from{" "}
						<span>
							{
								this.props.teamName
							}
						</span>{" "}
						team<br/>sent to{" "}
						<span>
							{
								this.props.email
							}
						</span>
					</p>
					<p>
						You can always resend this invitation.
					</p>
					<div className="teamMemberRemovalButtonWrapper">
						<button className="cancelButton"
							onClick={this.props.toggleTeamMemberRemoval}
						>
							Cancel
						</button>
						<button
							className="defaultButtonRed"
							onClick={this.removeUserFromTeam}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		)
		}
		</React.Fragment>
		);
	}
}

export default TeamMemberRemoval;