import React, { Component } from 'react';
import axios from 'axios';

let settings = require("../../settings");
let authentication = require("../../authentication");

class ColorPicker extends Component {
    
    onColorChange(item) {

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const requestCredentials = {
            id: this.props.selectedTagId,
            color: item.substring(1),
        };

        var self = this;

        axios
            .patch(settings.API_HOST + "tag", requestCredentials, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.props.refreshUserData();
                    self.props.refreshActiveTeamData();
                    self.props.closeColorPicker();
                } else {
                    self.props.createNotification(
                        "error",
                        "An error occurred while trying to set the label color. Please try again later.",
                        "Error - Label color change",
                        7000
                    );
                }
            })
            .catch(function (error) {
                self.props.createNotification(
                    "error",
                    "An error occurred while trying to set the label color. Please try again later.",
                    "Error - Label color change",
                    6000
                );
            });

    }

    render() {

        const colors = [
            '#f44336','#e91e63','#9c27b0','#673ab7','#3f51b5','#2196f3',
            '#03a9f4','#00bcd4','#009688','#4caf50','#8bc34a','#cddc39',
            '#ffeb3b','#ffc107','#ff9800','#ff5722','#795548','#607d8b'
        ]

        var circle = colors.map((item, index) => {
            return (
                <div className="colorPickerColor" key={index}>
                    <svg height="28" width="28">
                        <circle cx="14" cy="14" r="14" fill={item} onClick={() => this.onColorChange(item)} />
                    </svg>
                </div>
            )
        })

        return(
            <div className="colorPicker">
                {circle}
            </div>       
        )
    }
};

export default ColorPicker;