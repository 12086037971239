import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";

import LoaderAnimation from "./LoaderAnimation";
import App from "./App";
import Register from "./Register";
import SignIn from "./SignIn";
import ResetPassword from "./ResetPassword";
import SetNewPassword from "./SetNewPassword";

class AppRouter extends Component {
	render() {
		//
		// Check user authentication status
		//
		if (this.props.isAuthenticated !== null) {
			return (
				<Root
					createNotification={this.props.createNotification}
					isAuthenticated={this.props.isAuthenticated}
					toggleAuthentication={this.props.toggleAuthentication}
				/>
			);
		} else {
			return (
					<LoaderAnimation />
			);
		}
	}
}

export default AppRouter;

const Root = (props) => {
	
	return (
		<Router>
			<Route
				exact
				path="/register"
				component={() => (
					<Register createNotification={props.createNotification}
							  location={window.location}/>
				)}
			/>
			<Route
				exact
				path="/signin"
				component={() =>
					props.isAuthenticated ? (
						<Redirect to="/" />
					) : (
						<SignIn
							createNotification={props.createNotification}
							toggleAuthentication={props.toggleAuthentication}
						/>
					)
				}
			/>
			<Route
				exact
				path="/"
				component={(entryPoint) => 	
					props.isAuthenticated ? (
						<App createNotification={props.createNotification} 
							entryPoint={(typeof entryPoint.location.parameter === 'undefined') ? 'emailFinder' : entryPoint.location.parameter.route} 
							payPalTokenPath={(typeof entryPoint.location.parameter === 'undefined') ? null : entryPoint.location.parameter.path}
							googleTokenPath={(typeof entryPoint.location.parameter === 'undefined') ? null : entryPoint.location.parameter.path}/>
					) : (
						<Redirect to="/signin" />
					)
				}
			/>
			<Route
				exact
				path="/prepaidcredits"
				component={() =>
					<Redirect to={{ pathname: "/", parameter: { route: "paymentMethods", path: null }}}/>
				}
			/>
			<Route
				exact
				path="/approvedprepaidcredits"
				component={(path) =>
					<Redirect to={{ pathname: "/", parameter: { route: "paymentMethods", path: "approvedprepaidcredits"}}}/>
				}
			/>
			<Route
				exact
				path="/subscriptionfailed"
				component={(path) =>
					<Redirect to={{ pathname: "/", parameter: { route: "accountPlans", path: "subscriptionfailed"}}}/>
				}
			/>
			<Route
				exact
				path="/subscription"
				component={(path) =>
					<Redirect to={{ pathname: "/", parameter: { route: "accountPlans", path: "subscription"}}}/>
				}
			/>
			<Route
				exact
				path="/permanentfailed"
				component={(path) =>
					<Redirect to={{ pathname: "/", parameter: { route: "accountPlans", path: "permanentfailed"}}}/>
				}
			/>
			<Route
				exact
				path="/permanentpurchased"
				component={(path) =>
					<Redirect to={{ pathname: "/", parameter: { route: "accountPlans", path: "permanentpurchased"}}}/>
				}
			/>
			<Route
				exact
				path="/googleinvite"
				component={(path) =>
					<Redirect to={{ pathname: "/", parameter: { route: "referrals", path: path}}}/>
				}
			/>
			<Route
				exact
				path="/requestpasswordreset"
				component={() => (
					<ResetPassword
						createNotification={props.createNotification}
					/>
				)}
			/>
			<Route
				exact
				path="/setpassword/:link"
				component={SetNewPassword}
			/>
		</Router>
	);
};


// http://localhost:3000/approvedprepaidcredits?token=75X22004PA562193Y&PayerID=D32WAARD4Z3QY

// if (this.props.payPalTokenPath !== null) {
// 	console.log(this.props.payPalTokenPath)
// 	var urlParams = new URLSearchParams(this.props.payPalTokenPath);

// 	console.log("Token: ", urlParams.get("token"))
// }
