import React, { Component } from "react";

import Switch from "../switch";
import UpgradePlanTable from './upgradePlanTable';

class AvailablePlans extends Component {

    constructor(props) {
        super(props)
        var plans = []

        this.props.plansData.forEach((item) => {

            if (item.name !== this.props.currentProduct.name) {
            	let plan = item.plans.find(function(intervalPlan) {
					return(intervalPlan.recurring.interval === 'month')
				})

				item.selected = plan
                plans.push(item)
			}
        })

        this.state=({
            annualInterval: false,
            visiblePlans: plans,
            currentProductName: this.props.currentProduct.name
        })

        this.handleToggle = this.handleToggle.bind(this)
        this.handleLabelClick = this.handleLabelClick.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.currentProduct.name !== state.currentProductName) {

            var plans = []

            props.plansData.forEach((item) => {
                if (item.name !== props.currentProduct.name) {
                    let plan = item.plans.find(function(intervalPlan) {
                        return(intervalPlan.recurring.interval === 'month')
                    })

                    item.selected = plan
                    plans.push(item)
                }
            })

            return {
                annualInterval: false,
                currentProductName: props.currentProduct.name,
                visiblePlans: plans
            };
        }
        return null;
    }

    handleToggle() {
    	var self = this;
        var selectedPlans = []

        this.props.plansData.forEach((item) => {
                if (item.name !== self.props.currentProduct.name) {
	            	let plan = item.plans.find(function(intervalPlan) {
						return(intervalPlan.recurring.interval === (self.state.annualInterval ? 'month' : 'year'))
					})
					// Free plan has only monthly interval that should be used in all cases
					if (plan === undefined) {
						plan = item.plans.find(function(intervalPlan) {
							return(intervalPlan.recurring.interval === 'month')
						})
					}

					item.selected = plan
	                selectedPlans.push(item)
				}
            })
        
        this.setState({
            annualInterval: !this.state.annualInterval,
            visiblePlans: selectedPlans
        })
    }

    handleLabelClick(status) {
        if (this.state.annualInterval !== status) {
            this.handleToggle()
        }
    }

	render() {
		var unsortedPlans = this.state.visiblePlans;
		unsortedPlans.sort((a, b) => (a.selected.unitAmount < b.selected.unitAmount) ? 1 : -1)

        var plans = unsortedPlans.map((item, index) => {
            return (
                <UpgradePlanTable key={index} plan={item} annualInterval={this.state.annualInterval}
                                                          togglePaymentProcessFormVisibility={this.props.togglePaymentProcessFormVisibility}
                                                          currentProduct={this.props.currentProduct}/>
            )
        })
        
		return (
			<div className="availablePlansWrapper">
				<h1>Available subscription plans</h1>
				<div className="upgradeSelectorWrapper">
					<div className="upgradeSelector">
                            <div className="cycleSwitch">
                                <div className="toggleLabel" onClick={() => this.handleLabelClick(false)}>
                                    <h3>Monthly</h3>
                                </div>
                                <div>
                                    <Switch isOn={this.state.annualInterval} handleToggle={() => this.handleToggle()}/>
                                </div>
                                <div className="toggleLabel" onClick={() => this.handleLabelClick(true)}>
                                    <h3>Yearly</h3>
                                </div>
                            </div>
                        </div>
					<div className="upgradeNotificationWrapper">
						<h3>Simple and transparent pricing</h3>
						<p>Choose the plan that's right for your business. Whether you're just getting started or you're looking to improve your email related activities, we've got you covered.</p>
					</div>
				</div>
				<div className="upgradeTable">
                    {plans}
                </div>
			</div>
		)
	}
}

export default AvailablePlans;