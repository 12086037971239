import React, { Component } from "react";

import EmailFinder from '../pages/emailFinder';
import DomainSearch from '../pages/domainSearch';
import EmailVerification from '../pages/emailVerification';
import ContactLists from '../pages/contactLists';
import BulkActions from '../pages/bulkActions';
import Teamwork from '../pages/teamwork';

import AccountPlans from '../pages/settings/accountPlans'
import Profile from '../pages/settings/profile'
import Referrals from '../pages/settings/referrals'
import PaymentMethods from '../pages/settings/paymentMethods'
import BillingSettings from '../pages/settings/billingSettings'
import BillingHistory from '../pages/settings/billingHistory'


class Content extends Component {
	render() {

		var mode = () => {
			if (this.props.mode === 'emailFinder') { return ( <EmailFinder 	activeTeamData={this.props.activeTeamData}
																			userTags={this.props.userData.tags}
																			displayTagSelector={this.props.displayTagSelector}
																			displayTagRemovalSelector={this.props.displayTagRemovalSelector}
																			handleTaskRemoval={this.props.handleTaskRemoval}
																			refreshUserData={this.props.refreshUserData}
																		    createNotification={this.props.createNotification}
																		    refreshActiveTeamData={this.props.refreshActiveTeamData}
																			helperPanelMode={this.props.helperPanelMode}
																			switchHelperPanelModeTo={this.props.switchHelperPanelModeTo}
																			source={this.props.source}
																			setDetailsHelperPanelSource={this.props.setDetailsHelperPanelSource}
																			resultsPerPage={this.props.resultsPerPage}
																			setResultsPerPage={this.props.setResultsPerPage}
																			/> )} 
			else if (this.props.mode === 'domainSearch') { return  (<DomainSearch activeTeamData={this.props.activeTeamData}
																				  displayTagSelector={this.props.displayTagSelector}
																				  displayTagRemovalSelector={this.props.displayTagRemovalSelector}
																				  handleTaskRemoval={this.props.handleTaskRemoval}
																				  userTags={this.props.userData.tags}
																				  refreshUserData={this.props.refreshUserData}
																				  refreshActiveTeamData={this.props.refreshActiveTeamData}
																				  createNotification={this.props.createNotification}
																				  helperPanelMode={this.props.helperPanelMode}
																				  switchModeTo={this.props.switchModeTo}
																				  switchHelperPanelModeTo={this.props.switchHelperPanelModeTo}
																				  source={this.props.source}
																				  setDetailsHelperPanelSource={this.props.setDetailsHelperPanelSource}
																				  resultsPerPage={this.props.resultsPerPage}
																				  setResultsPerPage={this.props.setResultsPerPage}
																				  /> )}
			else if (this.props.mode === 'emailVerification') { return  (<EmailVerification activeTeamData={this.props.activeTeamData}
																							helperPanelMode={this.props.helperPanelMode}
																							displayTagSelector={this.props.displayTagSelector}
																							displayTagRemovalSelector={this.props.displayTagRemovalSelector}
																							handleTaskRemoval={this.props.handleTaskRemoval}
																							userTags={this.props.userData.tags}
																							refreshUserData={this.props.refreshUserData}
																							refreshActiveTeamData={this.props.refreshActiveTeamData}
																							createNotification={this.props.createNotification}
																							switchModeTo={this.props.switchModeTo}
																							switchHelperPanelModeTo={this.props.switchHelperPanelModeTo}
																							source={this.props.source}
																							setDetailsHelperPanelSource={this.props.setDetailsHelperPanelSource}
																							resultsPerPage={this.props.resultsPerPage}
																							setResultsPerPage={this.props.setResultsPerPage}
																							/> )}
			else if (this.props.mode === 'contactLists') { return  (<ContactLists helperPanelMode={this.props.helperPanelMode}
																			      switchHelperPanelModeTo={this.props.switchHelperPanelModeTo}
																			      source={this.props.source}
																				  setDetailsHelperPanelSource={this.props.setDetailsHelperPanelSource}
																				  activeTeamData={this.props.activeTeamData}
																					displayTagSelector={this.props.displayTagSelector}
																					displayTagRemovalSelector={this.props.displayTagRemovalSelector}
																					handleTaskRemoval={this.props.handleTaskRemoval}
																					userTags={this.props.userData.tags}
																					refreshUserData={this.props.refreshUserData}
																					refreshActiveTeamData={this.props.refreshActiveTeamData}
																					createNotification={this.props.createNotification}
																					switchModeTo={this.props.switchModeTo}
																					resultsPerPage={this.props.resultsPerPage}
																					setResultsPerPage={this.props.setResultsPerPage}

																				  /> )}
			else if (this.props.mode === 'bulkActions') { return (<BulkActions />)}
			else if (this.props.mode === 'teamwork') { return  (<Teamwork createNotification={this.props.createNotification}
																		  refreshUserData={this.props.refreshUserData}
																		  userEmailAddress={this.props.userEmailAddress}/>)}
			else if (this.props.mode === 'accountPlans') { return (<AccountPlans createNotification={this.props.createNotification}
																				refreshUserData={this.props.refreshUserData} 
																				currentProduct={this.props.currentProduct}
																				plansData={this.props.plansData}
																				userData={this.props.userData}
																				payPalTokenPath={this.props.payPalTokenPath}
																				permanentCreditsData={this.props.permanentCreditsData}
																			    setPayPalTokenPathToNull={this.props.setPayPalTokenPathToNull}/>)}
			else if (this.props.mode === 'profile') { return (<Profile createNotification={this.props.createNotification}
																		currentProduct={this.props.currentProduct}
																		refreshUserData={this.props.refreshUserData}
																		userData={this.props.userData}/>)}
			else if (this.props.mode === 'referrals') { return (<Referrals createNotification={this.props.createNotification}
																		   refreshUserData={this.props.refreshUserData}
																		   userData={this.props.userData}
																		   googleTokenPath={this.props.googleTokenPath}/>)}
			else if (this.props.mode === 'paymentMethods') { return (<PaymentMethods createNotification={this.props.createNotification}
																					 refreshUserData={this.props.refreshUserData}
																					 userData={this.props.userData}
																					 payPalTokenPath={this.props.payPalTokenPath}
																					 setPayPalTokenPathToNull={this.props.setPayPalTokenPathToNull}/>)}
			else if (this.props.mode === 'billingSettings') { return (<BillingSettings createNotification={this.props.createNotification}
																					 refreshUserData={this.props.refreshUserData}
																					 userData={this.props.userData}/>)}
			else if (this.props.mode === 'billingHistory') { return (<BillingHistory createNotification={this.props.createNotification}/>)}
			}

		return (
			<div className="contentBlockWrapper">
				<div className="content">
					{mode()}
				</div>
			</div>
		)
	}
}

export default Content;
