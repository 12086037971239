import React, { Component } from "react";

class AccountCreditForm extends Component {
	render() {
		return (
			<div className="accountCreditFormWrapper">
				<div className="accountCreditForm">
					<h1>Upgrade your account</h1>
					<p>
						Your subscription plan will be upgraded from{" "}
						<span>
							{
								this.props.currentProductName
							}
						</span>{" "}
						to{" "}
						<span>
							{
								this.props.newPlanName
							}
						</span>{" "}
						plan.
					</p>
					<p>
						Current account balance will be used for initial subscription payment.
					</p>
					<div className="accountCreditFormButtonWrapper">
						<button className="cancelButton"
							onClick={this.props.toggleAccountCreditForm}
						>
							Cancel
						</button>
						<button
							className="defaultButtonBlue"
							onClick={this.props.upgradeWithCredit}
						>
							Upgrade
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountCreditForm;