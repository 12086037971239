import React, { Component } from "react";

import axios from 'axios';

import UserRemoval from "./userRemoval";

let settings = require("../../settings");
let authentication = require("../../authentication");

class DeactivateAccount extends Component {
	constructor(props) {
		super(props)

		this.state=({
			removalVisible: false
		})

		this.toggleRemoval = this.toggleRemoval.bind(this);
		this.removeUser = this.removeUser.bind(this);
	}

	toggleRemoval() {
		this.setState({
			removalVisible: !this.state.removalVisible,
		})
	}

	removeUser() {
		var self = this;
		let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const headers = { Authorization: "Bearer " + tokenHolder.token };

        axios
            .delete(settings.API_HOST + "/user", { headers })
            .then(function (response) {
                if (response.status === 200) {
                    authentication.removeAccessToken();
					window.location.href = settings.WEB_HOST
                } else {
                    self.props.createNotification(
                        "error",
                        "Internal server error.",
                        "Error",
                        6000
                    );
                }
            })
            .catch(function (error) {
                console.log("ERROR: ", error.response)
                self.props.createNotification(
                    "error",
                    "Internal server error.",
                    "Error",
                    6000
                );
            });
	}

	render() {

		return (
			<React.Fragment>
				{
					this.state.removalVisible ?
					<UserRemoval toggleRemoval={this.toggleRemoval}
								 removeUser={this.removeUser}/>
					:
					<React.Fragment />
				}
				<div className="deactivateAccount">
					<div><h1>Deactivate account</h1></div>
					<div className="deactivateAccountActionWrapper">
						<div>
							<p>This will remove your account from all teams and disable your account.</p>
						</div>
						<div className="deactivateAccountButton">
							<button className="defaultButtonRed" onClick={() => this.toggleRemoval()}>Deactivate account</button>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default DeactivateAccount;
