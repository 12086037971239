import React, { Component } from "react";
import axios from 'axios';

import LoaderAnimation from "../../LoaderAnimation";

let settings = require("../../settings");
let authentication = require("../../authentication");

let refreshInterval;

class ApplyCoupon extends Component {
	constructor(props) {
		super(props)

		this.state={
			couponCode: "",
			processing: false,
			loaderAnimationMessage: undefined,
		}

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleCouponApply = this.handleCouponApply.bind(this)
	}

	handleInputChange(event) {
		const target = event.target;
		var value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

    componentDidUpdate(prevProps) {
        if (prevProps.userData.plan !== this.props.userData.plan) {
        	clearInterval(refreshInterval);
            this.setState({
            	processing: false,
            	loaderAnimationMessage: undefined
            })
        }
    }

	handleCouponApply(event) {

		console.log("UserData", this.props.userData);

		event.preventDefault();

		if (this.state.couponCode === "") {
			this.props.createNotification(
				"error",
				"Please enter a valid coupon code.",
				"Error - coupon code",
				6000
			);
			return;
		}

		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			self.toggleNewTeamInputVisibility()
			return;
		}

		const requestData = {
			code: this.state.couponCode,
		};

		this.setState({
			processing: true
		})


		axios
			.post(settings.API_HOST + "user/coupon", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					console.log("200")
					self.setState({
						couponCode: "",
						processing: false,
					});
					self.props.createNotification(
						"success",
						"You have successfully applied a new coupon code to your account.",
						"Success - Coupon Code",
						7000
					);
					self.props.refreshUserData();
				} else if (response.status === 201) {
					console.log("201")
					self.setState({
						couponCode: "",
						loaderAnimationMessage: "Account update in progress."
					});
					self.props.createNotification(
						"success",
						"You have successfully applied a new coupon code to your account.",
						"Success - Coupon Code",
						7000
					);
					refreshInterval = setInterval(function () {
						self.props.refreshUserData();
					}, 5000);
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while trying to apply a new coupon code.",
						"Error - Coupon Code",
						7000
					);

					self.setState({
						processing: false
					})

				}
			})
			.catch(function (error) {
				self.setState({
					processing: false
				})
				if (error.response.status === 409) {
					self.props.createNotification(
						"error",
						"Coupon already used.",
						"Error - Coupon Code",
						6000
					);
				} else {
					self.props.createNotification(
						"error",
						"An error occurred while trying to apply a new coupon code. Please try again later.",
						"Error - Coupon Code",
						7000
					);
				}
			});
	}

	componentWillUnmount() {
		clearInterval(refreshInterval);
	}


	render() {

		return (
			<React.Fragment>
				{this.state.processing ? <LoaderAnimation message={this.state.loaderAnimationMessage}/> : <React.Fragment />}
				<div className="applyCoupon">
					<div>
						<h1>Apply coupon code to your account</h1>
					</div>
					<form className="applyCouponActionWrapper" onSubmit={(event) => this.handleCouponApply(event)}>
						<input type="text" name="couponCode" value={this.state.couponCode} onChange={this.handleInputChange} placeholder="Coupon"/>
						<button className="defaultButtonBlue" type="submit">Apply code</button>
					</form>
				</div>
			</React.Fragment>
		)
	}
}

export default ApplyCoupon;