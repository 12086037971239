import React, { Component } from "react";
import axios from 'axios';

import { Collapse } from "react-collapse";

import DomainSearchChart from "./blocks/domainSearchChart";
import Gravatar from './gravatar.js';
import Tags from './blocks/tags';

let icons = require("./icons");
let settings = require("../settings");
let authentication = require("../authentication");

class Task extends Component {
	constructor(props) {
		super(props);

		///
		/// Evaluate types of email
		///
		var prospectPart = 0;
		var genericPart = 0;
		var unknownPart = 0;
		var relevantPart = 0;
		var fraudulentPart = 0;
		var totalCount = 0;

		if (typeof this.props.item.result !== "undefined") {
			let foundAddressesCount =
				this.props.item.result.payload.length +
				(typeof this.props.item.result.relevantAddress !== "undefined"
					? this.props.item.result.relevantAddress.length
					: 0);

			let prospectCount = this.props.item.result.payload.filter(
				(item) => item.type === "prospect"
			).length;
			let genericCount = this.props.item.result.payload.filter(
				(item) => item.type === "generic"
			).length;
			let unknownCount = this.props.item.result.payload.filter(
				(item) => item.type === "unknown"
			).length;
			let relevantCount =
				typeof this.props.item.result.relevantAddress !== "undefined"
					? this.props.item.result.relevantAddress.length
					: 0;
			let fraudulentCount = this.props.item.result.payload.filter(
				(item) => item.type === "fraudulent"
			).length;

			totalCount =
				prospectCount +
				genericCount +
				unknownCount +
				relevantCount +
				fraudulentCount;

			prospectPart = Math.round(
				(prospectCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);
			genericPart = Math.round(
				(genericCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);
			unknownPart = Math.round(
				(unknownCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);

			relevantPart = Math.round(
				(relevantCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);
			fraudulentPart = Math.round(
				(fraudulentCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);
		}

		this.state = {
			prospectPart: prospectPart,
			genericPart: genericPart,
			unknownPart: unknownPart,
			relevantPart: relevantPart,
			fraudulentPart: fraudulentPart,
			totalCount: totalCount,
			isOpen: false,
		};

		this.lookupEmailAddress = this.lookupEmailAddress.bind(this);
		this.handleTaskClick = this.handleTaskClick.bind(this);
		this.handleRightClick = this.handleRightClick.bind(this);
	}

	handleRightClick(event, taskID) {
		event.preventDefault()

		this.props.handleTaskRemoval(event, taskID, this.props.refreshTaskData)
	}

	handleTaskClick() {
		this.setState({isOpen: !this.state.isOpen});
		this.props.setDetailsHelperPanelSource(this.props.item)
	}

	lookupEmailAddress(event, name, domain) {
		event.preventDefault();


		var self = this;

		let tokenHolder = authentication.getToken();

		if (!tokenHolder) {
			return;
		}

		const requestData = {
			company: domain,
			name: name,
		};

		axios
			.post(settings.API_HOST + "find", requestData, {
				headers: { Authorization: "Bearer " + tokenHolder.token },
			})
			.then(function (response) {
				if (response.status === 200) {
					self.props.refreshUserData();
					self.props.switchModeTo("emailFinder")
				} else {
					self.props.createNotification(
						"error",
						"An error occurred performing email lookup.",
						"Error",
						6000
					);
				}
			})
			.catch(function (error) {
				self.props.createNotification(
					"error",
					"An error occurred performing email lookup.",
					"Error",
					6000
				);
			});
	}

	static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (props.item !== state.item) {

    	///
		/// Evaluate types of email
		///
		var prospectPart = 0;
		var genericPart = 0;
		var unknownPart = 0;
		var relevantPart = 0;
		var fraudulentPart = 0;
		var totalCount = 0;

		if (typeof props.item.result !== "undefined") {
			let foundAddressesCount =
				props.item.result.payload.length +
				(typeof props.item.result.relevantAddress !== "undefined"
					? props.item.result.relevantAddress.length
					: 0);

			let prospectCount = props.item.result.payload.filter(
				(item) => item.type === "prospect"
			).length;
			let genericCount = props.item.result.payload.filter(
				(item) => item.type === "generic"
			).length;
			let unknownCount = props.item.result.payload.filter(
				(item) => item.type === "unknown"
			).length;
			let relevantCount =
				typeof props.item.result.relevantAddress !== "undefined"
					? props.item.result.relevantAddress.length
					: 0;
			let fraudulentCount = props.item.result.payload.filter(
				(item) => item.type === "fraudulent"
			).length;

			totalCount =
				prospectCount +
				genericCount +
				unknownCount +
				relevantCount +
				fraudulentCount;

			prospectPart = Math.round(
				(prospectCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);
			genericPart = Math.round(
				(genericCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);
			unknownPart = Math.round(
				(unknownCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);

			relevantPart = Math.round(
				(relevantCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);
			fraudulentPart = Math.round(
				(fraudulentCount * 100) / (foundAddressesCount !== 0 ? foundAddressesCount : 1)
			);
		}

      return {
        prospectPart: prospectPart,
		genericPart: genericPart,
		unknownPart: unknownPart,
		relevantPart: relevantPart,
		fraudulentPart: fraudulentPart,
		totalCount: totalCount,
      };
    }
    return null;
  }

	render() {
		var self = this;
		const processingBarRandomClass = Math.floor(
			Math.random() * Math.floor(5)
		);

		const target = typeof this.props.item.domain !== 'undefined' ? this.props.item.domain.payload[0] : 'world';
		const icon = "https://s2.googleusercontent.com/s2/favicons?domain=" + target
		let domain = typeof this.props.item.domain !== 'undefined' ? this.props.item.domain.payload[0] : 'identifying...';

		let date = new Date(
			this.props.item.createdAt * 1000
		).toLocaleDateString();

		let status = () => {
			switch (this.props.item.status) {
				case "pending":
					return (
						<div>
							<div className="processingBarWrapper">
								<div
									className={
										"processingBar processingBar" +
										processingBarRandomClass
									}
								></div>
							</div>
						</div>
					);
				case "processing":
					return (
						<div>
							<div className="processingBarWrapper">
								<div
									className={
										"processingBar processingBar" +
										processingBarRandomClass
									}
								></div>
							</div>
						</div>
					);
				case "succeeded":
					return <div>{icons.checkCircle()}</div>;
				case "failed":
					return <div>{icons.timesCircle()}</div>;
				default:
					return <React.Fragment />;
			}
		};

		let foundAddresses =
			typeof this.props.item.result !== "undefined"
				? this.props.item.result.payload.length +
				  (typeof this.props.item.result.relevantAddress !== "undefined"
						? this.props.item.result.relevantAddress.length
						: 0)
				: 0;

		let suggestedLookups =
			typeof this.props.item.result !== "undefined"
				? typeof this.props.item.result.recommended !== "undefined"
					? this.props.item.result.recommended.length
					: 0
				: 0;

		let alternativeDomain =
			typeof this.props.item.result !== "undefined"
				? typeof this.props.item.result.alternativeDomain !==
				  "undefined"
					? this.props.item.result.alternativeDomain.length
					: 0
				: 0;

		let filterEmails = (type) => {
			if (typeof this.props.item.result !== "undefined") {

				let holder = (type === 'relevant' ? this.props.item.result.relevantAddress : this.props.item.result.payload)

				return holder.map(function (
					item,
					index
				) {
					if (item.type === type || type === 'relevant') {
						return (
							<div
								className="domainSearchEmailWrapper"
								key={index}						
							>
								<div className="contactAvatar">
									<Gravatar userEmailAddress={item.email} defaultImage="https%3A%2F%2Fcdn.poirot.app%2Fimages%2Fperson-circle.png" imageSize="24"/>
								</div>
								<div className="foundEmailAddress"><a href={"mailto:" + item.email + "?&Content-Type=text/html&body=%0D%0A%0D%0ASent with help from www.poirot.app"}>{item.email}</a></div>
								<div className={item.verified ? "verificationStatusPass" : "verificationStatusFail"}>{item.verified ? icons.success() : icons.fail()}</div>
								<div className="contactName">{item.name}</div>
								<div className="contactPosition">{item.position}</div>
								<div className="contactLinkedIn">{typeof item.linkedInUrl !== 'undefined' ? 
																	<div><a href={item.linkedInUrl} target="_blank" rel="noopener noreferrer">{icons.linkedInLogo()}</a></div>
																	:
																	<React.Fragment />
																}
								</div>
								<div className="contactTags">
									<Tags activeTeamData={self.props.activeTeamData}
										  displayTagSelector={self.props.displayTagSelector}
										  displayTagRemovalSelector={self.props.displayTagRemovalSelector}
										  userTags={self.props.userTags}
										  taskId={self.props.item.id}
										  email={item.email}
										  label={item.label}
										  refreshTaskData={self.props.refreshTaskData} />
								</div>
							</div>
						);
					} else {
						return <React.Fragment key={index} />;
					}
				});
			} else {
				return <React.Fragment />;
			}
		};

		return (
			<div
				className={
					this.state.isOpen
						? "taskWrapper inFocus"
						: "taskWrapper"
				}
			>
				<div className="task" onClick={() => this.handleTaskClick()} onContextMenu={(event) => this.handleRightClick(event, this.props.item.id)}>
					<div>
						<img src={icon} alt="CompanyIcon" />{" "}
					</div>
					<div className="taskDomainName">{domain}</div>
					<div className="taskEventTime">{date}</div>
					<div className="taskStatus">{status()}</div>
					<div className="taskDomainSearchChart">
						<DomainSearchChart
							index={this.props.item.id}
							prospectPart={this.state.prospectPart}
							genericPart={this.state.genericPart}
							unknownPart={this.state.unknownPart}
							relevantPart={this.state.relevantPart}
							fraudulentPart={this.state.fraudulentPart}
							totalCount={this.state.totalCount}
						/>
					</div>
					<div className="taskFoundAddresses">
						Discovered<span>{foundAddresses}</span>
					</div>
					<div className="taskAlternativeDomains">
						Alternate Domains<span>{alternativeDomain}</span>
					</div>
					<div className="taskSuggestedLookups">
						Suggested<span>{suggestedLookups}</span>
					</div>
				</div>

				{this.state.isOpen ?

				<Collapse isOpened={this.state.isOpen}>
					<div className="taskContentWrapper">
						{foundAddresses !== 0 ?
							<div className="discoverdEmailsTitle"><h1>Discovered {foundAddresses > 1 ? 'emails' : 'email'}</h1></div>
							:
							<React.Fragment />
						}

						{this.state.prospectPart !== 0 ?
							<div className="taskContent">
								<div className="taskContent-prospects"><h3>PROSPECTS</h3></div>
								<div className="filteredEmailsList">{filterEmails("prospect")}</div>
							</div>
							: 
							<React.Fragment />
						}

						{this.state.genericPart !== 0 ?
							<div className="taskContent">
								<div className="taskContent-generic"><h3>GENERIC</h3></div>
								<div className="filteredEmailsList">{filterEmails("generic")}</div>
							</div>
							:
							<React.Fragment />
						}

						{this.state.unknownPart !== 0 ?
							<div className="taskContent">
								<div className="taskContent-unknown"><h3>UNKNOWN</h3></div>
								<div className="filteredEmailsList">{filterEmails("unknown")}</div>
							</div>
							:
							<React.Fragment />
						}

						{this.state.relevantPart !== 0 ?
							<div className="taskContent">
								<div className="taskContent-relevant"><h3>RELEVANT</h3></div>
								<div className="filteredEmailsList">{filterEmails("relevant")}</div>
							</div>
							:
							<React.Fragment />
						}

						{this.state.fraudulentPart !== 0 ?
							<div className="taskContent">
								<div className="taskContent-fraudulent"><h3>FRAUDULENT</h3></div>
								<div className="filteredEmailsList">{filterEmails("fraudulent")}</div>
							</div>
							:
							<React.Fragment />
						}

						{alternativeDomain !== 0 ?
							<div className="alternativeDomainsWrapper">
								<div>
									<h1>Alternative {alternativeDomain > 1 ? 'domains' : 'domain'}</h1>
									{this.props.item.result.alternativeDomain.map(function(item, index) {

										const icon =
												"https://s2.googleusercontent.com/s2/favicons?domain=" + item;

										 return (
											<div className="alternativeDomainsList" key={'alternative'+index}>
												<div><img src={icon} alt="alternative company icon"/></div>
												<div>{item}</div>
												<div><span onClick={(event) => self.props.handleDomainSearch(event, item)}>Initiate lookup task</span></div>
											</div>
										)
									})}
								</div>
							</div>
							:
							<React.Fragment />
						}

						{suggestedLookups !== 0 ?
							<div className="suggestedLookupsWrapper">
								<div>
									<h1>Suggested {suggestedLookups > 1 ? 'contacts' : 'contact'}</h1>
									{this.props.item.result.recommended.map(function(item, index) {

										return (
											<div className="suggestedLookupsList" key={'suggestes'+index}>
												<div><a href={item.linkedInUrl} target="_blank" rel="noopener noreferrer">{icons.linkedInLogo()}</a></div>
												<div>{item.name}</div>
												<div>{item.position}</div>											
												<div>{self.props.item.result.catchAllDomain ? <React.Fragment /> : <span onClick={(event) => self.lookupEmailAddress(event, item.name, domain)}>Email lookup</span>}</div>
											</div>
										)
									})

									}
								</div>
							</div>
							:
							<React.Fragment />
						}

						{foundAddresses + suggestedLookups + alternativeDomain === 0 && this.props.item.status === 'failed' ?
							<div className="failedLookupTitle"><h1>Domain lookup failed.</h1></div>
							:
							<React.Fragment />
						}

						{foundAddresses + suggestedLookups + alternativeDomain === 0 && this.props.item.status === 'succeeded' ?
							<div className="failedLookupTitle"><h1>Unable to locate email addresses.</h1></div>
							:
							<React.Fragment />
						}


						{foundAddresses + suggestedLookups + alternativeDomain === 0 && (this.props.item.status === 'pending' || this.props.item.status === 'processing') ?
							<div className="failedLookupTitle"><h1>Your request is being processed...</h1></div>
							:
							<React.Fragment />
						}

					</div>

				</Collapse>
				:
				<React.Fragment />
				}
			</div>
		);
	}
}

export default Task;