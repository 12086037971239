import React, { Component } from 'react';
import axios from "axios";

import TagsHelperPanel from './tagsHelperPanel';
import DetailsHelperPanel from './detailsHelperPanel';

let settings = require("../settings");
let authentication = require("../authentication");

class HelperPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            domainData: undefined,
            loadingProcessActive: undefined,
        };

        this.handleRefresh = this.handleRefresh.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.source !== this.props.source) {
            this.handleRefresh();
        }
    }

    handleRefresh() {
        var self = this;

        if (typeof this.props.source === "undefined") {
            return
        }
        if (typeof this.props.source.domain === 'undefined') {
            return
        }
        if (typeof this.props.source.domain.payload === 'undefined') {
            return
        }
        if (this.props.source.domain.payload.length === 0) {
            return;
        }

        this.setState({
            loadingProcessActive: true,
        });

        let tokenHolder = authentication.getToken();

        if (!tokenHolder) {
            return;
        }

        const requestData = {
            url: this.props.source.domain.payload[0],
        };

        axios
            .post(settings.API_HOST + "domaindata", requestData, {
                headers: { Authorization: "Bearer " + tokenHolder.token },
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.setState({
                        loadingProcessActive: false,
                        domainData: response.data,
                    });
                } else {
                    self.setState({ loadingProcessActive: undefined, domainData: undefined });
                }
            })
            .catch(function (error) {
                self.setState({ loadingProcessActive: undefined, domainData: undefined });
            });
    }

    render() {

        return(
            <div className="helperPanel">
                <div className="helperPanelTabs">
                	<button className={this.props.helperPanelMode === 'details' ? "helperPanelTab" : "helperPanelTabUnselected"} onClick={() => this.props.switchHelperPanelModeTo("details")}>Details</button>
                	<button className={this.props.helperPanelMode === 'tags' ? "helperPanelTab" : "helperPanelTabUnselected"} onClick={() => this.props.switchHelperPanelModeTo("tags")}>Tags</button>
                </div>
                <div className="helperPanelDataWrapper">
                	 {this.props.helperPanelMode === 'details' 
                     ? <DetailsHelperPanel loadingProcessActive={this.state.loadingProcessActive}
                                            domainData={this.state.domainData}/>
                     : <TagsHelperPanel activeTeamData={this.props.activeTeamData}
                                        refreshUserData={this.props.refreshUserData}
                                        refreshActiveTeamData={this.props.refreshActiveTeamData}
                                        createNotification={this.props.createNotification}
                                        userTags={this.props.userTags}
                                        refreshTaskData={this.props.refreshTaskData}/>
                     }
                </div>
            </div>       
        )
    }
};

export default HelperPanel;